import { trim, replace, startsWith, insert } from "lodash";
import moment from "moment";
import ReactPixel from "react-facebook-pixel";

export function cleanSSN(input) {
  if (!input) return input;

  input = trim(input);

  if (input.length <= 8) return input;

  input = replace(input, "-", "");

  if (
    input.length === 10 &&
    !startsWith(input, "19") &&
    !startsWith(input, "20")
  ) {
    input = "19" + input;
  }

  if (input.length === 12) {
    input = insert(input, "-", 8);
  }

  return input;
}

export const isItReal = (type, id) => {
  if (type === "ga") {
    if (id.length < 5 || !id.startsWith("UA-")) {
      return false;
    }
    return true;
  } else if (type === "pixel") {
    if (id.length < 5 || id.startsWith("xxx") || id.startsWith("XXX")) {
      return false;
    }
    return true;
  }
  return true;
};

export const PixelInit = (id) => {
  if (isItReal("pixel", id)) {
    ReactPixel.init(id);
    ReactPixel.pageView();
  }
};

export const pixelData = (product, quantity = 1) => {
  return {
    content_ids: [product.plu],
    content_name: product.name,
    content_type: "product",
    contents: [
      {
        id: product.plu,
        quantity,
      },
    ],
  };
};

export const pixelCheckoutData = (data, currency) => {
  return {
    content_ids: data.map((d) => d.article.plu),
    contents: data.map((d) => ({
      id: d.article.plu,
      quantity: d.quantity,
    })),
    currency,
    value: data
      .map((d) => d.totalPrice.amountInclVat)
      .reduce((acc, cur) => acc + cur),
    num_items: data.map((d) => d.quantity).reduce((acc, cur) => acc + cur),
  };
};

export const GAInit = (id) => {
  return null;
  // if (id && id.length > 0) {
  //   ReactGA.initialize([
  //     {
  //       trackingId: "UA-150531555-1",
  //       gaOptions: { name: "Integration", debug: true, allowLinker: true }
  //     },
  //     {
  //       trackingId: id,
  //       gaOptions: { debug: true, hostname: "host-test", allowLinker: true }
  //     }
  //   ]);
  // } else {
  //   ReactGA.initialize("UA-150531555-1", {
  //     debug: true,
  //     gaOptions:{allowLinker: true}
  //   });
  // }
  // ReactGA.pageview(window.location.pathname, ["Integration"]);
};

export const getInitialFromUntil = () => {
  return {
    from: moment().hour(0).minute(0).toISOString(),
    until: moment().add(1, "year").hour(23).minute(59).toISOString(),
  };
};

export const translateProductGroup = (originalName, language, location) => {
  if (!language[location]) {
    return originalName;
  }
  const translatedName = Object.entries(language[location]).find(
    (category) => originalName === category[0]
  );

  if (translatedName) {
    return translatedName[1];
  } else {
    return originalName;
  }
};
