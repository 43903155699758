import { Radio, Text, UnstyledButton } from "@mantine/core";
import React from "react";
import classes from "./ExtensionModification.module.css";

const ExtensionModifications = ({
  modification,
  id,
  title,
  price,
  checked,
  setChecked,
}) => {
  return (
    <UnstyledButton
      key={id}
      onClick={() => setChecked(modification)}
      aria-label={`${title} ${price}`}
      className={`${classes.button} ${checked && classes.checked}`}
    >
      <div className={classes.body}>
        <Text
          className="product-price"
          c="black"
          size="xs"
          style={{ lineHeight: 1 }}
          mb={5}
        >
          {price}
        </Text>
        <Text fw={500} size="sm" style={{ lineHeight: 1 }}>
          {title}
        </Text>
      </div>
      <Radio
        checked={checked?.id === id}
        onChange={() => {
          setChecked(modification);
        }}
        tabIndex={-1}
        styles={{
          radio: {
            backgroundColor:
              checked?.id === id && "var(--button-primary-bg-color)",
            borderColor: checked?.id === id && "var(--button-primary-bg-color)",
            cursor: "pointer",
          },
        }}
      />
    </UnstyledButton>
  );
};

export default ExtensionModifications;
