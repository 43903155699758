import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import classNames from "classnames";
import classes from "./SiteHeading.module.css";
import { STATE_CULTURE } from "../../../common/state";
import { useLocalStorage } from "@mantine/hooks";
let source;

const SiteHeading = (props) => {
  const [text, setText] = useState("");
  const [heroHeight, setHeroHeight] = useLocalStorage("height", "");
  const { culture, config } = useSelector((state) => ({
    culture: state.cookies[STATE_CULTURE],
    config: state.config,
  }));

  const ref = useRef(null);

  useEffect(() => {
    if (config?.showSiteHeadingOnlyOnLanding) {
      if (ref.current && window.location.pathname === "/ticketshop/") {
        let heroHeight = ref.current.offsetHeight;
        localStorage.setItem("height", heroHeight);
        setHeroHeight(heroHeight);
      }
    }
  }, [ref]);

  useEffect(() => {
    if (config.useSiteHeading) {
      let CancelToken = axios.CancelToken;
      source = CancelToken.source();
      axios
        .get(
          `${config.baseUrl}/${config.descriptionFolderUrl}siteheading.${culture}.md`,
          {
            cancelToken: source.token,
          }
        )
        .then((res) => setText(res.data));
      return () => source.cancel("Site heading Is Unmounting");
    }
  }, [culture, config.descriptionFolderUrl, config.useSiteHeading]);

  if (!config.useSiteHeading) return null;
  return (
    <section
      className={classNames(classes.container, "siteheading__container")}
      ref={ref}
    >
      <ReactMarkdown
        className="site-heading__content"
        rehypePlugins={[rehypeRaw]}
      >
        {text}
      </ReactMarkdown>
    </section>
  );
};

export default SiteHeading;
