import { Autocomplete, TextInput } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import classes from "./PersonalInputField.module.css";

const PersonalInputField = ({ form, type, label, placeholder }) => {
  const { config } = useSelector((state) => ({
    config: state.config,
  }));

  const autoCompleteOptions = (type) => {
    switch (type) {
      case "firstName":
        return "given-name";
      case "lastName":
        return "family-name";
      case "civicRegistrationNumber":
        return "";
      case "email":
        return "email";
      case "cellPhone":
        return "tel-national";
      case "street":
        return "address-line1";
      case "streetNumber":
        return "address-line2";
      case "zipCode":
        return "postal-code";
      case "city":
        return "address-level2";
      case "countryCode":
        return "country-name";
      default:
        return "name";
    }
  };

  const inputModeType = (type) => {
    if (
      type === "cellPhone" ||
      type === "zipCode" ||
      type === "civicRegistrationNumber"
    ) {
      return "numeric";
    } else {
      return "text";
    }
  };

  const data =
    form.values.email.trim().length > 0 && !form.values.email.includes("@")
      ? ["gmail.com", "outlook.com", "yahoo.com", "me.com", "hotmail.com"].map(
          (provider) => `${form.values.email}@${provider}`
        )
      : [];
  if (!label || !config) return null;
  return (
    <>
      {type === "email" ? (
        <Autocomplete
          label={label}
          placeholder={placeholder ? placeholder : label}
          required={config?.formConfig?.[0].requiredFields.includes(type)}
          {...form.getInputProps(type)}
          classNames={classes}
          autoComplete={autoCompleteOptions(type)}
          inputMode={inputModeType(type)}
          data={data}
        />
      ) : (
        <TextInput
          placeholder={placeholder ? placeholder : label}
          required={config?.formConfig?.[0].requiredFields.includes(type)}
          classNames={classes}
          // value={value}
          {...form.getInputProps(type)}
          // onChange={(event) => valueSet(event.currentTarget.value)}
          label={label}
          autoComplete={autoCompleteOptions(type)}
          inputMode={inputModeType(type)}
        />
      )}
    </>
  );
};

export default PersonalInputField;
