import React from "react";
import { Button, Text, Title } from "@mantine/core";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import classes from "./Receipt.module.css";

const Receipt = ({ success, error, onPrevClick }) => {
  const { language } = useSelector((state) => ({
    language: state.language,
  }));

  const navigate = useNavigate();
  return (
    <div>
      <div className={classes.titleWrapper} role={!success && "alert"}>
        <Title
          order={2}
          style={{ fontSize: "1.15rem", fontWeight: 500, textAlign: "center" }}
        >
          {success && language.BookingComplete}
          {!success && language.BookingError}
        </Title>
        <Text component="p" style={{ textAlign: "center" }}>
          {success && language.BookingDescription}
          {!success && language.BookingErrorDescription}
        </Text>
      </div>
      <div className={classes.buttonWrapper}>
        {!success ? (
          <Button
            onClick={() => onPrevClick()}
            className={"primaryButton backButton"}
            size="md"
          >
            {language.Back}
          </Button>
        ) : (
          <div />
        )}
        <Button
          onClick={() => navigate("/")}
          className={"primaryButton backButton"}
          size="md"
        >
          {language.BackToHomepage}
        </Button>
      </div>
    </div>
  );
};

export default Receipt;
