import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Description from "../../components/common/Description";
import PageLayout from "../../components/Styles/PageLayout";
import PageContent from "../../components/Styles/PageContent";
import Page from "../../components/Page";
import { STATE_CULTURE } from "../../common/state";
import { getPage } from "./util";
import { DataContext } from "../../components/common/dataProvider";
import slugify from "../../components/common/slugify";
import { Button, Container, Text, Title } from "@mantine/core";
import { useParams, useNavigate } from "react-router";

let source;

const SitePage = ({ setPosition }) => {
  const id = useParams().id;
  const [text, setText] = useState("");
  const { config, culture, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const { data } = useContext(DataContext);
  const page = config.pages.find((p) => slugify(p.name) === id);
  const extendedPage = useMemo(
    () => getPage(data, config, page),
    [data, config, page]
  );
  const navigate = useNavigate();

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    source = CancelToken.source();
    if (page && page.description && page.description.length > 0) {
      axios
        .get(
          `${config.baseUrl}/${config.descriptionFolderUrl}/${page.description}.${culture}.md`,
          {
            cancelToken: source.token,
          }
        )
        .then((res) => setText(res.data));
    } else {
      setText("");
    }
    return () => source.cancel("Component Is Unmounting");
  }, [culture, config.descriptionFolderUrl, page]);
  if (!page)
    return (
      <PageLayout>
        <PageContent>
          <Container
            style={{ textAlign: "center", margin: "3rem 0", maxWidth: "100%" }}
          >
            <Title mb="lg" order={1}>
              {language.CouldNotFindPageHeader}
            </Title>
            <Text component="p" mb="lg">
              {language.CouldNotFindPageText}
            </Text>
            <Button
              component="a"
              onClick={() => navigate("/")}
              className="mantine-Primary-btn primaryButton"
              variant="filled"
              size="md"
              radius="sm"
              color="indigo"
            >
              {language.BackToHomepage}
            </Button>
          </Container>
        </PageContent>
      </PageLayout>
    );

  return (
    <PageLayout>
      <PageContent>
        <Description text={text} />
        <Page
          originalPage={page}
          page={extendedPage}
          setPosition={setPosition}
        />
      </PageContent>
    </PageLayout>
  );
};

export default SitePage;
