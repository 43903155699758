import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { Menu, UnstyledButton } from "@mantine/core";
import { BiChevronDown } from "react-icons/bi";
import { motion } from "framer-motion";
import slugify from "../../common/slugify";
import { STATE_CULTURE } from "../../../common/state";
import classes from "./Menu.module.css";

export const allLinks = (getLinks, dropDownLinks) => {
  if (dropDownLinks) {
    const all = [...getLinks, ...dropDownLinks];
    const newArr = new Array(all.length);

    dropDownLinks.forEach((item, i) => {
      const newItem = { ...item, isDropDown: true }; // Create a copy and add isDropDown
      if (
        typeof newItem.sortInMenu === "number" &&
        newArr[newItem.sortInMenu] === undefined
      ) {
        newArr[newItem.sortInMenu] = newItem;
      } else {
        newArr[i] = newItem;
      }
    });

    let j = 0;

    all.forEach((item) => {
      if (!dropDownLinks.some((dropDownItem) => dropDownItem === item)) {
        while (newArr[j] !== undefined) j++;
        newArr[j] = { ...item };
      }
    });

    return newArr.filter(Boolean);
  } else {
    return getLinks;
  }
};

const Links = () => {
  const { config, language, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const [opened, setOpened] = useState(false);
  const location = useLocation();

  const getLinks = useMemo(() => {
    return config?.pages?.filter((el) => !el.disabled && !el.useInDropDown);
  }, [config]);

  const dropDownLinks = useMemo(() => {
    return config?.newHeaderDropDown?.filter((el) => !el.disabled);
  }, [config]);

  const linkHref = (item) => {
    const slug = slugify(item.name);
    if (item?.internalLink) {
      return `${item.internalLink}`;
    }
    if (item?.externalLink) {
      return `${item.externalLink}`;
    }
    if (item?.eventCalendar) {
      return `/tickets/${slug}`;
    }
    if (item?.articleBooking) {
      return `/booking/${slug}`;
    }
    if (item?.publicBooking) {
      return `/publicbooking/${slug}`;
    }
    return `/${slug}`;
  };

  const nameTranslation = (link) => {
    const getTranslation =
      link?.translations &&
      link.translations.find((translation) => translation.culture === culture);
    if (getTranslation) {
      return getTranslation?.name;
    } else return link.name;
  };

  const getTranslatedName = useCallback(
    (name) => {
      if (language && language?.LandingTexts && name) {
        const getTranslation = language?.LandingTexts[name];
        return getTranslation?.primary;
      }
    },
    [language]
  );

  const isLinkActive = useCallback(
    (link) => {
      const splitUrl = location.pathname.split("/").filter(Boolean).pop();
      const checkDropdown =
        dropDownLinks &&
        dropDownLinks
          .filter((el) =>
            el?.subLinks.find((ele) => {
              if (
                ele.internalLink &&
                ele.internalLink.replaceAll("/", "") === splitUrl
              ) {
                return true;
              } else if (slugify(ele?.name) === splitUrl) {
                return true;
              }
            })
          )
          .filter(Boolean)?.[0];

      if (checkDropdown && slugify(checkDropdown?.name) === slugify(link))
        return true;
      if (location.pathname === "/" && link === "hem") return true;
      else if (slugify(link) === splitUrl) return true;
      else return false;
    },
    [location]
  );

  const fixInternalLink = (internalLink) =>
    internalLink.split("/").filter(Boolean).pop();

  return (
    <>
      <div className={`${classes.links} headerLinks`}>
        <Link className={classes.linkHome} to={"/"}>
          {language.Home}
          <div style={{ paddingTop: ".25rem", position: "relative" }}>
            <motion.div animate>
              {isLinkActive("hem") ? (
                <motion.div
                  className={classes.underline}
                  layoutId="underline"
                  style={{ originY: "0px" }}
                />
              ) : null}
            </motion.div>
          </div>
        </Link>

        {allLinks(getLinks, dropDownLinks).map((link, i) => {
          if (link?.isDropDown) {
            return (
              <Menu
                opened={opened === link.name}
                onChange={() =>
                  opened ? setOpened(null) : setOpened(link.name)
                }
                openDelay={100}
                closeDelay={400}
                position="bottom-start"
                key={link.name + i}
              >
                <Menu.Target>
                  <UnstyledButton
                    onMouseEnter={() => setOpened(link.name)}
                    onMouseLeave={() => setOpened(null)}
                    className={classes.menuTarget}
                  >
                    {nameTranslation(link)}
                    <div style={{ position: "relative" }}>
                      <motion.div animate>
                        {isLinkActive(link.name) ? (
                          <motion.div
                            className={classes.underline}
                            layoutId="underline"
                            style={{ originY: "0px" }}
                          />
                        ) : null}
                      </motion.div>
                    </div>
                    <span className={classes.chevron}>
                      <BiChevronDown />
                    </span>
                  </UnstyledButton>
                </Menu.Target>
                <motion.div
                  className={classes.menuDropdownWrapper}
                  onMouseEnter={() => setOpened(link.name)}
                  onMouseLeave={() => setOpened(null)}
                >
                  <Menu.Dropdown className={classes.menuDropdown}>
                    {link.subLinks.map((subLink, i) => {
                      if (!subLink.disabled)
                        return (
                          <Menu.Item
                            className={`${classes.dropdownLinks} dropdownLinks`}
                            key={`${subLink.name}${i}`}
                            component={Link}
                            to={linkHref(subLink)}
                          >
                            {nameTranslation(subLink)}
                          </Menu.Item>
                        );
                    })}
                  </Menu.Dropdown>
                </motion.div>
              </Menu>
            );
          } else {
            if (link.externalLink) {
              return (
                <a
                  key={link.name}
                  href={linkHref(link)}
                  target="_blank"
                  rel="noreferrer"
                  style={{ paddingTop: ".25rem" }}
                >
                  {getTranslatedName(link.name)}
                </a>
              );
            } else {
              return (
                <span key={link.name}>
                  <Link to={linkHref(link)}>
                    {getTranslatedName(link.name)}
                    <div style={{ paddingTop: ".25rem", position: "relative" }}>
                      <motion.div key={link.name} animate>
                        {isLinkActive(
                          link.internalLink
                            ? fixInternalLink(link.internalLink)
                            : link.name
                        ) ? (
                          <motion.div
                            className={classes.underline}
                            layoutId="underline"
                            style={{ originY: "0px" }}
                          />
                        ) : null}
                      </motion.div>
                    </div>
                  </Link>
                </span>
              );
            }
          }
        })}

        {config?.showAllProducts && (
          <Link className={classes.linkHome} to={"/all-products"}>
            {language.All_Products}
            <div style={{ paddingTop: ".25rem", position: "relative" }}>
              <motion.div animate>
                {isLinkActive("all-products") ? (
                  <motion.div
                    className={classes.underline}
                    layoutId="underline"
                    style={{ originY: "0px" }}
                  />
                ) : null}
              </motion.div>
            </div>
          </Link>
        )}
        {config?.useAllEvents && (
          <Link className={classes.linkHome} to={"/all-events"}>
            {language.All_Events}
            <div style={{ paddingTop: ".25rem", position: "relative" }}>
              <motion.div animate>
                {isLinkActive("all-events") ? (
                  <motion.div
                    className={classes.underline}
                    layoutId="underline"
                    style={{ originY: "0px" }}
                  />
                ) : null}
              </motion.div>
            </div>
          </Link>
        )}
      </div>
    </>
  );
};

export default Links;
