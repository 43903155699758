import React from "react";
import { Group, Radio } from "@mantine/core";
import classes from "./Field.module.css";

const RadioGroupField = ({
  form,
  label,
  id,
  description,
  values,
  required,
}) => {
  const matchingFormIndex = form.values.formFields.findIndex(
    (field) => field.id === id
  );
  return (
    <div className={"pbCheckbox"}>
      <Radio.Group
        classNames={{ label: classes.label, description: classes.description }}
        label={label}
        description={description}
        withAsterisk={required}
        {...form.getInputProps(`formFields.${matchingFormIndex}.stringVal`)}
      >
        <Group mt="xs">
          {values.map((el) => (
            <Radio
              classNames={{ label: classes.radio }}
              key={el}
              label={el}
              value={el}
              color="indigo"
            />
          ))}
        </Group>
      </Radio.Group>
    </div>
  );
};

export default RadioGroupField;
