import { Button, Divider, Image, Loader } from "@mantine/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addArticle, addToCartTracking } from "../../common/sale";
import { STATE_CULTURE, STATE_CURRENCY } from "../../common/state";
import { DataContext } from "../common/dataProvider";
import { isSameDay } from "date-fns";
import classes from "./OptionsItems.module.css";
import placeholder from "../../images/placeholder.jpg";
import QuantityInput from "../Drawer/QuantityInput";
import { CART_ARTICLE_ADD, Context } from "../../context";
import { useDisclosure } from "@mantine/hooks";
import OptionsModal from "./OptionsModal";

const OptionsItem = ({ item, sale, isLastItem, allotmentId }) => {
  const [loading, setLoading] = useState(null);
  const { config, culture, language, currency } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
    currency: state.cookies[STATE_CURRENCY],
  }));
  const { dispatch } = useContext(Context);
  const rdxDispatch = useDispatch();
  const { data } = useContext(DataContext);
  const [extraInformation, setExtraInformation] = useState(null);
  const [ID, setID] = useState(null);
  const [day, setDay] = useState(null);
  const [addedToCart, addedToCartSet] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);

  const uri = config?.apiUriOverride?.startsWith("http")
    ? config.apiUriOverride
    : config.apiUri;

  const image = `${uri}/articleimage/${item?.imageFilename}`;

  const getName = () => {
    const getTranslation = item?.translation.filter(
      (el) => el.culture === culture
    );
    return getTranslation?.[0]?.name;
  };

  const getDescription = () => {
    const getTranslation = item?.translation.filter(
      (el) => el.culture === culture
    );
    return getTranslation?.[0]?.description;
  };

  const getStock =
    item?.plu &&
    data &&
    data.articles.filter((el) => el.plu === item.plu)?.[0]?.inStock;

  const allotmentOccasion = useMemo(() => {
    if (allotmentId && data) {
      return data.allotments.reduce((acc, curr) => {
        if (curr.id === allotmentId) {
          let occasion = curr.occasions.filter((el) =>
            isSameDay(new Date(el.time), day)
          );
          acc = occasion;
        }
        return acc;
      }, {});
    }
  }, [allotmentId, data]);

  const availableOccasions = useMemo(() => {
    return (
      allotmentId &&
      data &&
      data.allotments.reduce((acc, curr) => {
        if (curr.id === allotmentId) {
          let occasion = curr.occasions.filter((el) => el.remaining > 0);
          acc = occasion;
        }
        return acc;
      }, {})
    );
  }, [data, allotmentId]);

  const article = useMemo(() => {
    if (allotmentId && extraInformation) {
      return {
        plu: item.plu,
        quantity: 1,
        allotmentId: allotmentId,
        allotmentOccasion: day,
        extraInformation: extraInformation,
      };
    } else if (allotmentId && !extraInformation) {
      return {
        plu: item.plu,
        quantity: 1,
        allotmentId: allotmentId,
        allotmentOccasion: day,
      };
    } else if (!allotmentId && extraInformation) {
      return {
        plu: item.plu,
        quantity: 1,
        extraInformation: extraInformation,
      };
    } else {
      return {
        plu: item.plu,
        quantity: 1,
      };
    }
  }, [day, item, allotmentId, extraInformation]);

  const allotmentRemaining = useMemo(() => {
    if (allotmentOccasion && allotmentOccasion.length > 0) {
      return allotmentOccasion[0].remaining;
    } else return null;
  }, [allotmentOccasion]);

  const trackingProductData = [
    {
      id: item.plu,
      name: item?.name,
      price: item.price.amountInclVat,
      quantity: 1,
    },
  ];

  const cb = (status) => {
    addToCartTracking(currency, trackingProductData);
    dispatch({
      type: CART_ARTICLE_ADD,
      payload: article,
    });
    setLoading(false);
    if (status === 200) {
      addedToCartSet(true);
      setTimeout(() => {
        addedToCartSet(false);
      }, 2000);
    }
  };

  const add = (item) => {
    setLoading(true);
    rdxDispatch(addArticle(item, cb));
  };

  useEffect(() => {
    if (sale) {
      const findItem = sale.items.find((el) => el.article.plu === item.plu);
      if (findItem) {
        setID(findItem.key);
      }
    }
  }, [sale]);

  const saleItem = useMemo(() => {
    return sale?.items.find((el) => el.key === ID);
  }, [sale, ID]);

  const isDisabled = useMemo(() => {
    const hasAdditionalFields = item?.additionalFields.every(
      (all) => all.setValueOnSale && extraInformation?.hasOwnProperty(all.id)
    );
    const hasSetValueOnSale = item?.additionalFields.every(
      (all) => all.setValueOnSale
    );
    if (allotmentId && !day) {
      return true;
    }
    if (!hasAdditionalFields && hasSetValueOnSale) {
      return true;
    }
    return false;
  }, [item, day, extraInformation]);

  const handleAdd = () => {
    if (item?.additionalFields?.length > 0) {
      open();
    } else {
      add(article);
    }
  };

  useEffect(() => {
    const saleHasAllotment =
      sale && sale.items.filter((el) => el.occasion !== null);

    const findSameDay = availableOccasions?.find(
      (item) =>
        isSameDay(
          new Date(saleHasAllotment?.[0]?.occasion),
          new Date(item.time)
        ) && item.remaining > 0
    )?.time;

    if (findSameDay && saleHasAllotment && !saleItem && !day) {
      setDay(new Date(findSameDay));
    } else if (saleHasAllotment && saleItem) {
      setDay(new Date(saleItem?.occasion));
    }
  }, [availableOccasions, sale, saleItem]);

  return (
    <div className={loading ? classes.loadingWrapper : undefined}>
      <div className={classes.wrapper}>
        {loading && (
          <Loader
            variant="dots"
            className={classes.loader}
            color="var(--theme-primary-color)"
          />
        )}
        <div className={classes.itemWrapper}>
          <Image
            fallbackSrc={placeholder}
            className={classes.image}
            radius="md"
            src={image}
            fit="cover"
            alt={getName()}
            asp
          />
          <div className={classes.rowWrapper}>
            <div>
              <div className={classes.leftWrapper}>
                <div className={classes.textWrapper}>
                  <p className={classes.text}>{getName()}</p>
                </div>
              </div>
            </div>
            <div>
              <div className={classes.rightWrapper}>
                {item?.additionalFields.length === 0 &&
                !addedToCart &&
                saleItem?.quantity > 0 ? (
                  <QuantityInput
                    className={classes.quantityInput}
                    style={{ width: "100%" }}
                    name={getName()}
                    inStock={
                      getStock
                        ? getStock
                        : allotmentRemaining
                        ? allotmentRemaining
                        : ""
                    }
                    item={item}
                    id={ID}
                    quantity={saleItem?.quantity ? saleItem.quantity : 0}
                    addArticle={() => add(article)}
                    isDisabled={isDisabled}
                  />
                ) : (
                  <Button
                    className={`${classes.button} primaryButton add_to_cart_product`}
                    classNames={{
                      root: addedToCart && classes.addedToCart,
                      label: "add_to_cart_product",
                      inner: "add_to_cart_product",
                    }}
                    onClick={handleAdd}
                  >
                    {addedToCart
                      ? language.Added_To_Cart
                      : language.AddToCartBtn}
                  </Button>
                )}
                <p component="p" className={classes.price}>
                  {item.priceInclVat}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLastItem ? (
        <div style={{ marginBottom: "20px" }} />
      ) : (
        <Divider my="lg" size="sm" className={classes.divider} />
      )}
      <OptionsModal
        title={getName()}
        description={getDescription()}
        opened={opened}
        close={close}
        additionalFields={item.additionalFields}
        handleAdd={() => add(article)}
        extraInformation={extraInformation}
        setExtraInformation={setExtraInformation}
        allotmentId={allotmentId}
        availableOccasions={availableOccasions}
        day={day}
        setDay={(date) => setDay(date)}
      />
    </div>
  );
};

export default OptionsItem;
