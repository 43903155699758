import React from "react";
import classes from "./PageContent.module.css";

const PageContent = ({ children }) => {
  return (
    <section
      id="main-page-content"
      className={`${classes.pageContent} page__content`}
    >
      {children}
    </section>
  );
};

export default PageContent;
