import classes from "./Options.module.css";
import { useContext } from "react";
import { SaleContext } from "../common/saleProvider";
import OptionsItem from "./OptionsItems";
import { useSelector } from "react-redux";

const Item = ({ data }) => {
  const saleContext = useContext(SaleContext);
  const language = useSelector((state) => state.language);

  if (!data) return language.NoOptionsAvailable;
  const { sale } = saleContext.state;

  return (
    <div className={classes.itemWrapper}>
      <OptionsItem item={data} sale={sale} />
    </div>
  );
};

export default Item;
