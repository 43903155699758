import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Button } from "@mantine/core";

const OccasionButton = (props) => {
  const navigate = useNavigate();

  return (
    <>
      {props.click ? (
        <Button
          className={`primaryButton`}
          onClick={() => props.setStep(props.click)}
          disabled={props.disabled}
          aria-label={props.primaryText}
          size="md"
          fullWidth={true}
        >
          {props.primaryText}
        </Button>
      ) : (
        ""
      )}

      {props.submit ? (
        <Button
          className={`primaryButton`}
          disabled={props.disabled}
          aria-label={props.primaryText}
          size="md"
        >
          {props.primaryText}
        </Button>
      ) : (
        ""
      )}

      {props.redirect ? (
        <>
          <Button
            component={Link}
            role="link"
            tabIndex={0}
            className="primaryButton"
            to={props.redirect}
            style={{ display: "block" }}
            aria-label={props.primaryText}
            fullWidth
            size="md"
          >
            {props.primaryText}
          </Button>
        </>
      ) : (
        ""
      )}
      {props.text ? (
        <Button
          role="link"
          tabIndex={0}
          variant="outline"
          className="primaryButtonOutline"
          onClick={() => navigate("/")}
          size="md"
          fullWidth={true}
        >
          {props.text}
        </Button>
      ) : (
        ""
      )}
    </>
  );
};

export default OccasionButton;
