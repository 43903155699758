import Cookies from "universal-cookie";
import { v4 } from "uuid";
//import InitialState from "../reducers/initialState";

const makeId = () => v4();

const initialState = {
  culture: "sv-SE",
  currency: "SEK",
  cookieBanner: true,
  theme: "light",
  shopId: makeId(),
  consent: null,
};

export const setCookie = (key, value) => {
  return {
    type: "@EE/SET_COOKIE",
    key,
    value,
  };
};

export const expireCookie = (key) => {
  return {
    type: "@EE/EXPIRE_COOKIE",
    key,
  };
};

export const setCookieConsent = (key, value, options) => {
  return {
    type: "@EE/SET_CONSENT",
    key,
    value,
    options,
  };
};

export const cookieReducer = (keys, config) => (state, action) => {
  state = state || getInitialState(keys, config);

  switch (action.type) {
    case "@EE/EXPIRE_COOKIE": {
      const c = {};
      c[action.key] = null;

      return Object.assign({}, state, c);
    }
    case "@EE/SET_COOKIE": {
      const c = {};
      c[action.key] = action.value;
      return Object.assign({}, state, c);
    }
    case "@EE/SET_CONSENT": {
      const c = {};
      c[action.key] = action.value;
      return Object.assign({}, state, c);
    }
    default:
      return state;
  }
};

export const cookieMiddleware = (store) => (next) => (action) => {
  const cookies = new Cookies(null, { path: "/" });
  if (action.type === "@EE/SET_COOKIE") {
    cookies.set(action.key, action.value.toString(), { sameSite: "lax" });
  }
  if (action.type === "@EE/SET_CONSENT") {
    const options = {
      expires: new Date(action.options.expires),
      path: action.options.path,
      sameSite: action.options.sameSite,
    };
    cookies.set(action.key, action.value, options);
  } else if (action.type === "@EE/EXPIRE_COOKIE") {
    cookies.remove(action.key);
  }

  return next(action);
};

function getInitialState(keys, config) {
  const state = {};
  const cookies = new Cookies(null, { path: "/" });

  //Check if culture, currency or tag filter is in cookie
  if (cookies.get("shop-saleId") == null) {
    cookies.set("shop-saleId", initialState.shopId, {
      sameSite: "lax",
    });
  }
  if (cookies.get("shop-culture") == null) {
    cookies.set("shop-culture", config.defaultCulture || initialState.culture, {
      sameSite: "lax",
    });
  }
  if (cookies.get("shop-currency") == null) {
    cookies.set("shop-currency", initialState.currency, { sameSite: "lax" });
  }
  if (cookies.get("shop-consent") == null) {
    cookies.set("shop-consent", initialState.consent, { sameSite: "lax" });
  }

  for (var i = 0; i < keys.length; i++) {
    const key = keys[i];
    state[key] = cookies.get(key);
  }

  return state;
}
