import { Button, Modal } from "@mantine/core";
import { useState } from "react";

const TimerWarning = ({ isOpenModal, language, classes }) => {
  const [loading, setLoading] = useState(false);

  const forceReload = () => {
    window.location.reload();
    setLoading(true);
  };

  return (
    <Modal onClose={() => null} withCloseButton={false} opened={isOpenModal}>
      <h2 style={{ padding: "16px 24px" }}>
        {language ? language.Information : "Information"}
      </h2>
      <div style={{ padding: "16px 24px" }}>{language.Article_Expired}</div>
      <div className={classes.modalActions}>
        <Button
          className="primaryButton"
          onClick={forceReload}
          loading={loading}
          color="var(--button-primary-bg-color)"
        >
          {language ? language.Close : "Stäng"}
        </Button>
      </div>
    </Modal>
  );
};

export default TimerWarning;
