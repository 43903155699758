import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { Button, Modal } from "@mantine/core";
import Cultures from "./cultures";
import Currencies from "./currencies";
import Version from "./version";
import Flag from "./flag";
import { useDispatch, useSelector } from "react-redux";
import { setCulture, STATE_CULTURE } from "../../common/state";
import classes from "./Settings.module.css";
import { DataContext } from "../common/dataProvider";

const Settings = (props) => {
  const { data, refetch, setTranslated } = useContext(DataContext);
  const dispatch = useDispatch();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const { config, language, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const cultures = data?.cultures;

  const handleChangeLanguage = (lang) => {
    const findCulture = cultures.find((c) => c.name.split("-")[0] === lang);
    if (findCulture) dispatch(setCulture(findCulture.name, config));
    else {
      dispatch(setCulture("en-US", config));
      refetch();
    }
  };

  // Set HTML language
  useEffect(() => {
    document.documentElement.lang = culture.split("-")[0];
  }, [culture]);

  // Look for changes in the html lang attribute (Google Translate)
  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "lang"
        ) {
          handleChangeLanguage(document.documentElement.lang);
        }
        if (mutation.target.className.includes("translated")) {
          setTranslated(true);
        } else {
          setTranslated(false);
        }
      }
    });

    observer.observe(document.documentElement, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <Button
        variant="subtle"
        aria-label="Byt språk"
        onClick={() => setSettingsOpen(!settingsOpen)}
        tabIndex={0}
        className={clsx(classes.settingsButton, "header__settings-button")}
        color="inherit"
      >
        <Flag culture={culture} />
      </Button>
      <Modal
        title={language.Settings}
        classNames={{
          title: classes.modalTitle,
          header: classes.modalHeader,
          body: classes.modalBody,
        }}
        centered
        withCloseButton={true}
        transitionProps={{ transition: "fade" }}
        size={"lg"}
        fullScreen={window.innerWidth < 577}
        opened={settingsOpen}
        onClose={() => setSettingsOpen(!settingsOpen)}
      >
        <div>
          <Cultures />
          {!config?.hideCurrencySelector && (
            <>
              <hr className={classes.divider} />
              <Currencies />
            </>
          )}
          <hr className={classes.divider} />
          <Version />
        </div>
        <div className={classes.dialogAction}>
          <Button
            variant="default"
            onClick={() => setSettingsOpen(!settingsOpen)}
          >
            {language?.Cancel || "Cancel"}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Settings;
