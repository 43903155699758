import { Button, Title } from "@mantine/core";
import classes from "./CookieBanner.module.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ConsentContent from "./ConsentContent";
import { useDisclosure } from "@mantine/hooks";

const TrackingCookiesContent = ({ onAccept, onDeny, onClose }) => {
  const [language] = useSelector((state) => [state.language]);
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <div>
      <Title order={2} size="large" id="cookie-box-title">
        {language.CookieSettings}
      </Title>
      <p className={classes.cookieText} id="cookie-box-message">
        {language.WeUseCookies}{" "}
        <Link className="cookieLink" to="/security">
          {language.Cookies}
        </Link>{" "}
        {language.CookieConsentBody}
      </p>
      <div className={classes.footer}>
        <Button variant="light" onClick={onDeny}>
          Endast nödvändiga
        </Button>
        <Button className="secondaryButton" onClick={open}>
          Hantera kakor
        </Button>
        <Button className="primaryButton" onClick={onAccept}>
          {language.CookieConsent}
        </Button>
      </div>
      <ConsentContent
        opened={opened}
        close={() => {
          close();
          // onClose();
        }}
      />
    </div>
  );
};

export default TrackingCookiesContent;
