import moment from "moment";

// import all needed moment locale
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/sv";

export const LANGUAGE_LOADED = "@EE/LANGUAGE_LOADED";

export const languageReducer = (state = {}, action) => {
  switch (action.type) {
    case LANGUAGE_LOADED:
      return action.payload;
    default:
      return state;
  }
};

export const loadLanguage = (culture, config, dispatch) => {
  // set global moment locale to current culture
  moment.locale(culture);
  const getJson = (f) => {
    return fetch(f).then((x) => x.json());
  };

  const merge = (o, rest) => {
    var next = rest.pop();
    if (!next) return o;

    const merged = {
      ...o,
      ...next,
    };

    return merge(merged, rest);
  };

  const generalUri = `${process.env.PUBLIC_URL}/language.${culture}.json`;
  const specificUri = `${config.baseUrl}/ee-static/shop/${config.shopName}/Language/language.${culture}.json`;

  const files = [specificUri, generalUri].map((f) => getJson(f));

  Promise.all(files)
    .then((response) => merge({}, response))
    .then((json) => {
      dispatch({
        type: LANGUAGE_LOADED,
        payload: json,
      });
    });
};
