import React from "react";
import { Button } from "@mantine/core";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import classes from "./ButtonNavigation.module.css";

const ButtonNavigation = ({
  activeStep,
  onPrevClick,
  onNextClick,
  isDisabled,
  onClick,
}) => {
  const { language } = useSelector((state) => ({
    language: state.language,
  }));
  const prevClick = () => {
    if (onClick) {
      Promise.resolve(onClick()).then(() => onPrevClick());
    } else {
      onPrevClick();
    }
  };
  const nextClick = () => {
    if (onClick) {
      Promise.resolve(onClick()).then(() => onNextClick());
    } else {
      onNextClick();
    }
  };

  return (
    <div className={classes.wrapper}>
      {activeStep !== 0 && (
        <Button
          className="primaryButton backButton"
          size="md"
          leftSection={<FaArrowLeft />}
          onClick={() => {
            prevClick();
          }}
          aria-label={language.GoToPreviousStepBtn}
        >
          {language.Back}
        </Button>
      )}
      {activeStep === 0 && <div />}
      {activeStep !== 5 && (
        <Button
          className={`${activeStep === 0 && classes.firstButton} primaryButton`}
          size="md"
          rightSection={<FaArrowRight />}
          onClick={() => {
            nextClick();
          }}
          disabled={isDisabled}
        >
          {language.NextStep}
        </Button>
      )}
    </div>
  );
};

export default ButtonNavigation;
