import React, { useMemo, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mantine/core";
import { getAllowedCurrencies } from "./util";
import { setCurrency, STATE_CURRENCY } from "../../common/state";
import { DataContext } from "../common/dataProvider";

const Currencies = (props) => {
  const { data, refetch } = useContext(DataContext);
  const dispatch = useDispatch();
  const handleSetCurrency = (currency) => {
    dispatch(setCurrency(currency));
    refetch();
  };
  const { currency, language, config } = useSelector((state) => ({
    currency: state.cookies[STATE_CURRENCY],
    language: state.language,
    config: state.config,
  }));
  const { allowedCurrencies } = config;
  const currencies = data?.currencies;
  const filteredCurrencies = useMemo(
    () => getAllowedCurrencies(currencies, allowedCurrencies),
    [currencies, allowedCurrencies]
  );

  if (!filteredCurrencies) return null;

  return (
    <div>
      <h6 style={{ fontSize: "1rem" }}>{language.Currency}</h6>
      {filteredCurrencies.map((c, i) => {
        const isActive = c.name === currency;
        return (
          <Button
            variant="light"
            key={i}
            onClick={() => handleSetCurrency(c.name)}
            style={{ margin: "0 8px 8px 0", minWidth: "100px" }}
            disabled={isActive}
            data-testid={`button_${c.name}`}
          >
            {c.name}
          </Button>
        );
      })}
    </div>
  );
};

export default Currencies;
