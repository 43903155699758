import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../common/state";
import PageLayout from "../../components/Styles/PageLayout";
import PageContent from "../../components/Styles/PageContent";
import { useEffect, useState } from "react";
import axios from "axios";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";

let source;

const AccessibilityPage = (props) => {
  const { config, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const [text, setText] = useState("");

  const fetchFallback = async () => {
    axios
      .get(
        `${config.baseUrl}/${config.descriptionFolderUrl}/accessibility.sv-SE.md`,
        {
          cancelToken: source.token,
        }
      )
      .then((response) => {
        console.log(response);
        setText(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchMarkdown = async (cul) => {
    axios
      .get(
        `${config.baseUrl}/${config.descriptionFolderUrl}/accessibility.${cul}.md`,
        {
          cancelToken: source.token,
        }
      )
      .then((response) => {
        setText(response.data);
      })
      .catch((error) => {
        console.log(
          "Couldn't find accessibility." + cul + ", falling back to sv-SE"
        );
        fetchFallback();
      });
  };

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    source = CancelToken.source();
    if (config && culture) {
      fetchMarkdown(culture);
    }
  }, [culture, config]);

  return (
    <PageLayout>
      <PageContent>
        <ReactMarkdown
          className="accessibility-text"
          rehypePlugins={[rehypeRaw]}
        >
          {text}
        </ReactMarkdown>
      </PageContent>
    </PageLayout>
  );
};

export default AccessibilityPage;
