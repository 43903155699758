import React, { useEffect, useState } from "react";
import "./styles.css";
import Header from "./Header";
import Week from "./Week";
import WeekDay from "./WeekDay";
import { Loader } from "@mantine/core";

const Calendar = ({
  allotment,
  allotmentData,
  loading,
  allotmentSettings,
  chosenDate,
  setChosenDate,
  currentDate,
  setCurrentDate,
  selectedDate,
  setSelectedDate,
  setFilteredData,
  scrollIntoView,
  firstDate,
  latestDate,
  language,
  cultureLocale,
  pathname,
}) => {
  const [newMonthData, setNewMonthData] = useState(allotmentData);
  useEffect(() => {
    setNewMonthData(allotmentData);
  }, [allotmentData]);

  if (allotment.length === 0) return null;
  return (
    <>
      {allotmentSettings[0] && (
        <div className={`ticket-calendar`}>
          <Header
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            selectedDate={selectedDate}
            allotmentSettings={allotmentSettings}
            firstDate={firstDate}
            latestDate={latestDate}
            cultureLocale={cultureLocale}
            pathname={pathname}
            language={language}
          />
          <div
            className={`${
              loading === true ? "loadingbackground" : ""
            } ticket-dayWrapper`}
          >
            <Week currentDate={currentDate} cultureLocale={cultureLocale} />
            <WeekDay
              currentDate={currentDate}
              setSelectedDate={setSelectedDate}
              allotment={allotment}
              allotmentData={newMonthData}
              loading={loading}
              allotmentSettings={allotmentSettings}
              chosenDate={chosenDate}
              setChosenDate={setChosenDate}
              setFilteredData={setFilteredData}
              scrollIntoView={scrollIntoView}
              language={language}
              cultureLocale={cultureLocale}
            />
          </div>
          {loading === true ? (
            <div className="loadingwrapper">
              <Loader
                className="loadingspinner"
                style={{ color: "rgb(53, 53, 53)" }}
              />
              <p className="loadingtext">{language.LoadingText}</p>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

export default Calendar;
