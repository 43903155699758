import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import TicketImage from "./TicketImage";
import classes from "./TicketIntroduction.module.css";

const TicketIntroduction = (props) => {
  const { urlParams, ticketData, altText, text, language } = props;
  const [hideLongText, setHideLongText] = useState(true);

  const filter = ticketData.filter((el) => el.slug.toLowerCase() === urlParams);
  useEffect(() => {
    setHideLongText(false);
  }, [text]);

  return (
    <div className={`${classes.ticketWrapper} ticketWrapper`}>
      {filter && filter[0]?.image && (
        <TicketImage
          className="ticketImage"
          imageUrl={`${
            filter[0].image
              ? `${props.config.imageFolderUrl}${filter[0].image}`
              : ""
          }`}
          altText={altText}
        />
      )}
      {filter && filter.length >= 1 ? (
        <div
          className={`ticketTextWrapper ${classes.ticketTextWrapper} ${
            filter[0].image
              ? classes.ticketTextPadding
              : classes.ticketTextPaddingNoImg
          }  `}
        >
          <div>
            <div className={classes.showMore}>
              {text.length > 300 && window.screen.availWidth <= 768 ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <ReactMarkdown
                    className={
                      hideLongText === true
                        ? classes.ticketText
                        : classes.ticketTextGradient
                    }
                    rehypePlugins={[rehypeRaw]}
                  >
                    {hideLongText === false
                      ? text.substr(0, 300) + "... "
                      : text}
                  </ReactMarkdown>
                </div>
              ) : (
                <ReactMarkdown
                  className="ticketTextWrapper"
                  rehypePlugins={[rehypeRaw]}
                >
                  {text}
                </ReactMarkdown>
              )}
              {text.length > 300 && window.screen.availWidth <= 768 ? (
                <button
                  className={classes.readMoreButton}
                  style={{ fontSize: "1rem", paddingLeft: 0 }}
                  onClick={() => setHideLongText(!hideLongText)}
                >
                  {hideLongText === false
                    ? language.ReadMore
                    : language.ShowLessText}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TicketIntroduction;
