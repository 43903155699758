import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { GrFormClose } from "react-icons/gr";
import "./search.css";

const SearchInput = ({
  language,
  searchValue,
  setSearchValue,
  toggleSearch,
  setToggleSearch,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const ref = useRef();

  useEffect(() => {
    if (toggleSearch) {
      setSearchTerm("");
      ref.current.focus();
    }
  }, [toggleSearch]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setSearchValue(searchTerm);
    }, 350);
    return () => clearTimeout(delayDebounce);
  }, [searchTerm]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setSearchTerm("");
        setToggleSearch(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <>
      <input
        data-isactive={toggleSearch}
        className="search-input"
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        ref={ref}
        placeholder={language.WriteToSearch + "..."}
        aria-label={language.Search}
      />
      <button
        data-isactive={toggleSearch}
        className="search-close removeBorderBg"
        onClick={() => {
          setSearchTerm("");
          setToggleSearch(false);
        }}
        aria-label={language?.Close}
      >
        <GrFormClose
          data-isactive={toggleSearch}
          className="search-close-icon"
        />
      </button>
    </>
  );
};

export default SearchInput;
