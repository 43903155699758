import React, { useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import { Text } from "@mantine/core";
import { useSelector } from "react-redux";

const GroupArticleButtons = ({
  max,
  ticketData,
  groupTicketCart,
  setGroupTicketCart,
  item,
}) => {
  const [quantity, quantitySet] = useState(0);
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));

  const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30,
  };

  const disabled = useMemo(() => {
    if (config?.useMultiTicketQuantityOverride) {
      if (max === 0) return true;
    } else if (
      !config?.useMultiTicketQuantityOverride &&
      quantity === ticketData.maximumNumber
    ) {
      return true;
    } else {
      return false;
    }
  }, [quantity, max]);

  const ticketOwners = useMemo(() => {
    const getOwners = item?.ticketOwners?.filter(
      (el) => el.ticketHolderCategoryId === ticketData.categoryId
    );
    if (getOwners && getOwners.length > 0) {
      return getOwners?.[0];
    }
    return null;
  }, [item]);

  const increase = () => {
    if (!ticketOwners) {
      item.ticketOwners.push({
        quantity: 1,
        ticketHolderCategoryId: ticketData.categoryId,
      });
    }
    quantitySet(quantity + 1);
  };

  const decrease = () => {
    if (quantity > 0) {
      quantitySet(quantity - 1);
    }
  };

  const updateGroupTicketCart = (newQuantity) => {
    const updatedCart = groupTicketCart.map((item) => {
      if (item.ticketOwners) {
        return {
          ...item,
          ticketOwners: item.ticketOwners
            .map((owner) => {
              if (owner.ticketHolderCategoryId === ticketData.categoryId) {
                if (newQuantity === 0) {
                  return null;
                } else {
                  return {
                    ...owner,
                    quantity: newQuantity,
                  };
                }
              }
              return owner;
            })
            .filter(Boolean),
        };
      }
      return item;
    });

    setGroupTicketCart(updatedCart);
  };

  useEffect(() => {
    updateGroupTicketCart(quantity);
  }, [quantity]);

  return (
    <div className="articleRightColumnToggle" style={{ minHeight: "3rem" }}>
      <motion.div
        className="switch"
        data-ison={quantity > 0 ? true : ""}
        transition={spring}
        layout
      />
      <motion.div
        className={`handle`}
        tabIndex="-1"
        data-ison={quantity > 0 ? true : ""}
        whileTap={{ scale: 1.2 }}
        layout
        transition={spring}
        style={{
          pointerEvents: disabled ? "none" : "",
        }}
      >
        <button
          className={`articleButton ${disabled ? "disableAddButton" : ""}`}
          onClick={() => increase()}
          aria-label={`${language?.AddQuantity} ${ticketData.categoryName}`}
          style={{ border: "none", background: "none" }}
          disabled={disabled}
        >
          <MdAddCircle className="buttonIcons" />
        </button>
      </motion.div>
      <motion.div
        className="event-count articleQty"
        data-ison={quantity > 0 ? true : false}
        layout
        transition={spring}
        aria-live="assertive"
      >
        <Text
          size="xl"
          fw={"bold"}
          component="span"
          aria-label={`${ticketData.categoryName} ${language.Quantity} ${quantity}`}
        >
          {quantity}
        </Text>
      </motion.div>
      <motion.div
        data-ison={quantity > 0 ? true : ""}
        tabIndex="-1"
        className="handle2"
        whileTap={{ scale: 1.2 }}
        layout
        transition={spring}
      >
        <button
          className={`articleButtonRemove ${
            quantity === 0 ? "event-hideScreen" : ""
          }`}
          onClick={() => decrease()}
          aria-label={`${language?.DecreaseQuantity} ${ticketData.categoryName}`}
          disabled={quantity === 0 ? true : false}
          style={{ border: "none", background: "none" }}
        >
          <MdRemoveCircle className="buttonIcons" />
        </button>
      </motion.div>
    </div>
  );
};

export default GroupArticleButtons;
