import React, { useContext } from "react";
import { startOfWeek, format, addDays } from "date-fns";
import sv from "date-fns/locale/sv";
import { DataContext } from "../common/dataProvider";

const Week = ({ cultureLocale }) => {
  const { translated } = useContext(DataContext);

  const days = [];
  const startDate = startOfWeek(new Date(), { locale: sv });
  for (let i = 0; i < 7; i++) {
    const day = format(addDays(startDate, i), translated ? "EEEE" : "E", {
      locale: cultureLocale,
    });
    days.push(
      <div
        className={translated ? "abbreviation" : "calendar-column col-center"}
        key={i}
        aria-label={day}
      >
        <span>{day}</span>
      </div>
    );
  }
  return (
    <div
      className={`calendar-days calendar-row ${
        translated ? "monospace translation-row" : ""
      }`}
      style={{
        justifyContent: "space-around",
      }}
    >
      {days}
    </div>
  );
};

export default Week;
