import React, { useEffect, useMemo, useState } from "react";
import { DatePicker } from "@mantine/dates";
import { Loader, Text, Title } from "@mantine/core";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mantine/hooks";
import "../styles.css";
import "dayjs/locale/sv";
import "dayjs/locale/en";
import "dayjs/locale/de";
import "dayjs/locale/da";
import {
  addYears,
  endOfMonth,
  format,
  getDate,
  isSameMonth,
  startOfMonth,
} from "date-fns";
import { STATE_CULTURE } from "../../../common/state";
import classes from "./DateChooser.module.css";
import DatePickerSkeleton from "./DatePickerSkeleton";

const DateChooser = ({
  initialData,
  initialLoading,
  occasions,
  form,
  calendarDatesSet,
}) => {
  const { language, culture } = useSelector((state) => ({
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const [value, valueSet] = useState(null);
  const [month, onMonthChange] = useState(new Date());
  const matches = useMediaQuery("(max-width: 768px)");

  const exclude = (date) => {
    const check =
      occasions &&
      occasions.some(
        (el) =>
          isSameMonth(date, new Date(el)) &&
          date.getDate() === getDate(new Date(el))
      );
    if (!check) return true;
  };

  useEffect(() => {
    if (value) {
      form.setFieldValue("occasion", format(value, "yyyy-MM-dd"));
    }
  }, [value]);

  useEffect(() => {
    if (form.values.occasion) {
      valueSet(new Date(form.values.occasion));
    }
    calendarDatesSet({
      from: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
      to: format(addYears(new Date(), 1), "yyyy-MM-dd'T'HH:mm"),
    });
  }, []);

  useEffect(() => {
    calendarDatesSet({
      from: startOfMonth(month),
      to: endOfMonth(month),
    });
  }, [month]);

  const sortedOccasions = useMemo(() => {
    const clone = initialData && [...initialData.publicBookingOccasions];
    if (clone) {
      return clone.sort((a, b) => new Date(a) - new Date(b));
    }
  }, [initialData]);

  useEffect(() => {
    if (occasions && occasions.length > 0 && initialData && !value) {
      onMonthChange(new Date(initialData?.publicBookingOccasions[0]));
    }

    if (occasions && occasions.length === 0 && initialData && !value) {
      onMonthChange(new Date(initialData?.publicBookingOccasions[0]));
    }

    if (value) {
      onMonthChange(value);
    }
  }, [initialData, value]);

  return (
    <div className={classes.container} style={{ width: matches ? "100%" : "" }}>
      {initialLoading && <Loader variant="dots" className={classes.loader} />}

      <div className={classes.titleWrapper}>
        <Title order={3} style={{ fontSize: "1rem", fontWeight: 500 }}>
          {language.Date}
        </Title>
        <Text>{language.ChooseDateToContinue}</Text>
      </div>
      <div>
        {!sortedOccasions && <DatePickerSkeleton />}
        {sortedOccasions && (
          <DatePicker
            translate="no"
            value={value}
            onChange={valueSet}
            className={`${classes.calendar} pbCalendar`}
            classNames={{
              calendarBase: classes.calendarBase,
              calendarCell: classes.center,
              calendarHeader: classes.calendarHeader,
              month: classes.month,
              monthsList: classes.monthsList,
              levelsGroup: classes.levelsGroup,
              calendarHeaderControl: classes.calendarHeaderControl,
              calendarHeaderControlIcon: classes.calendarHeaderControlIcon,
            }}
            size={"md"}
            locale={culture.split("-")[0]}
            defaultDate={
              sortedOccasions.length > 0 && new Date(sortedOccasions?.[0])
            }
            excludeDate={(date) => exclude(date)}
            minDate={new Date(sortedOccasions?.[0])}
            maxDate={new Date(sortedOccasions?.[sortedOccasions.length - 1])}
            hideOutsideDates
            style={{ opacity: initialLoading && 0.5 }}
            weekendDays={[]}
          />
        )}
        {!initialLoading && occasions && occasions.length === 0 && (
          <div className={classes.alertWrapper}>
            <Text component="span" role="alert">
              {language.NoOccasionsAvailable}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default DateChooser;
