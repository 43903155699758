import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const goBackUrl = localStorage.getItem("goBackUrl");
    if (goBackUrl && goBackUrl.startsWith("publicBooking")) {
      navigate(goBackUrl.split("?/ticketshop")?.[1]);
    }
    window.scrollTo(0, 0);
    if (pathname !== "/options") {
      localStorage.setItem("goBackUrl", window.location.href);
    }
  }, [pathname]);

  return null;
}
