import { useState } from "react";
import { DatePicker, DatePickerInput } from "@mantine/dates";
import { format, isSameDay } from "date-fns";
import { Button, Popover } from "@mantine/core";
import classes from "./OptionsItems.module.css";
import CalendarIcon from "../Ticket/icons/calendarIcon";
import { useSelector } from "react-redux";
import { useFocusReturn } from "@mantine/hooks";

const OptionsCalendar = ({ availableOccasions, day, setDay, isModal }) => {
  const [language] = useSelector((state) => [state.language]);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  useFocusReturn({
    opened: openDatePicker,
    shouldReturnFocus: true,
  });

  const getDayProps = (date) => {
    if (availableOccasions) {
      const isAvailable = availableOccasions.find((el) =>
        isSameDay(date, new Date(el.time))
      );
      if (!isAvailable) {
        return {
          disabled: true,
        };
      }
    }
  };

  const handleAdd = (date) => {
    const filter = availableOccasions.find((el) =>
      isSameDay(date, new Date(el.time))
    );
    setDay(new Date(filter.time));
    setOpenDatePicker(false);
  };

  if (isModal) {
    return (
      <DatePickerInput
        value={day ? new Date(day) : null}
        onChange={(value) => {
          handleAdd(value);
        }}
        size="sm"
        minDate={new Date(availableOccasions?.[0]?.time)}
        maxDate={availableOccasions?.[availableOccasions.length - 1]?.time}
        getDayProps={getDayProps}
        weekendDays={[]}
        leftSection={<CalendarIcon />}
        label={language.ChooseDateHeader}
        placeholder={language.ChooseDateHeader}
        locale="sv"
        required
        classNames={{ day: classes.datePickerInput }}
        valueFormat="D MMMM YYYY"
      />
    );
  }

  return (
    <Popover
      position="bottom"
      withArrow
      shadow="md"
      opened={openDatePicker}
      onChange={setOpenDatePicker}
      trapFocus
    >
      <Popover.Target>
        <Button
          size="xs"
          variant="subtle"
          className={classes.extraButton}
          leftSection={
            <div className={classes.calendarIcon}>
              <CalendarIcon />
            </div>
          }
          onClick={() => setOpenDatePicker((open) => !open)}
        >
          {!day
            ? language.ChooseDateHeader
            : format(new Date(day), "yyyy-MM-dd HH:mm")}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <DatePicker
          value={new Date(day)}
          onChange={(value) => {
            handleAdd(value);
          }}
          size="sm"
          minDate={new Date(availableOccasions?.[0]?.time)}
          maxDate={availableOccasions?.[availableOccasions.length - 1]?.time}
          getDayProps={getDayProps}
          weekendDays={[]}
          locale="sv"
        />
      </Popover.Dropdown>
    </Popover>
  );
};

export default OptionsCalendar;
