import {
  Button,
  Container,
  Loader,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATE_CURRENCY } from "../../../common/state";
import Article from "./Article";
import { CART_ARTICLE_ADD, Context, SUSPEND_OPEN_CART } from "../../../context";
import {
  addArticleBatch,
  addArticleGroupTicket,
  addToCartTracking,
} from "../../../common/sale";
import { useDisclosure } from "@mantine/hooks";
import MultiTicketModal from "./MultiTicketModal";
import { notification } from "../../common/util";

const ArticleList = ({ chosenOccasion, articles, availableOccasions }) => {
  const [cart, cartSet] = useState([]);
  const [sortedArticles, setSortedArticles] = useState([]);
  const [groupTicketCart, setGroupTicketCart] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [cartTotal, setCartTotal] = useState(0);
  const [isLoading, isLoadingSet] = useState(false);
  const [addedToCart, addedToCartSet] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [openedGroupItem, setOpenedGroupItem] = useState(null);
  const { language, currency } = useSelector((state) => ({
    language: state.language,
    currency: state.cookies[STATE_CURRENCY],
  }));
  const { dispatch } = useContext(Context);
  const rdxDispatch = useDispatch();
  const focusRef = useRef(null);
  const max = Math.min(
    chosenOccasion?.allotment?.maxPerSale,
    chosenOccasion?.remaining
  );

  const isTabletOrMobile = useMediaQuery("(max-width: 993px)");

  useEffect(() => {
    cartSet([]);
  }, [availableOccasions, chosenOccasion]);

  const total = (item) => {
    switch (currency) {
      case "SEK":
      case "DKK":
      case "NOK":
        return item + " kr";
      case "EUR":
        return "€" + item;
      case "GBP":
        return "£" + item;
      case "USD":
        return "$" + item;
      default:
        return item + currency;
    }
  };

  const productPrice = (item) => {
    if (item && item.length > 0) {
      const regex = /[$€£kr]/g;
      let newPrice = item.replace(regex, "");
      if (newPrice.trim().split(".")[1] === "00") {
        return newPrice.split(".")[0];
      } else {
        return newPrice;
      }
    }
  };

  const calcCartValue = () => {
    const removeChar = (price) => {
      if (price) {
        let newPrice = price && price.replaceAll(/[^0-9,.]/g, "");
        const commaIndex = newPrice.lastIndexOf(",");
        if (commaIndex !== -1) {
          if (commaIndex === 1) {
            newPrice =
              newPrice.substring(0, commaIndex) +
              newPrice.substring(commaIndex + 1);
          } else {
            newPrice =
              newPrice.substring(0, commaIndex) +
              "." +
              newPrice.substring(commaIndex + 1);
          }
        }
        return parseFloat(newPrice);
      }
    };
    let total = 0;
    for (let i = 0; i < cart.length; i++) {
      if (cart[i]?.price?.amountInclVat !== 0) {
        total += parseFloat(
          removeChar(cart[i].priceInclVat)
            ? removeChar(cart[i].priceInclVat)
            : removeChar(cart[i].displayPrice)
            ? removeChar(cart[i].displayPrice)
            : ""
        );
      }
    }
    const groupTicket = articles?.reduce((acc, curr) => {
      const find = groupTicketCart.filter((item) => item?.plu === curr?.plu);
      if (find?.length > 0) {
        find.map((item) => item.plu === curr.plu && acc.push(curr));
      }
      return acc;
    }, []);
    if (groupTicket && groupTicket.length > 0) {
      groupTicket.map((item) => (total += item?.price?.amountInclVat));
    }
    setCartTotal(total.toFixed(2));
  };

  useEffect(() => {
    calcCartValue();
  }, [cart, groupTicketCart]);

  const reduceCart = Object.values(
    cart.reduce((acc, curr, i) => {
      const hasExtraInformation =
        curr.extraInformation && Object.keys(curr.extraInformation)?.length > 0;
      const uniqueKey = hasExtraInformation
        ? `${curr.plu}-${JSON.stringify(curr.extraInformation)}`
        : curr.plu;

      if (hasExtraInformation && !acc[uniqueKey]) {
        acc[uniqueKey] = {
          plu: curr.plu,
          quantity: 1,
          allotmentId: curr.allotmentId,
          allotmentOccasion: curr.timeOccasions,
          extraInformation: curr.extraInformation,
        };
      } else if (!hasExtraInformation && !acc[uniqueKey]) {
        acc[uniqueKey] = {
          plu: curr.plu,
          quantity: 1,
          allotmentId: curr.allotmentId,
          allotmentOccasion: curr.timeOccasions,
        };
      } else {
        acc[uniqueKey].quantity += 1;
      }
      return acc;
    }, {})
  );

  const trackReduceCart = Object.values(
    cart.reduce((acc, curr) => {
      if (!acc[curr.plu]) {
        acc[curr.plu] = {
          id: curr.plu,
          quantity: 1,
          price: parseFloat(productPrice(curr.priceInclVat).replace(",", ".")),
          name: curr.name,
        };
      } else {
        acc[curr.plu].quantity += 1;
      }
      return acc;
    }, {})
  );

  useEffect(() => {
    let addedToCartTimer = setTimeout(() => {
      addedToCartSet(null);
    }, 3000);
    return () => {
      clearTimeout(addedToCartTimer);
    };
  }, [addedToCart]);

  const trackGroupTicket = () => {
    const ticket = articles.filter((article) =>
      groupTicketCart.map((item) => item.plu).includes(article.plu)
    );
    const trackingGroupTicket = ticket.reduce((acc, curr) => {
      const existingItem = acc.find((item) => item.id === curr.plu);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        acc.push({
          id: curr.plu,
          quantity: 1,
          price: curr.price.amountInclVat,
          name: curr.name,
        });
      }
      return acc;
    }, []);
    return trackingGroupTicket;
  };

  const cb = (status, msg) => {
    if (status >= 400) {
      notification("error", language.ErrorMsgCouldNotAddToCart);
      cartSet([]);
      setGroupTicketCart([]);
      isLoadingSet(false);
    }
  };

  const cbFinally = () => {
    dispatch({
      type: CART_ARTICLE_ADD,
      payload: reduceCart,
    });
    dispatch({
      type: SUSPEND_OPEN_CART,
      payload: false,
    });
    cartSet([]);
    setGroupTicketCart([]);
    addedToCartSet("success");
    isLoadingSet(false);
  };

  const add = async () => {
    isLoadingSet(true);
    dispatch({
      type: SUSPEND_OPEN_CART,
      payload: true,
    });
    try {
      if (groupTicketCart?.length > 0) {
        await new Promise((resolve) => {
          rdxDispatch(
            addArticleGroupTicket(groupTicketCart, (status, msg) => {
              resolve();
              cb(status, msg);
              addToCartTracking(currency, trackGroupTicket());
            })
          );
        });
      }

      if (reduceCart?.length > 0) {
        await new Promise((resolve) => {
          rdxDispatch(
            addArticleBatch(reduceCart, (status, msg) => {
              cb(status, msg);
              resolve();
              addToCartTracking(currency, trackReduceCart);
            })
          );
        });
      }
    } catch (error) {
      console.error("Error during add operation:", error);
    } finally {
      cbFinally();
    }
  };

  const disabledGroupAddToCart = () => {
    return groupTicketCart.every((item) => item.ticketOwners.length === 0);
  };

  const openModal = (groupItem) => {
    open();
    setOpenedGroupItem(groupItem);
  };

  const removeGroupTicket = (ticket) => {
    const findGroupItem = groupTicketCart.filter(
      (item) => item.plu === ticket.plu
    );
    if (findGroupItem.length === 1) {
      const filterOutGroupItem = groupTicketCart.filter(
        (item) => item.plu !== findGroupItem[0].plu
      );
      setGroupTicketCart(filterOutGroupItem);
    }
    if (findGroupItem.length > 1) {
      setGroupTicketCart(findGroupItem.slice(0, -1));
    }
  };

  const closeAndConfirm = () => {
    close();
    setOpenedGroupItem(null);
  };

  const closeModal = () => {
    removeGroupTicket(openedGroupItem);
    setOpenedGroupItem(null);
    close();
  };

  const returnFocus = () => {
    focusRef.current.focus();
  };

  useEffect(() => {
    // Sort articles when selecting an occasion
    if (articles) {
      const clone = [...articles];
      setSortedArticles(clone.sort((a, b) => a.plu - b.plu));
    }
  }, [articles]);

  // Count the number of articles in the cart
  const articleQuantityInCart = useMemo(() => {
    return reduceCart.reduce((acc, cur) => (acc += cur.quantity), 0);
  }, [reduceCart]);

  useEffect(() => {
    // Compare number of articles in cart to max (maxPerSale or remaining allotments)
    if (articleQuantityInCart >= max) {
      setDisabledBtn(true);
    } else if (groupTicketCart && groupTicketCart.plu) {
      setDisabledBtn(true);
    } else {
      setDisabledBtn(false);
    }
  }, [groupTicketCart, articleQuantityInCart]);

  const item = useMemo(() => {
    const find = groupTicketCart.filter(
      (item) => item?.plu === openedGroupItem?.plu
    );
    if (find && openedGroupItem) {
      return find?.[find.length - 1];
    }
  }, [groupTicketCart, openedGroupItem]);

  return (
    <Container
      className="event-article-list-wrapper"
      px="0"
      my="sm"
      style={{ position: "relative" }}
    >
      {chosenOccasion && (
        <Stack>
          {sortedArticles.map((el, i) => (
            <div key={i}>
              <Article
                key={el.id}
                chosenOccasion={chosenOccasion}
                disabledBtn={disabledBtn}
                ticketData={el}
                cart={cart}
                cartSet={cartSet}
                availableOccasions={availableOccasions}
                openModal={openModal}
                groupTicketCart={groupTicketCart}
                setGroupTicketCart={setGroupTicketCart}
                removeGroupTicket={removeGroupTicket}
                item={item}
              />
              {el?.isMultiTicket && (
                <MultiTicketModal
                  opened={openedGroupItem?.plu === el?.plu && opened}
                  close={closeAndConfirm}
                  closeModal={closeModal}
                  language={language}
                  data={el}
                  item={item}
                  groupTicketCart={groupTicketCart}
                  setGroupTicketCart={setGroupTicketCart}
                  disabledGroupAddToCart={disabledGroupAddToCart}
                  returnFocus={returnFocus}
                />
              )}
            </div>
          ))}
        </Stack>
      )}
      <Container px="0" py="xl">
        <SimpleGrid cols={isTabletOrMobile ? 1 : 2}>
          <div>
            {(cart.length > 0 || groupTicketCart) && (
              <>
                <div
                  style={{
                    gap: 0,
                    marginTop: "-0.35rem",
                    textAlign: isTabletOrMobile && "right",
                  }}
                >
                  <Title order={5}>{language.Total}</Title>
                  <Text size="md" fw={500} component="p" style={{ margin: 0 }}>
                    {total(cartTotal)}
                  </Text>
                </div>
              </>
            )}
          </div>
          <div>
            <div aria-live="assertive" style={{ textAlign: "right" }}>
              <Button
                className={`add_to_cart_event ${
                  addedToCart === "success"
                    ? "mantine-Primary-btn-success"
                    : "mantine-Primary-btn"
                }`}
                classNames={{
                  label: "add_to_cart_event",
                  inner: "add_to_cart_event",
                }}
                size="md"
                radius="sm"
                aria-label={language.AddToCartBtn}
                color={
                  addedToCart === "success"
                    ? "green"
                    : "var(--button-primary-bg-color)"
                }
                ref={focusRef}
                disabled={
                  addedToCart !== "success" &&
                  cart.length === 0 &&
                  disabledGroupAddToCart()
                }
                onClick={add}
                style={{
                  opacity: isLoading ? 0.7 : 1,
                  transition: "all 0.2s ease-in-out",
                  marginTop: "0.1rem",
                  pointerEvents:
                    cart.length === 0 && disabledGroupAddToCart() ? "none" : "",
                  width: addedToCart === "success" && "100%",
                }}
                fullWidth={isTabletOrMobile && true}
                loading={isLoading}
              >
                {addedToCart === "success" && !isLoading
                  ? language.Added_To_Cart
                  : language.AddToCartBtn}
              </Button>
            </div>
          </div>
        </SimpleGrid>
      </Container>
    </Container>
  );
};

export default ArticleList;
