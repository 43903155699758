import slugify from "../../components/common/slugify";

// const getAllArticles = (tags) => {
//   let ids = [];
//   let articles = [];
//   tags.forEach((t) => {
//     t.articles.forEach((a) => {
//       if (!ids.includes(a.id)) {
//         ids = [...ids, a.id];
//         articles = [...articles, a];
//       }
//     });
//   });
//   return articles;
// };

const extendArticle = (
  productConfig = {},
  a = {},
  config,
  imageUri,
  culture
) => {
  const { imageFolderUrl, descriptionFolderUrl, thumbnailsFolderUrl } = config;

  // If it's a product that only exists in config
  const plu = a.plu ? a.plu : productConfig.plu;
  // If a is productGroup with useGroupsAsItems
  const uri = config?.apiUriOverride?.startsWith("http")
    ? config.apiUriOverride
    : config.apiUri;
  if (a.articles)
    return {
      ...a,
      name: a.name ? a.name : productConfig.name,
      plu,
      description: productConfig.description
        ? `${descriptionFolderUrl}${productConfig.description}`
        : undefined,
      id: a.id,
      imageUri,
      images: imageUri
        ? [`${uri}/articleimage/${imageUri}`]
        : !plu && productConfig.images
        ? productConfig.images.map(
            (img) => `${config.baseUrl}${imageFolderUrl}${img}`
          )
        : undefined,
      thumbnail: productConfig.thumbnail
        ? `${config.baseUrl}${thumbnailsFolderUrl}${productConfig.thumbnail}`
        : undefined,
      isInfo: productConfig.isInfo || false,
      isModification: productConfig.isModification || false,
      isOpenPrice: productConfig.isOpenPrice,
      openPriceValues:
        productConfig.openPriceValues?.length > 0
          ? productConfig.openPriceValues
          : null,
      displayPrice: a.priceInclVat,
      slug: `products/${plu}`,
    };

  return {
    ...a,
    name:
      a.translation?.find((t) => t.culture === culture)?.name ||
      a.translation?.find((t) => t.culture === "sv-SE")?.name ||
      a.name ||
      productConfig.name ||
      `Product Name (${plu})`,
    plu,
    description: productConfig.description
      ? `${descriptionFolderUrl}${productConfig.description}`
      : undefined,
    id: a.id,
    imageUri,
    images: imageUri ? [`${uri}/articleimage/${imageUri}`] : undefined,
    thumbnail: productConfig.thumbnail
      ? `${config.baseUrl}${thumbnailsFolderUrl}${productConfig.thumbnail}`
      : undefined,
    isInfo: productConfig.isInfo || false,
    isModification: productConfig.isModification || false,
    isOpenPrice: productConfig.isOpenPrice,
    openPriceValues:
      productConfig.openPriceValues?.length > 0
        ? productConfig.openPriceValues
        : null,
    displayPrice: a.priceInclVat,
    slug: `products/${plu}`,
  };
};

export const getProduct = (data, config, id, culture) => {
  let productGroupConfig = config.productGroupConfig.find(
    (c) => slugify(c.name) === id
  );
  if (!data || (productGroupConfig && !productGroupConfig?.useGroupsAsItems))
    return undefined;

  const productConfig = productGroupConfig?.useGroupsAsItems
    ? productGroupConfig
    : config.productConfig.find((c) => c.plu === Number(id));
  let a = productGroupConfig?.useGroupsAsItems
    ? // Checks if product is productGroup with useGroupsAsItems
      data.tags.find((t) => slugify(t.name) === id)
    : // If not, looks for article in data.articles
      data.articles.find((article) => article.plu === Number(id));

  const imageUri = productGroupConfig?.useGroupsAsItems
    ? undefined
    : data.articles.find((article) => article.plu === Number(id))
        ?.imageFilename;

  return extendArticle(productConfig, a, config, imageUri, culture);
};
