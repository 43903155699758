import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "./Button";
import "./styles.css";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useLocation } from "react-router";
import ChosenButton from "./ChosenButton";

const Header = ({
  bookingData,
  section,
  setSection,
  scrollIntoView,
  language,
  setIsVisible,
  settingsData,
  config,
  culture,
}) => {
  const [introduction, setIntroduction] = useState();
  const [newContent, setNewContent] = useState();
  const location = useLocation();
  const urlPathName = location.pathname.split("/").slice(-1);

  const getText = (data) => {
    const filter =
      data.translations &&
      data?.translations?.filter((el) => el.culture === culture);
    if (filter?.[0]?.name && filter?.[0]?.name !== undefined) {
      return filter?.[0]?.name;
    } else {
      return data.name;
    }
  };

  useEffect(() => {
    axios
      .get(
        `${config.baseUrl}/ee-static/shop/${config.shopName}//Texts/article-booking-${urlPathName}.${culture}.md`
      )
      .then((data) => setIntroduction(data.data))
      .catch((err) => {
        console.log(err);
      });
  }, [urlPathName]);

  useEffect(() => {
    setNewContent(bookingData);
  }, [bookingData, location.pathname]);

  return (
    <>
      {newContent && newContent.length > 0 ? (
        <div
          className="articleHeaderWrapper"
          style={{
            display: newContent.length === 1 ? "none" : "block",
          }}
        >
          <div>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {introduction}
            </ReactMarkdown>
          </div>
          <div className="buttonWrapper">
            {newContent.length > 1
              ? newContent.map((el, i) => (
                  <div key={el + i}>
                    <Button
                      text={getText(el)}
                      section={section}
                      setSection={setSection}
                      data={el}
                      scrollIntoView={scrollIntoView}
                      setIsVisible={setIsVisible}
                    />
                  </div>
                ))
              : newContent.map((el, i) => (
                  <div key={el + i}>
                    <ChosenButton
                      text={el?.name}
                      section={section}
                      setSection={setSection}
                      data={el}
                      scrollIntoView={scrollIntoView}
                      setIsVisible={setIsVisible}
                    />
                  </div>
                ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Header;
