import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import animationData from "./animations/1103-confetti-outline-edited.json";

const ConfirmationAnimation = ({ step }) => {
  const animationContainer = useRef(null);
  const anim = useRef(null);

  useEffect(() => {
    if (animationContainer.current) {
        anim.current = lottie.loadAnimation({
          container: animationContainer.current,
          renderer: "svg",
          loop: false,
          autoplay: false,
          animationData,
        });
        anim.current.setSpeed(1);
        setTimeout(() => {anim.current.play()}, 500);
        return () => anim.current.destroy();
    }
  }, []);

  useEffect(() => {}, [step]);

  return <div className="animationColor" ref={animationContainer} style={{width: "50%", margin: "0 auto", paddingTop: "1rem"}}></div>;
};

export default ConfirmationAnimation;
