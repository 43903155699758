export const busyReducer = (state = { isBusy: false }, action) => {
  switch (action.readyState) {
    case "request":
      return {
        ...state,
        isBusy: true,
      };
    case "success":
      return {
        ...state,
        isBusy: false,
      };
    case "failure":
      return {
        ...state,
        isBusy: false,
        isError: true,
        error: action.error.message,
      };

    default:
      return state;
  }
};
