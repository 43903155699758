import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { STATE_CULTURE, STATE_CURRENCY } from "../../common/state";
import { useNavigate, useLocation } from "react-router";
import TicketIntroduction from "./TicketIntroduction";
import { DataContext } from "../common/dataProvider";
import Calendar from "./Calendar";
import Legend from "./Legend";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import TimeChooser from "./TimeChooser";
import ArticleChooser from "./ArticleChooser";
import { endOfMonth, startOfMonth } from "date-fns";
import { da, de, enGB, sv } from "date-fns/locale";
import smoothscroll from "smoothscroll-polyfill";
import { Button, Container } from "@mantine/core";
import classes from "./Ticket.module.css"; // Import the CSS Module

const query = gql`
  query detailQuery(
    $currency: String
    $culture: String
    $posId: Int
    $from: DateTime
    $until: DateTime
  ) {
    allotments(
      currency: $currency
      culture: $culture
      posId: $posId
      from: $from
      until: $until
    ) {
      description
      occasions {
        time
        remaining
        allotment {
          id
          maxPerSale
        }
        articles {
          id
          name
          description
          plu
          priceInclVat
          isPersonalTicket
          isMultiTicket
          additionalFields {
            id
            setValueOnSale
            regExValidation
            translation {
              culture
              name
              description
            }
          }
          ticketHolders {
            categoryName
            categoryId
            maximumNumber
            defaultNumber
          }
          price {
            amountInclVat
          }
        }
      }
    }
  }
`;

const Ticket = (props) => {
  const { config, language, currency, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
    currency: state.cookies[STATE_CURRENCY],
  }));

  const { data } = useContext(DataContext);
  const { event, ticketData, shopName } = props;
  const [text, setText] = useState("");
  const [allotment, setAllotment] = useState([]);
  const [allotmentSettings, setAllotmentSettings] = useState([]);
  const [chosenDate, setChosenDate] = useState();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [filteredData, setFilteredData] = useState();
  const [firstDate, setFirstDate] = useState([]);
  const [latestDate, setLatestDate] = useState([]);
  const [activeScreen, setActiveScreen] = useState(false);
  const [chooseTime, setChooseTime] = useState();
  const [selected, setSelected] = useState(false);
  const [cultureLocale, setCultureLocale] = useState();
  const [activeOptionScreen, setActiveOptionScreen] = useState(false);
  const [from, setFrom] = useState();
  const [until, setUntil] = useState();
  const [singleOccasion, setSingleOccasion] = useState();
  const [activeTicketConfigurator, setActiveTicketConfigurator] = useState();

  const pageRefs = useRef({});
  const location = useLocation();
  const navigate = useNavigate();
  let urlParams = location.pathname.split("/").pop();
  const { posId } = config;

  const result = useQuery(query, {
    fetchPolicy: "cache-and-network",
    variables: { posId, currency, culture, from, until },
  });

  smoothscroll.polyfill();
  const locales = [
    { culture: "sv-SE", data: sv },
    { culture: "en-US", data: enGB },
    { culture: "en-GB", data: enGB },
    { culture: "de-DE", data: de },
    { culture: "da-DK", data: da },
  ];

  useEffect(() => {
    const getLocale = locales.filter((el) => el.culture === culture)[0].data;
    setCultureLocale(getLocale);
  }, []);

  useEffect(() => {
    axios
      .get(
        `${config.baseUrl}/ee-static/shop/${shopName}/Texts//${urlParams}.${culture}.md`
      )
      .then((data) => setText(data.data))
      .catch((err) => {
        console.log(err);
      });
  }, [location.pathname]);

  useEffect(() => {
    const siteSettings = config.ticketConfigurator.filter(
      (el) => el.slug.toLowerCase() === urlParams
    );

    if (siteSettings[0] !== undefined) {
      const allotmentFilter = data.allotments.filter((el) =>
        siteSettings[0].allotmentId.includes(el.id)
      );
      if (allotmentFilter.length !== 0) {
        let firstAllotmentDate = allotmentFilter;
        let lastAllotmentDate = allotmentFilter;
        const getLastDates = lastAllotmentDate
          .map((el) => el.occasions.slice(-1))
          .flat();
        const getFirstDates = firstAllotmentDate.map((el) => el.occasions[0]);
        const sortLastDates = getLastDates.sort(
          (a, b) => new Date(b.time) - new Date(a.time)
        );

        const sortFirstDates = getFirstDates.sort(
          (a, b) => new Date(a.time) - new Date(b.time)
        );
        setAllotment(allotmentFilter);
        setAllotmentSettings(siteSettings);
        setFirstDate(sortFirstDates[0]);
        setLatestDate(sortLastDates[0]);
      }
      if (allotmentFilter.length === 0) {
        setAllotment([]);
      }
    }

    // get all occasions, sort den backwards and get the latest date. If the month of the latest date, set until to the latest working month
  }, [location.pathname, result]);

  const scrollIntoView = (type) => {
    if (pageRefs.current !== null) {
      setTimeout(() => {
        pageRefs.current[type].scrollIntoView({
          behavior: "smooth",
        });
      }, 10);
    }
  };

  useEffect(() => {
    setFrom(startOfMonth(currentDate));
    setUntil(endOfMonth(currentDate));
  }, [currentDate]);

  return (
    <div>
      <Container className={classes.buttonWrapper}>
        <Button
          className={classes.button}
          variant="default"
          mb={2}
          onClick={() => navigate(-1)}
        >
          {language.Back}
        </Button>
      </Container>
      <div className={classes.introductionWrapper}>
        <TicketIntroduction
          config={config}
          shopName={shopName}
          urlParams={urlParams}
          contextData={data}
          ticketData={ticketData}
          imageData={event.images}
          altText={event.name}
          text={text}
          language={language}
        />
      </div>

      <div
        className={classes.calendarWrapper}
        ref={(el) =>
          (pageRefs.current = { ...pageRefs.current, NewCalendar: el })
        }
      >
        <h2 className="calendar-header" style={{ paddingTop: "2rem" }}>
          {allotment.length > 0
            ? language.Choose_Date
            : language.NoOccasionsAvailable}
        </h2>

        {allotment.length === 0 ||
        (allotmentSettings[0] !== undefined &&
          allotmentSettings[0].showColors === false) ? (
          ""
        ) : (
          <Legend language={language} />
        )}
        <Calendar
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          allotment={allotment}
          allotmentData={result.data}
          loading={result.loading}
          error={result.error}
          allotmentSettings={allotmentSettings}
          chosenDate={chosenDate}
          setChosenDate={setChosenDate}
          setFilteredData={setFilteredData}
          scrollIntoView={scrollIntoView}
          pageRefs={pageRefs}
          firstDate={firstDate}
          latestDate={latestDate}
          language={language}
          cultureLocale={cultureLocale}
          pathname={location.pathname}
        />
      </div>
      <div className="timeArticleWrapper" style={{ minHeight: "70vh" }}>
        {allotment.length > 0 && (
          <div
            className={`${classes.timeWrapper} ${
              chosenDate && !singleOccasion
                ? classes.largeWrapper
                : classes.smallWrapper
            }`}
            ref={(el) =>
              (pageRefs.current = { ...pageRefs.current, TimeChooser: el })
            }
            // style={{ paddingTop: singleOccasion  ? "0" : "0" }}
          >
            <div style={{ paddingTop: "1rem" }}>
              <TimeChooser
                chosenDate={chosenDate}
                setChosenDate={setChosenDate}
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                dataContext={data}
                allotmentSettings={allotmentSettings}
                activeScreen={activeScreen}
                setActiveScreen={setActiveScreen}
                scrollIntoView={scrollIntoView}
                pageRefs={pageRefs}
                chooseTime={chooseTime}
                setChooseTime={setChooseTime}
                selected={selected}
                setSelected={setSelected}
                setActiveOptionScreen={setActiveOptionScreen}
                language={language}
                cultureLocale={cultureLocale}
                pathname={location.pathname}
                singleOccasion={singleOccasion}
                setSingleOccasion={setSingleOccasion}
                activeTicketConfigurator={activeTicketConfigurator}
                setActiveTicketConfigurator={setActiveTicketConfigurator}
              />
            </div>
          </div>
        )}

        <div
          className={`${classes.chooseArticleWrapper} ${
            selected ? classes.largeWrapper : classes.smallWrapper
          }`}
          ref={(el) =>
            (pageRefs.current = { ...pageRefs.current, ArticleChooser: el })
          }
        >
          {allotment.length > 0 && (
            <div className={classes.articleWrapper}>
              <ArticleChooser
                chosenDate={chosenDate}
                allotmentSettings={allotmentSettings}
                scrollIntoView={scrollIntoView}
                allotmentData={result.data}
                selected={selected}
                setSelected={setSelected}
                setChooseTime={setChooseTime}
                setActiveOptionScreen={setActiveOptionScreen}
                activeOptionScreen={activeOptionScreen}
                cultureLocale={cultureLocale}
                singleOccasion={singleOccasion}
                activeTicketConfigurator={activeTicketConfigurator}
                setChosenDate={setChosenDate}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Ticket;
