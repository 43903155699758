import { showNotification } from "@mantine/notifications";
import { IoMdWarning, IoMdInformationCircle } from "react-icons/io";
import { MdOutlineError } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import React from "react";

const getColorTheme = (theme, variant) => {
  switch (variant) {
    case "success":
      return theme.colors.green[6];
    case "warning":
      return theme.colors.yellow[6];
    case "error":
      return theme.colors.red[6];
    case "info":
      return theme.colors.blue[6];
    default:
      return theme.colors.blue[6];
  }
};

const getHoverTheme = (theme, variant) => {
  switch (variant) {
    case "success":
      return theme.colors.green[7];
    case "warning":
      return theme.colors.yellow[7];
    case "error":
      return theme.colors.red[7];
    case "info":
      return theme.colors.blue[7];
    default:
      return theme.colors.blue[7];
  }
};

const variantIcon = {
  success: FaCheckCircle,
  warning: IoMdWarning,
  error: MdOutlineError,
  info: IoMdInformationCircle,
};

export const notification = (variant, message) => {
  const Icon = variantIcon[variant];

  return showNotification({
    title: message,
    autoClose: 5000,
    icon: <Icon style={{ width: "100%", height: "100%" }} />,
    styles: (theme) => {
      const themeColor = getColorTheme(theme, variant);
      const themeHover = getHoverTheme(theme, variant);
      return {
        root: {
          backgroundColor: themeColor,
          borderColor: themeColor,

          "&::before": { backgroundColor: theme.white },
        },

        title: { color: theme.white },
        description: { color: theme.white },
        closeButton: {
          color: theme.white,
          "&:hover": { backgroundColor: themeHover },
        },
      };
    },
  });
};
