import { Alert } from "@mantine/core";

import React, { useEffect, useState } from "react";
import AlertIcon from "./AlertIcon";
import axios from "axios";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../common/state";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import classes from "./DrawerAlert.module.css";

const DrawerAlert = () => {
  const [alertMsg, alertMsgSet] = useState(null);
  const { language, config, culture } = useSelector((state) => ({
    language: state.language,
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
  }));

  useEffect(() => {
    axios
      .get(
        `${config.baseUrl}/ee-static/shop/${config.shopName}/Texts/cart-below.${culture}.md`
      )
      .then((data) => alertMsgSet(data.data))
      .catch((error) => console.log(error));
  }, []);
  return (
    alertMsg && (
      <div className={`${classes.alert} cart-alert`}>
        <Alert
          icon={<AlertIcon size={16} />}
          title={language.CartDrawerAlertTitle}
          color="red.9"
          radius="md"
          className={classes.alertIconWrapper}
          role="complementary"
        >
          <ReactMarkdown className="drawerMsg" rehypePlugins={[rehypeRaw]}>
            {alertMsg}
          </ReactMarkdown>
        </Alert>
      </div>
    )
  );
};

export default DrawerAlert;
