import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import gql from "graphql-tag";
import { STATE_CULTURE, STATE_CURRENCY } from "../../common/state";
import { getInitialFromUntil } from "../../common/util";
import Busy from "./busy";

export const DataContext = React.createContext();
const { from, until } = getInitialFromUntil();

const ArticleQuery = gql`
  query ArticleQuery(
    $currency: String
    $culture: String
    $posId: Int
    $from: DateTime
    $until: DateTime
  ) {
    tags(currency: $currency, culture: $culture, posId: $posId) {
      name
      articles {
        imageFilename
        id
        plu
        name
        inStock
        priceInclVat
        price {
          amountInclVat
        }
        translation {
          culture
          name
          description
        }
        isPersonalTicket
        isProduct
        isGiftCertificate
        isMultiTicket
        overrideStartDate
        ticketHolders {
          categoryName
          categoryId
          maximumNumber
          defaultNumber
        }
        accessConditions {
          start
          stop
          dayOfWeeks
        }
      }
    }
    allotments(
      currency: $currency
      culture: $culture
      posId: $posId
      from: $from
      until: $until
    ) {
      id
      description
      imageFileName
      translation {
        culture
        name
        description
      }
      occasions {
        time
        remaining
      }
      articles {
        id
        plu
        name
        priceInclVat
        imageFilename
        isMultiTicket
        ticketHolders {
          categoryName
          categoryId
          maximumNumber
          defaultNumber
        }
        price {
          amountInclVat
        }
        translation {
          culture
          name
          description
        }
        additionalFields {
          id
          setValueOnSale
          regExValidation
          translation {
            culture
            name
            description
          }
        }
      }
    }
    articles(culture: $culture, posId: $posId) {
      imageFilename
      id
      plu
      inStock
      name
      priceInclVat
      price {
        amountInclVat
      }
      translation {
        culture
        name
        description
      }
      isPersonalTicket
      isProduct
      isMultiTicket
      isGiftCertificate
      ticketHolders {
        categoryName
        categoryId
        maximumNumber
        defaultNumber
      }
      additionalFields {
        id
        setValueOnSale
        regExValidation
        translation {
          culture
          name
          description
        }
      }
    }
    cultures {
      name
      description
    }
    currencies {
      name
    }
  }
`;

const DataProvider = ({ children }) => {
  const setEventFilterLogger = (data) => {
    setEventFilter(data);
    console.log("--> Set event date filter to:", data);
  };

  const [eventFilter, setEventFilter] = useState();
  const [translated, setTranslated] = useState(false);
  const { config, culture, currency } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
    currency: state.cookies[STATE_CURRENCY],
  }));
  const { data, loading, refetch } = useQuery(ArticleQuery, {
    variables: {
      posId: config.posId,
      currency,
      culture,
      from,
      until,
    },
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    if (!loading) {
      //Set default value to empty. Will be overridden if navigating months in all-events.
      setEventFilterLogger([]);
    }
  }, [data, loading]);

  if (loading) return <Busy busy={loading} busyText="Laddar webshop..." />;
  return (
    <DataContext.Provider
      value={{
        data,
        refetch,
        eventFilter,
        setEventFilter: setEventFilterLogger,
        translated,
        setTranslated,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
