import React, { useContext, useState } from "react";
import clsx from "clsx";
import { UnstyledButton } from "@mantine/core";
import { useSelector } from "react-redux";
import { SaleContext } from "../common/saleProvider";
import CountdownContent from "./CountdownContent";
import { Context, OPEN_CART } from "../../context";
import classes from "./CountdownContainer.module.css";
import TimerWarning from "./TimerWarning";

const CountdownContainer = ({ changeBackground, parent }) => {
  const { dispatch } = useContext(Context);
  const {
    state: { sale },
  } = useContext(SaleContext);

  const { language } = useSelector((state) => ({
    language: state.language,
  }));
  const [isOpenModal, setIsOpenModal] = useState(false);

  const CountdownWrapper = (props) => {
    if (parent === "Header")
      return (
        <UnstyledButton
          className={`${clsx(classes.countDownText)} countDownText`}
          onClick={() => dispatch({ type: OPEN_CART })}
          disabled={sale.itemQuantity < 1}
          classes={{ disabled: classes.disabled }}
          aria-label={language.OpenCart}
        >
          {props.children}
        </UnstyledButton>
      );
    else
      return (
        <div className={`${clsx(classes.countDownText)} countDownText`}>
          {props.children}
        </div>
      );
  };

  const isAllotment = sale?.items.some((el) => el.allotmentId);

  if (!isAllotment) return null;

  if (isOpenModal) {
    return (
      <TimerWarning
        isOpenModal={isOpenModal}
        language={language}
        classes={classes}
      />
    );
  }

  return (
    <div className={parent === "Header" ? classes.paddingLeft : undefined}>
      <CountdownWrapper>
        <CountdownContent
          sale={sale}
          language={language}
          openModal={setIsOpenModal}
          changeBackground={changeBackground}
        />
      </CountdownWrapper>
    </div>
  );
};

export default CountdownContainer;
