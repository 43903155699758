import {
  ActionIcon,
  Button,
  Container,
  Divider,
  Grid,
  Group,
  Image,
  List,
  NumberInput,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { addArticle, addToCartTracking } from "../../../common/sale";
import { STATE_CULTURE, STATE_CURRENCY } from "../../../common/state";
import { getSaleData } from "../util";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import ProductSelector from "./ProductSelector";
import slugify from "../../common/slugify";
import axios from "axios";
import { motion } from "framer-motion";
import "./styles.css";
import { SaleContext } from "../../common/saleProvider";
import { HiMinusSm, HiPlusSm } from "react-icons/hi";
import classes from "./GroupProduct.module.css";
import { useLocation } from "react-router";

const GroupProduct = ({ product, productDescription }) => {
  const { config, culture, language, currency } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
    currency: state.cookies[STATE_CURRENCY],
  }));
  const [quantity, setQuantity] = useState(1);
  const [addedToCart, setAddedToCart] = useState(null);
  const [selected, setSelected] = useState(null);
  const [text, setText] = useState("");
  const handlers = useRef();
  const quantityChangeRef = useRef();
  const rdxDispatch = useDispatch();
  const saleContext = useContext(SaleContext);
  const { sale } = saleContext.state;
  const location = useLocation();

  const handleAdd = () => {
    const productData = [
      {
        id: selected?.[0]?.plu,
        name: selected?.[0]?.name,
        price: selected?.[0]?.price?.amountInclVat,
        quantity: quantity,
      },
    ];
    addToCartTracking(currency, productData);
    announceQuantityChange(quantity);
    const callback = (status, msg) => {
      if (status === 200) {
        setQuantity(1);
        setAddedToCart(true);
        setTimeout(() => {
          setAddedToCart(null);
        }, 5000);
      }
      if (status !== 200) {
        setQuantity(1);
        setAddedToCart(false);
        setTimeout(() => {
          setAddedToCart(null);
        }, 5000);
      }
    };
    let article;
    let values;
    let photo;
    let overrideDate;
    const saleData = product.articles
      ? getSaleData(selected[0], article, values, photo, quantity, overrideDate)
      : {
          plu: selected.plu,
          quantity: quantity,
          priceInclVat: selected.priceInclVat * 100,
        };
    rdxDispatch(addArticle(saleData, callback));
  };

  useEffect(() => {
    setSelected(null);
    setQuantity(1);
  }, [location]);

  useEffect(() => {
    axios
      .get(
        `${config.baseUrl}/ee-static/shop/${config?.shopName}/Texts/${slugify(
          product.name
        )}.${culture}.md`
      )
      .then((res) => setText(res.data))
      .catch((err) => console.log(err));
  }, [product]);

  const uri = config?.apiUriOverride?.startsWith("http")
    ? config.apiUriOverride
    : config.apiUri;
  const img = `${uri}/articleimage/${selected?.[0]?.imageFilename}`;
  const regex = /\D/g;
  const reduce =
    product.articles &&
    product.articles.reduce((acc, curr) => {
      acc.push(curr.priceInclVat);
      return acc;
    }, []);

  const reduceChooseValue =
    product.openPriceValues &&
    product.openPriceValues.reduce((acc, curr) => {
      acc.push(curr);
      return acc;
    }, []);

  const sortPrice = (prices) => {
    if (prices && typeof prices?.[0] === typeof 0) {
      const sortOpenPrice = prices.sort((a, b) => a - b);
      return sortOpenPrice;
    } else if (prices && typeof prices?.[0] === typeof "") {
      const sortStringValue = prices.sort(
        (a, b) => a.replace(regex, "") - b.replace(regex, "")
      );
      return sortStringValue;
    }
  };

  const name = useMemo(() => {
    const getTranslation = product?.translation?.filter(
      (el) => el?.culture === culture
    )?.[0];
    const translation = selected?.[0]?.translation?.find(
      (lang) => lang.culture === culture
    );

    if (translation) {
      return translation?.name;
    } else if (selected?.name) {
      return selected?.name;
    } else if (getTranslation) {
      return getTranslation?.name;
    } else if (product?.name) {
      return product?.name;
    }
  }, [product, selected, culture]);

  const description = useMemo(() => {
    const getTranslation = product?.translation?.filter(
      (el) => el?.culture === culture
    )?.[0];
    const translation = selected?.[0]?.translation?.find(
      (lang) => lang.culture === culture
    );
    if (translation) {
      return translation?.description;
    } else if (getTranslation?.description) {
      return getTranslation?.description;
    } else if (productDescription) {
      return productDescription;
    } else {
      return text;
    }
  }, [description, text, product, selected, culture]);

  const price = useCallback(() => {
    if (product?.priceInclVat > 0) {
      return product?.priceInclVat;
    }

    if (product?.openPriceValues && !reduceChooseValue) {
      return `${language.FromCost} ${product?.openPriceValues?.[0]} kr`;
    }

    if (selected?.[0]?.priceInclVat) {
      return selected?.[0].priceInclVat;
    }

    if (reduce) {
      return `${sortPrice(reduce)?.[0]}–${
        sortPrice(reduce)?.[sortPrice(reduce).length - 1]
      }`;
    }

    if (reduceChooseValue) {
      return `${sortPrice(reduceChooseValue)?.[0]} kr–${
        sortPrice(reduceChooseValue)?.[sortPrice(reduceChooseValue).length - 1]
      } kr`;
    }
  }, [product, selected, reduceChooseValue, reduce]);

  const disabledIncrement = useMemo(() => {
    if (sale) {
      const qty = sale.items.filter(
        (el) => el.article.plu === selected?.[0]?.plu
      )?.[0]?.quantity;
      const calc = selected?.[0]?.inStock - (qty ? qty : 0);
      if (quantity >= calc) {
        return true;
      }
    }
  }, [sale, selected, quantity]);

  const disabledAdd = useMemo(() => {
    if (sale) {
      const qty = sale.items.filter(
        (el) => el.article.plu === selected?.[0]?.plu
      )?.[0]?.quantity;
      const calc = selected?.[0]?.inStock - (qty ? qty : 0);
      if (quantity > calc) {
        return true;
      }
    }
  }, [sale, selected, quantity]);

  const isAddedToCart = sale?.items.filter(
    (el) => el?.article?.plu === selected?.[0]?.plu
  );

  const announceQuantityChange = (newQuantity) => {
    quantityChangeRef.current.innerText = `${language.Quantity} ${newQuantity}`;
  };

  const increment = () => {
    handlers.current.increment();
    announceQuantityChange(quantity + 1);
  };

  const decrement = () => {
    handlers.current.decrement();
    announceQuantityChange(quantity - 1);
  };

  if (!product) return null;
  return (
    <Container className={classes.containerWrapper}>
      <Grid gutter={20}>
        <Grid.Col span={{ sm: 12, md: 7 }}>
          <motion.div
            className={classes.imageWrapper}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Image
              src={`${
                selected?.[0]?.imageFilename ? img : product?.images?.[0]
              }`}
              radius={"sm"}
              className={classes.image}
              fallbackSrc={product?.images?.[0]}
              // h={
              //   mobile && (selected?.[0]?.imageFilename || product.images[0])
              //     ? "20rem"
              //     : mobile
              //     ? "20rem"
              //     : "25rem"
              // }
              h={"auto"}
              fit="cover"
              alt={`${language.ProductAltLabel} ${name}`}
            />
          </motion.div>
        </Grid.Col>
        <Grid.Col span={{ sm: 12, md: 5 }} className={classes.container}>
          <div className={classes.titleWrapper}>
            <h1 className="productTitle" style={{ fontSize: "1.5rem" }}>
              {name}
            </h1>
          </div>
          <Grid cols={2} className={`${classes.stockWrapper} stockWrapper`}>
            <Grid.Col span={5}>
              {selected && selected?.[0]?.isProduct ? (
                <List
                  size="sm"
                  center
                  className={`${classes.list} stockIndicatorGroup`}
                >
                  <List.Item
                    style={{ display: "flex", justifySelf: "end" }}
                    icon={
                      <ThemeIcon
                        color={
                          selected?.[0].inStock > 0
                            ? "var(--button-add-to-cart-success-bg-color)"
                            : "#FA5252"
                        }
                        size={19}
                        radius="xl"
                      >
                        <div />
                      </ThemeIcon>
                    }
                  >
                    <p
                      style={{ paddingTop: "0.1rem", margin: "0 0 0 -0.2rem" }}
                    >
                      {selected?.[0].inStock > 0
                        ? language.In_Stock
                        : language.Not_In_Stock}
                    </p>
                  </List.Item>
                </List>
              ) : (
                <div />
              )}
            </Grid.Col>

            <Grid.Col
              span={7}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <span
                className={`${classes.price} product-price`}
                component="span"
              >
                {price()}
              </span>
            </Grid.Col>
          </Grid>

          <ProductSelector product={product} setSelected={setSelected} />

          <div className={classes.actionGrid}>
            <div
              className={classes.hideLabel}
              role="region"
              aria-live="assertive"
              ref={quantityChangeRef}
            />
            <Group
              gap={5}
              styles={{ height: "42px" }}
              className={classes.actionWrapper}
            >
              <ActionIcon
                className={`${classes.actionButton} addToCart`}
                size={35}
                color="gray"
                radius="50"
                variant="filled"
                onClick={() => decrement()}
                disabled={
                  (!selected && true) ||
                  quantity === 1 ||
                  product.isGiftCertificate ||
                  product.name.includes("Presentkort")
                }
                aria-label={language.DecreaseQuantity}
              >
                <div style={{ fontSize: "1.5rem", height: "24px" }}>
                  <HiMinusSm />
                </div>
              </ActionIcon>
              <NumberInput
                hideControls
                value={quantity}
                aria-label={`${language.Quantity} ${quantity}`}
                onChange={(val) => setQuantity(val)}
                disabled={
                  (!selected && true) ||
                  product.isGiftCertificate ||
                  product.name.includes("Presentkort")
                }
                handlersRef={handlers}
                max={selected?.[0]?.inStock ? selected?.[0].inStock : 99999}
                min={1}
                step={1}
                styles={{
                  input: {
                    backgroundColor: "transparent",
                    border: "none",
                    width: 35,
                    textAlign: "center",
                    height: 35,
                    fontWeight: "600",
                    fontSize: "1.25rem",
                    padding: 0,
                  },
                }}
              />
              <ActionIcon
                aria-label={language.AddQuantity}
                className={`${classes.actionButton} addToCart`}
                size={35}
                color="gray"
                radius="50"
                disabled={
                  (disabledIncrement
                    ? disabledIncrement
                    : selected?.[0]?.inStock
                    ? selected?.[0]?.inStock === quantity
                    : !selected && true) ||
                  product.isGiftCertificate ||
                  product.name.includes("Presentkort")
                }
                variant="filled"
                onClick={() => increment()}
              >
                <div style={{ fontSize: "1.5rem", height: "24px" }}>
                  <HiPlusSm />
                </div>
              </ActionIcon>
            </Group>
            <div
              className={classes.alertWrapper}
              role="region"
              aria-live="assertive"
            >
              <Button
                variant="filled"
                size="md"
                className="regularButton add_to_cart_product"
                classNames={{
                  root: addedToCart && classes.addedToCart,
                  label: "add_to_cart_product",
                  inner: "add_to_cart_product",
                }}
                onClick={handleAdd}
                disabled={quantity === 0 || !selected || disabledAdd}
                style={{
                  width: "100%",
                  transition: "all 0.2s ease-in-out",
                }}
              >
                {disabledAdd && isAddedToCart?.length > 0
                  ? language.MaxInCart
                  : disabledAdd && language.Not_In_Stock}
                {!disabledAdd && addedToCart
                  ? language.Added_To_Cart
                  : !disabledAdd && language.AddToCartBtn}
              </Button>
            </div>
          </div>

          <Divider my="lg" />
          <h2 className={`${classes.descriptionTitle} descriptionTitle`}>
            {language.Description}
          </h2>

          <div className={`${classes.descriptionBody} productDescription`}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {description}
            </ReactMarkdown>
          </div>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default GroupProduct;
