import React from "react";
import LoadingAnimation from "./animations/LoadingAnimation";
import classes from "./AddToCartButton.module.css";

const AddToCartButton = (props) => {
  return (
    <>
      {props.loading === false ? (
        <span>{props.text}</span>
      ) : (
        <LoadingAnimation />
      )}
    </>
  );
};

export default AddToCartButton;
