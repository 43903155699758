import { Button, Divider } from "@mantine/core";
import classes from "./Options.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useContext, useMemo } from "react";
import { SaleContext } from "../common/saleProvider";
import { isItReal, pixelCheckoutData } from "../../common/util";
import { STATE_CURRENCY } from "../../common/state";
import { goToCheckoutTracking } from "../common/tracking";
import { checkout } from "../../common/sale";
import Cookies from "universal-cookie";

const OptionsCartFooter = ({ active, setActive, maxStep }) => {
  const { config, language, currency } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    currency: state.cookies[STATE_CURRENCY],
  }));
  const saleContext = useContext(SaleContext);
  const { sale } = saleContext.state;
  const rdxDispatch = useDispatch();
  const cookies = useMemo(() => new Cookies(), []);
  const consent = cookies.get("shop-consent");

  const shippingCost = useCallback(() => {
    if (sale && sale?.items) {
      const shipping = sale.items.filter((el) => el.type === "shipping_fee");
      if (shipping.length > 0) {
        return (
          <div className={classes.shippingWrapper}>
            <span>{language.ShippingCost}</span>
            <span>{shipping?.[0]?.totalPriceInclVat}</span>
          </div>
        );
      }
    }
  }, [sale]);

  const serviceFee = useCallback(() => {
    if (sale && sale?.items) {
      const serviceFee = sale.items.filter((el) => el.type === "service_fee");
      if (serviceFee.length > 0) {
        return (
          <div className={classes.shippingWrapper}>
            <span>{language.ServiceFee}</span>
            <span>{serviceFee?.[0]?.totalPriceInclVat}</span>
          </div>
        );
      }
    }
  }, [sale]);

  const handlePrevious = () => {
    setActive(active - 1);
  };

  const handleNext = () => {
    setActive(active + 1);
    if (active === maxStep - 1) {
      goToCheckout();
    }
  };

  const goToCheckout = () => {
    if (sale) {
      if (config.facebookPixelId && isItReal("pixel", config.facebookPixelId)) {
        pixelCheckoutData(sale.items, currency);
      }
    }
    goToCheckoutTracking(sale);
    localStorage.setItem("hasBegunCheckout", sale.id);
    return rdxDispatch(checkout(consent));
  };

  return (
    <div className={""}>
      <Divider size="sm" className={classes.divider} />
      <div className={classes.textWrapper}>
        <span className={classes.total}>{language.ToPay}</span>
        <span className={classes.total}>{sale?.sumInclVat}</span>
      </div>
      <div className={classes.feeWrapper}>
        {shippingCost()}
        {serviceFee()}
      </div>
      <div className={classes.actionWrapper}>
        {maxStep > 1 && (
          <>
            <Button
              variant="light"
              fullWidth
              onClick={handlePrevious}
              disabled={active === 0}
            >
              {language.PreviousStep}
            </Button>
            <div>
              <Button className="primaryButton" fullWidth onClick={handleNext}>
                {active === maxStep - 1
                  ? language.GoToCheckout
                  : language.NextStep}
              </Button>
              <Divider
                mt="1rem"
                label={language.ContinueWith}
                labelPosition="center"
              />
            </div>
          </>
        )}

        <Button className="secondaryButton" fullWidth onClick={goToCheckout}>
          {language.GoToCheckout}
        </Button>
      </div>
    </div>
  );
};

export default OptionsCartFooter;
