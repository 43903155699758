import { Button, Modal, TextInput } from "@mantine/core";
import { useSelector } from "react-redux";
import { useCallback, useMemo, useState } from "react";
import classes from "./OptionsModal.module.css";
import { STATE_CULTURE } from "../../common/state";
import { validateOptions } from "./util";
import OptionsCalendar from "./OptionsCalendar";

const OptionsModal = ({
  title,
  description,
  opened,
  close,
  additionalFields,
  handleAdd,
  extraInformation,
  setExtraInformation,
  allotmentId,
  availableOccasions,
  day,
  setDay,
}) => {
  const [language, culture] = useSelector((state) => [
    state.language,
    state.cookies[STATE_CULTURE],
  ]);
  const [hasErrors, setHasErrors] = useState(false);

  const translation = useCallback(
    (type, translation) => {
      if (type === "name") {
        return translation.find((item) => item.culture === culture)?.name;
      }
      if (type === "description") {
        return translation.find((item) => item.culture === culture)
          ?.description;
      }
    },
    [culture, additionalFields]
  );

  const handleOnChange = (e, item) => {
    const validate = validateOptions(
      translation("name", item?.translation),
      e.target.value.toUpperCase(),
      item?.regExValidation
    );
    setExtraInformation({
      ...extraInformation,
      [item.id]: e.target.value.toUpperCase(),
    });
    if (validate) {
      setHasErrors(true);
    } else {
      setHasErrors(false);
    }
  };
  const disabled = useMemo(() => {
    if (allotmentId && !day) return true;
    if (additionalFields) {
      for (let i = 0; i < additionalFields.length; i++) {
        if (!extraInformation?.[additionalFields[i].id]) return true;
      }
    }
    return hasErrors;
  }, [allotmentId, day, hasErrors, extraInformation, additionalFields]);

  const add = () => {
    setExtraInformation(null);
    handleAdd();
    close();
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={title ? title : language.AdditionalFields}
      centered
    >
      <div className={classes.wrapper}>
        {description && <p>{description}</p>}
        {allotmentId && (
          <OptionsCalendar
            availableOccasions={availableOccasions}
            day={day}
            setDay={setDay}
            isModal={true}
          />
        )}
        {additionalFields.map((item, i) => {
          if (!item.setValueOnSale) return null;
          return (
            <TextInput
              key={i}
              label={translation("name", item.translation)}
              description={translation("description", item.translation)}
              placeholder={translation("name", item.translation)}
              value={extraInformation ? extraInformation[item.id] : ""}
              onChange={(e) => handleOnChange(e, item)}
              error={validateOptions(
                translation("name", item?.translation),
                extraInformation?.[item.id],
                item?.regExValidation,
                language
              )}
              required
            />
          );
        })}
        <div className={classes.modalFooter}>
          <Button
            variant="outline"
            className="primaryButtonOutline"
            onClick={close}
          >
            {language.Close}
          </Button>
          <Button className="primaryButton" onClick={add} disabled={disabled}>
            {language.AddToCartBtn}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default OptionsModal;
