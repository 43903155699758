import React from "react";
import { Card, Image, Text, UnstyledButton } from "@mantine/core";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import slugify from "../common/slugify";
import { v4 as uuid } from "uuid";
import { useSessionStorage } from "@mantine/hooks";
import classes from "./CardV2.module.css";
import placeholder from "../../images/placeholder.jpg";

const CardV2 = ({ item, slug }) => {
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  const [selected, setSelected] = useSessionStorage({
    key: "activeLink",
    defaultValue: "",
  });
  const navigate = useNavigate();

  const link = () => {
    setSelected(item?.text?.primary.toLowerCase());
    const filter = config.landingPage.links.filter(
      (el) => el.name === item.name
    )?.[0];
    if (slug) {
      return slug;
    }
    if (item?.eventCalendar) {
      return `/tickets/${slugify(item.name)}`;
    }
    if (item?.articleBooking) {
      return `/booking/${slugify(item.name)}`;
    }
    if (item?.externalLink) {
      return item?.externalLink;
    }
    if (item?.internalLink) {
      return item?.internalLink;
    }
    if (item?.publicBooking) {
      return `/publicbooking/${slugify(item.name)}`;
    } else {
      if (filter) {
        return `/${slugify(filter.name)}`;
      }
    }
  };
  return (
    <UnstyledButton
      component={"a"}
      className={`${classes.cardWrapper} landing-listButton`}
      onClick={() => !item.externalLink && navigate(link())}
      onKeyDown={(e) =>
        e.key === "Enter" && !item.externalLink && navigate(link())
      }
      href={item?.externalLink && item?.externalLink}
      target={item?.externalLink && "_blank"}
      key={uuid()}
      aria-label={`${item?.text?.primary}, ${item?.text?.secondary}`}
      tabIndex={0}
    >
      <Card
        className={classes.card}
        radius="md"
        key={item?.text?.primary}
        p={0}
      >
        <Card.Section>
          <Image
            src={item.image}
            h={"20rem"}
            fallbackSrc={placeholder}
            radius="md"
            alt={`${language.PhotoOf} ${item?.text?.primary}`}
          />
        </Card.Section>

        <Text
          className={`${classes.title} landing-cardPrimaryText`}
          fw={500}
          component="p"
          mt={"xs"}
        >
          {item?.text?.primary}
        </Text>

        <Text
          className={`${classes.secondaryText} landing-cardSecondaryText`}
          size="sm"
          lineClamp={4}
          component="p"
          mt={"0"}
          fw={"600"}
          mb={0}
          c={"gray.7"}
        >
          {item?.text?.secondary} &nbsp;
        </Text>
      </Card>
    </UnstyledButton>
  );
};
export default CardV2;
