import React, { useEffect, useState } from "react";
import OccasionButton from "./OccasionButton";
import PreviousPageBtn from "./PreviousPageBtn";
import MoveOccasaionCalendar from "./Calendar";
import "./styles.css";
import ConfirmationAnimation from "./ConfirmationAnimation";
import { motion } from "framer-motion";
import { format, isBefore } from "date-fns";
import axios from "axios";
import SubmitButton from "./SubmitButton";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { Button, PinInput } from "@mantine/core";
import NewOccasionPicker from "./NewOccasionPicker";

const Content = ({
  step,
  setStep,
  ticketData,
  currentDate,
  setCurrentDate,
  filteredTicketData,
  chosenOldOccasion,
  setChosenOldOccasion,
  occasion,
  config,
  language,
  culture,
  posId,
  token,
  errorMsg,
  setErrorMsg,
  introductionText,
}) => {
  const [selectedDate, setSelectedDate] = useState();
  const [content, setContent] = useState();
  const [newOccasions, setNewOccasions] = useState([]);
  const [returnClick, setReturnClick] = useState(false);
  const [pinValue, setPinValue] = useState("");
  const [changeView, setChangeView] = useState(false);
  const [chosenNewOccasion, setChosenNewOccasion] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (occasion !== undefined && selectedDate !== undefined) {
      let date = new Date(selectedDate);
      let filterOccasions = occasion[0].occasions.filter(
        (el) => el.time.split("T")[0].toString() === format(date, "yyyy-MM-dd")
      );
      let removeBeforeNow = filterOccasions.filter(
        (el) => !isBefore(new Date(el.time), new Date())
      );
      setNewOccasions(removeBeforeNow);
      setChosenNewOccasion();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (ticketData !== undefined && ticketData.length === 0) {
      setErrorMsg(language.ErrorMsgMissingToken);
    }
  }, [ticketData]);

  useEffect(() => {
    setChangeView(false);
    if (step === 0) {
      setContent(introduction);
      setChangeView(true);
    }
    if (step === 1) {
      setContent(chooseOldOccasion);
      setChangeView(true);
    }
    if (step === 2) {
      setContent(chooseNewDate);
    }
    if (step === 3) {
      setErrorMsg("");
      setContent(chooseNewTime);
    }
    if (step === 4) {
      setErrorMsg("");
      setContent(confirmNewOccasion);
      setPinValue("");
    }
    if (step === 5) {
      setErrorMsg("");
      setContent(receiptScreen);
    }
  }, [step]);

  const introduction = {
    text: introductionText,
    btnTextPrimary: language.GoToBookingBtn,
    clickToSection: 1,
  };

  const chooseOldOccasion = {
    header: language.ChooseOldOccasionHeader,
    btnText: language.SecondaryBtn,
    btnTextPrimary: language.PrimaryBtn,
    progress: 0,
    clickToSection: 2,
    chooseData: ticketData,
  };

  const chooseNewDate = {
    header: language.ChooseNewOccasionHeader,
    btnText: language.SecondaryBtn,
    btnTextPrimary: language.PrimaryBtn,
    clickToSection: 3,
    chooseData: "",
  };

  const chooseNewTime = {
    header: language.ChooseNewOccasionTimeHeader,
    btnText: language.SecondaryBtn,
    btnTextPrimary: language.PrimaryBtn,
    clickToSection: 4,
    chooseData: newOccasions,
  };

  const confirmNewOccasion = {
    header: language.ConfirmNewOccasionHeader,
    btnText: language.SecondaryBtn,
    btnTextPrimary: language.SubmitBtn,
    clickToSection: "submit",
    confirmOldOccasion: chosenOldOccasion,
    confirmNewOccasion: chosenNewOccasion,
  };

  const receiptScreen = {
    header: language.ConfirmationHeader,
    btnTextPrimary: language.RedirectBtn,
    clickToSection: "redirect",
    redirect: config?.logoLink,
    confirmationOldData: chosenOldOccasion,
    confirmationNewData: chosenNewOccasion,
  };

  const handlePost = (e) => {
    e.preventDefault();
    setErrorMsg("");

    if (
      posId &&
      pinValue.length === 4 &&
      chosenOldOccasion &&
      chosenNewOccasion
    ) {
      setLoading(true);
      const uri = config?.apiUriOverride?.startsWith("http")
        ? config.apiUriOverride
        : config.apiUri;

      axios
        .post(`${uri}/api/managesale/${token}/allotments`, {
          posId: posId,
          pin: pinValue,
          allotmentId: chosenOldOccasion.allotmentId,
          fromOccasion: chosenOldOccasion.occasion,
          toOccasion: chosenNewOccasion.time,
        })
        .then(function (response) {
          setLoading(false);
          setStep(5);
          setErrorMsg("");
        })
        .catch(function (error) {
          setLoading(false);
          if (error.response.data === 1) {
            setErrorMsg(language.ErrorMsgIllegalPin);
          }
          if (error.response.data === 2) {
            setErrorMsg(language.ErrorMsgNoOccasionsToMoveFrom);
          }
          if (error.response.data === 3) {
            setErrorMsg(language.ErrorMsgTooCloseToOccasion);
          }
          if (error.response.data === 4) {
            setErrorMsg(language.ErrorMsgInvalidParameter);
          }
          if (error.response.data === 5) {
            setErrorMsg(language.ErrorMsgApiError);
          }
        });
    }
  };

  const disabled = () => {
    if (step === 0 && errorMsg.length === 0) {
      return false;
    }
    if (step === 1 && chosenOldOccasion !== undefined) {
      return false;
    }

    if (step === 2 && selectedDate !== undefined) {
      return false;
    }
    if (step === 3 && chosenNewOccasion !== undefined) {
      return false;
    }
    if (step === 4 && pinValue.length === 4) {
      return false;
    }

    if (step === 5) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <motion.div
      className="content"
      key={step}
      initial="hidden"
      animate="visible"
      variants={{
        hidden: {
          opacity: 0,
          transition: {
            duration: 0.65,
          },
        },
        visible: {
          opacity: 1,
          transition: {
            duration: 0.65,
          },
        },
      }}
      style={{ overflow: "visible" }}
    >
      {step !== 0 && step !== 5 ? (
        <PreviousPageBtn
          step={step}
          setStep={setStep}
          returnClick={returnClick}
          setReturnClick={setReturnClick}
          language={language}
        />
      ) : (
        ""
      )}

      <h2>{content?.header}</h2>
      {step === 0 ? (
        <div style={{ marginTop: "-2.5rem" }}>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {introductionText}
          </ReactMarkdown>
        </div>
      ) : (
        ""
      )}

      {step !== 3
        ? content?.chooseData &&
          Object.values(content?.chooseData).map((el, i) => (
            <Button
              variant={chosenOldOccasion === el ? "filled" : "outline"}
              className={`${step !== 3 ? "oldOccasion" : "newOccasionTime"} ${
                chosenOldOccasion === el
                  ? "primaryButton"
                  : "primaryButtonOutline"
              } ${el.canBeMoved === false ? "notAvailable" : ""}`}
              onClick={() => setChosenOldOccasion(el)}
              key={el + i}
              size="lg"
              disabled={el.canBeMoved === false ? true : false}
            >
              {step === 1 && (
                <span>
                  {format(new Date(el.occasion), "yyyy-MM-dd HH:mm")}&nbsp;
                </span>
              )}
              <span>
                {el.numberOfTickets === 1
                  ? el.numberOfTickets + " " + language.Ticket.toLowerCase()
                  : el.numberOfTickets + " " + language.Tickets.toLowerCase()}
              </span>
            </Button>
          ))
        : ""}

      {step === 3 && content?.chooseData && chosenOldOccasion && (
        <div className="occasionWrapper">
          {Object.values(content?.chooseData).map((el, i) => {
            return (
              <NewOccasionPicker
                el={el}
                index={i}
                language={language}
                chosenOldOccasion={chosenOldOccasion}
                chosenNewOccasion={chosenNewOccasion}
                setChosenNewOccasion={setChosenNewOccasion}
                step={step}
              />
            );
          })}
        </div>
      )}

      {content?.confirmOldOccasion && content?.confirmNewOccasion && (
        <>
          {step === 4 && (
            <>
              <h3>
                {chosenOldOccasion.numberOfTickets === 1
                  ? language.OldOccasionAllotment
                  : language.OldOccasionAllotments}
              </h3>
              <Button
                variant="outline"
                component="div"
                className="primaryButtonOutline"
                size="lg"
                style={{
                  pointerEvents: "none",
                  width: "100%",
                  marginBottom: "1rem",
                }}
              >
                <span>
                  {Object.values(
                    content?.confirmOldOccasion.occasion
                      .replace("T", " ")
                      .slice(0, -3)
                  )}
                  &nbsp;
                </span>
                <span>
                  {content?.confirmOldOccasion.numberOfTickets > 1
                    ? content?.confirmOldOccasion.numberOfTickets +
                      " " +
                      language.Tickets.toLowerCase()
                    : content?.confirmOldOccasion.numberOfTickets +
                      " " +
                      language.Ticket.toLowerCase()}
                </span>
              </Button>
              <h3>
                {content?.confirmOldOccasion.numberOfTickets > 1
                  ? language.NewOccasionAllotments
                  : language.NewTicket}
              </h3>
              <Button
                component="span"
                className="primaryButton"
                style={{
                  pointerEvents: "none",
                  width: "100%",
                  marginBottom: "1rem",
                }}
                size="lg"
              >
                <span>
                  {Object.values(
                    content?.confirmNewOccasion.time
                      .replace("T", " ")
                      .slice(0, -3)
                  )}
                  &nbsp;
                </span>
                <span>
                  {content?.confirmOldOccasion.numberOfTickets > 1
                    ? content?.confirmOldOccasion.numberOfTickets +
                      " " +
                      language.Tickets.toLowerCase()
                    : content?.confirmOldOccasion.numberOfTickets +
                      " " +
                      language.Tickets.toLowerCase()}
                </span>
              </Button>
              <div style={{ marginBottom: "1rem" }}>
                <h3 style={{ margin: 0 }}>{language.PinCodeHeader}</h3>
                <span>{language.PinCodeHelperText}</span>
                <PinInput
                  size="xl"
                  length={4}
                  value={pinValue}
                  onChange={setPinValue}
                  aria-required="true"
                  inputMode="numeric"
                  style={{ marginTop: ".5rem" }}
                  aria-label={language.PinCodeHeader}
                  justify="center"
                />
              </div>
            </>
          )}
        </>
      )}
      {content?.confirmationNewData && step === 5 ? (
        <>
          <div className="progressCircleWrapper" style={{ marginTop: "1rem" }}>
            <p
              className="content"
              style={{ textAlign: "center", fontWeight: "600" }}
            >
              {Object.values(
                content?.confirmationNewData.time.replace("T", " ").slice(0, -3)
              )}
              &nbsp;
              {content?.confirmationOldData.numberOfTickets > 1
                ? content?.confirmationOldData.numberOfTickets +
                  " " +
                  language?.Tickets.toLowerCase()
                : content?.confirmationOldData.numberOfTickets +
                  " " +
                  language?.Ticket.toLowerCase()}
            </p>
          </div>
          <ConfirmationAnimation step={step} />
        </>
      ) : (
        ""
      )}

      {step === 2 ? (
        <MoveOccasaionCalendar
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          occasion={occasion}
          chosenOldOccasion={chosenOldOccasion}
          culture={culture}
          language={language}
        />
      ) : (
        ""
      )}

      {errorMsg.length > 0 ? (
        <motion.div
          animate={{
            transform: [
              "translateX(-5px)",
              "translateX(0px)",
              "translateX(-5px)",
              "translateX(0px)",
              "translateX(-5px)",
              "translateX(0px)",
              "translateX(-5px)",
              "translateX(0px)",
              "translateX(-5px)",
              "translateX(0px)",
            ],
            transition: { duration: 0.5 },
          }}
          className="errorMsg"
          role="alert"
        >
          <p style={{ margin: "0 auto" }}>{errorMsg}</p>
        </motion.div>
      ) : (
        ""
      )}

      <div
        className="contentFooter"
        style={{
          padding:
            step === 0 && errorMsg.length > 0 ? "1rem 0rem 0.5rem 0" : "",
        }}
      >
        {content?.btnText ? <OccasionButton text={content?.btnText} /> : ""}
        {content?.clickToSection === "submit" ? (
          <div
            className={disabled() ? "disabled" : ""}
            style={{ cursor: disabled() ? "default" : "pointer" }}
            onClick={(e) => handlePost(e)}
          >
            <SubmitButton
              primaryText={content?.btnTextPrimary}
              primaryBtn
              loading={loading}
              language={language}
            />
          </div>
        ) : content?.clickToSection === "redirect" ? (
          <OccasionButton
            primaryText={content?.btnTextPrimary}
            primaryBtn
            redirect={content?.redirect}
          />
        ) : step === 0 && errorMsg.length > 0 ? (
          ""
        ) : (
          <OccasionButton
            primaryText={content?.btnTextPrimary}
            primaryBtn
            click={content?.clickToSection}
            setStep={setStep}
            disabled={disabled()}
          />
        )}
      </div>
    </motion.div>
  );
};

export default Content;
