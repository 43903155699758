import React from "react";
import App from "./App";

import { Notifications } from "@mantine/notifications";

const Main = () => {
  return (
    <>
      <Notifications position="bottom-right" />
      <App />
    </>
  );
};

export default Main;
