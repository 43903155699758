import { Button, Title } from "@mantine/core";
import { useSelector } from "react-redux";
import classes from "./CookieBanner.module.css";
import { Link } from "react-router-dom";

const NecessaryCookiesContent = ({ onClose }) => {
  const [language] = useSelector((state) => [state.language]);

  return (
    <div>
      <Title order={2} size="large" id="cookie-box-title">
        {language.CookieSettings}
      </Title>
      <p className={classes.cookieText} id="cookie-box-message">
        {language.FunctionalCookies}{" "}
        <Link className="cookieLink" to="/security">
          {language.Cookies}
        </Link>{" "}
        {language.OnSiteCookies}
      </p>
      <div className={classes.footer}>
        <Button className="primaryButton" onClick={onClose}>
          {language.CookieSimpleConsent}
        </Button>
      </div>
    </div>
  );
};

export default NecessaryCookiesContent;
