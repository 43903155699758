import React, { useMemo } from "react";
import { Button, Divider, Modal, SimpleGrid, Space, Text } from "@mantine/core";
import GroupArticle from "./GroupArticle";
import classes from "./MultiTicketModal.module.css";

const MultiTicketModal = ({
  opened,
  close,
  closeModal,
  language,
  data,
  groupTicketCart,
  setGroupTicketCart,
  item,
}) => {
  const disabled = useMemo(() => {
    if (item) {
      return item?.ticketOwners?.length === 0;
    } else {
      return false;
    }
  }, [groupTicketCart, data, item]);

  return (
    <Modal
      classNames={{
        title: classes.title,
        header: classes.header,
        body: classes.body,
      }}
      opened={opened}
      size="md"
      onClose={() => closeModal()}
      title={language.GroupTicketTitle}
      centered
      closeButtonProps={{
        "aria-label": language.Close,
      }}
    >
      <Text component="p" style={{ marginBottom: "1.5rem" }}>
        {language.GroupTicketDescription}
      </Text>
      <SimpleGrid cols={1} style={{ gap: ".5rem" }}>
        {data.ticketHolders.map((el) => (
          <div key={el.categoryId}>
            <GroupArticle
              allTicketsData={data.ticketHolders}
              ticketData={el}
              groupTicketCart={groupTicketCart}
              setGroupTicketCart={setGroupTicketCart}
              item={item}
            />
            <Divider size="xs" />
          </div>
        ))}
      </SimpleGrid>
      <Space h="lg" />
      <div className={classes.actionWrapper}>
        <Button
          size="md"
          radius="sm"
          className="secondaryButton"
          color="dark"
          variant="subtle"
          onClick={closeModal}
        >
          {language.Close}
        </Button>
        <Button
          size="md"
          radius="sm"
          className="regularButton"
          onClick={close}
          disabled={disabled}
        >
          {language.PrimaryBtn}
        </Button>
      </div>
    </Modal>
  );
};

export default MultiTicketModal;
