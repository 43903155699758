import React from "react";
import classNames from "classnames";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import classes from "./Description.module.css";

const Description = ({ text }) => {
  if (text.length < 1) return <div />;
  return (
    <div>
      <div className={classes.descriptionContainer}>
        <ReactMarkdown
          className={classNames(classes.description, "description")}
          rehypePlugins={[rehypeRaw]}
        >
          {text}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default Description;
