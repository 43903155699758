import React, { useEffect } from "react";
import "./eventStyles.css";
import Header from "./Header";
import Week from "./Week";
import WeekDay from "./WeekDay";
import { Loader } from "@mantine/core";
import { da, de, enGB, sv } from "date-fns/locale";
import { STATE_CULTURE } from "../../../../common/state";
import { useSelector } from "react-redux";

const EventCalendarV2 = ({
  currentDate,
  setCurrentDate,
  firstOccasion,
  lastOccasion,
  occasions,
  day,
  daySet,
  initialMonth,
  cultureLocale,
  setCultureLocale,
}) => {
  const { culture, language } = useSelector((state) => ({
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
  }));

  const locales = [
    { culture: "sv-SE", data: sv },
    { culture: "en-US", data: enGB },
    { culture: "en-GB", data: enGB },
    { culture: "de-DE", data: de },
    { culture: "da-DK", data: da },
  ];

  useEffect(() => {
    const getLocale = locales.filter((el) => el.culture === culture)[0].data;
    setCultureLocale(getLocale);
  }, []);

  return (
    <div className={`event-calendar`}>
      {occasions === undefined || !cultureLocale ? (
        <div className="spinnerWrapper">
          <Loader
            className="loadingspinner"
            style={{ color: "var(--button-primary-bg-color)" }}
          />
          <p style={{ fontWeight: 400 }}>{language.CalendarLoading}</p>
        </div>
      ) : (
        ""
      )}
      <div
        className={`${
          occasions === undefined || !cultureLocale ? "bgBlur" : ""
        }`}
      >
        <Header
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          firstOccasion={firstOccasion}
          lastOccasion={lastOccasion}
          cultureLocale={cultureLocale}
          initialMonth={initialMonth}
          language={language}
        />
        <Week
          currentDate={currentDate}
          cultureLocale={cultureLocale}
          initialMonth={initialMonth}
        />
        <WeekDay
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          selectedDate={day}
          daySet={daySet}
          occasions={occasions}
          cultureLocale={cultureLocale}
        />
      </div>
    </div>
  );
};

export default EventCalendarV2;
