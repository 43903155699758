import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classes from "./ArticleFilter.module.css";
import { translateProductGroup } from "../../../common/util";

const ArticleFilter = ({ page, onChange }) => {
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));

  const [showAlert, setShowAlert] = useState(false);

  const [availableGroups, setAvailableGroups] = useState(
    page.productGroups.map((group) => group.name)
  );

  // Filter result based on availableGroups
  useEffect(() => {
    const filter = { ...page };

    // filter.productGroups becomes the opposite of availableGroups
    filter.productGroups = page.productGroups.filter(
      (group) => !availableGroups.includes(group.name)
    );

    // Call the callback-function onChange with or w/o filter
    if (filter.productGroups.length > 0) {
      onChange(filter);
    } else {
      onChange(page);
    }
  }, [availableGroups, page]);

  // After checkbox-change --> update availableGroups
  const handleChange = (e) => {
    const groupName = e.target.name;

    if (e.target.checked) {
      // If checkbox is now checked, remove the group from availableGroups
      setAvailableGroups((prev) => prev.filter((g) => g !== groupName));
    } else {
      // If checkbox is now unchecked, add the group back to availableGroups
      setAvailableGroups((prev) => [...prev, groupName]);
    }
  };

  const clearFilter = () => {
    // Set all checkboxes to unchecked using DOM manipulation
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((filter) => (filter.checked = false));

    // Trigger WCAG alert (non-visible)
    triggerAlert();

    // Reset availableGroups
    setAvailableGroups(page.productGroups.map((group) => group.name));
  };

  const triggerAlert = () => {
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 1000);
  };

  return (
    <div>
      {config.pages.find((p) => p.name === page.name)
        .showProductGroupFilter && (
        <div className={classes.filterContainer}>
          {page.productGroups.map((group) => {
            return (
              <div key={group.name}>
                <input
                  className={classes.checkbox}
                  type="checkbox"
                  aria-label={translateProductGroup(
                    group.name,
                    language,
                    "Categories"
                  )}
                  id={group.name}
                  name={group.name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <label
                  htmlFor={group.name}
                  className={`${classes.filterInput} ${
                    availableGroups.includes(group.name)
                      ? classes.filterInputUnmarked
                      : classes.filterInputMarked
                  }`}
                >
                  {translateProductGroup(group.name, language, "Categories")}
                </label>
              </div>
            );
          })}
        </div>
      )}
      <div className={classes.clearFilter}>
        <button
          disabled={availableGroups.length === page.productGroups.length}
          className={classes.clearFilterBtn}
          onClick={() => clearFilter()}
        >
          {language.Clear_Filter || "Clear filter"}
          {showAlert && (
            <p className={classes.clearFilterAlert} role="alert">
              Produktfiltret rensat
            </p>
          )}
        </button>
      </div>
    </div>
  );
};

export default ArticleFilter;
