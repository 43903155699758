import React, { useEffect, useState } from "react";
import { Badge, Container, Group, Title, Transition } from "@mantine/core";
import { da, de, enGB, sv } from "date-fns/locale";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../../common/state";
import { format } from "date-fns";
import ArticleList from "./ArticleList";

const ArticlePicker = ({ chosenOccasion, articles, availableOccasions }) => {
  const [cultureLocale, cultureLocaleSet] = useState();
  const [show, setShow] = useState(false);
  const { language, culture } = useSelector((state) => ({
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const locales = [
    { culture: "sv-SE", data: sv },
    { culture: "en-US", data: enGB },
    { culture: "en-GB", data: enGB },
    { culture: "de-DE", data: de },
    { culture: "da-DK", data: da },
  ];
  const formatDay = "EEEE d MMMM HH:mm";

  useEffect(() => {
    const getLocale = locales.filter((el) => el.culture === culture)[0].data;
    cultureLocaleSet(getLocale);
  }, [culture]);

  useEffect(() => {
    if (chosenOccasion) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [chosenOccasion]);

  const articlePicker = document.getElementById("event-article-picker");

  useEffect(() => {
    if (chosenOccasion && show) {
      if (articlePicker) {
        articlePicker.focus();
      }
    }
  }, [chosenOccasion, show]);

  return (
    <div
      id="event-article-picker"
      tabIndex={chosenOccasion && 0}
      aria-label={language.ChooseTicket}
    >
      <Transition
        mounted={show}
        transition="fade"
        duration={250}
        timingFunction="ease"
      >
        {(styles) => (
          <div style={styles}>
            <Container
              className="event-article-group-wrapper"
              px={0}
              style={{ marginTop: "2.5rem" }}
            >
              <Group mb={"md"}>
                <Title id="event-ticket-title" order={3} size="lg">
                  {language.ChooseTicket}
                </Title>
                <Badge color="indigo" size="md" style={{ fontWeight: 700 }}>
                  {chosenOccasion?.time &&
                    format(new Date(chosenOccasion?.time), formatDay, {
                      locale: cultureLocale,
                    })}
                </Badge>
              </Group>
              <ArticleList
                chosenOccasion={chosenOccasion}
                availableOccasions={availableOccasions}
                articles={articles}
              />
            </Container>
          </div>
        )}
      </Transition>
    </div>
  );
};

export default ArticlePicker;
