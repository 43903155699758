import { Skeleton } from "@mantine/core";
import { addDays, format, startOfWeek } from "date-fns";
import { da, de, enGB, sv } from "date-fns/locale";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../../common/state";
import classes from "./DatePickerSkeleton.module.css";

const DatePickerSkeleton = () => {
  const { culture } = useSelector((state) => ({
    culture: state.cookies[STATE_CULTURE],
  }));

  const locales = [
    { culture: "sv-SE", data: sv },
    { culture: "en-US", data: enGB },
    { culture: "en-GB", data: enGB },
    { culture: "de-DE", data: de },
    { culture: "da-DK", data: da },
  ];

  const locale = useMemo(() => {
    const loc = locales.find((cul) => cul.culture === culture);
    return loc.data;
  }, [culture]);

  const weekDays = useMemo(() => {
    const start = startOfWeek(new Date(), { weekStartsOn: 1 });
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = format(addDays(start, i), "EEEEEE", { locale: locale });
      days.push(day);
    }
    return days;
  });

  return (
    <div className={classes.datePickerSkeleton}>
      <div className={classes.header}>
        <svg
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`${classes.calendarIcon}`}
        >
          <path
            d="M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z"
            fill="currentColor"
            fill-rule="evenodd"
            clip-rule="evenodd"
          ></path>
        </svg>

        <span className={classes.date}>
          <Skeleton width={60} height={22} />
          <Skeleton width={40} height={22} />
        </span>
        <svg
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`${classes.calendarIcon} ${classes.calendarIconNext}`}
        >
          <path
            d="M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z"
            fill="currentColor"
            fill-rule="evenodd"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>
      <div className={classes.weekdays}>
        {weekDays.map((day) => (
          <span className={classes.weekday}>{day}</span>
        ))}
      </div>
      <div className={classes.calendarGrid}>
        {new Array(35).fill().map((_, i) => (
          <Skeleton
            width={"100%"}
            height={42}
            style={{ opacity: i < 3 ? 0 : 1 }}
          />
        ))}
      </div>
    </div>
  );
};

export default DatePickerSkeleton;
