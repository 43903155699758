import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  addMonths,
  format,
  isBefore,
  isSameMonth,
  startOfMonth,
  subMonths,
} from "date-fns";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";

const Header = ({
  currentDate,
  setCurrentDate,
  allotmentSettings,
  firstDate,
  latestDate,
  cultureLocale,
  pathname,
  language,
}) => {
  const monthFormat = "MMMM";
  const yearFormat = "yyyy";
  const [, forceUpdate] = useState();

  // forceUpdate state when changing month to trigger re-rendering of the component. It helps google translate to translate the month label correctly.
  const nextMonth = useCallback(() => {
    setCurrentDate((prevDate) => {
      const newDate = addMonths(prevDate, 1);
      forceUpdate({});
      return newDate;
    });
  }, [setCurrentDate]);

  const prevMonth = useCallback(() => {
    setCurrentDate((prevDate) => {
      const newDate = subMonths(prevDate, 1);
      forceUpdate({});
      return newDate;
    });
  }, [setCurrentDate]);

  useEffect(() => {
    setCurrentDate(new Date(firstDate?.time));
  }, [pathname, firstDate, setCurrentDate]);

  const lastMonthLabel = format(subMonths(currentDate, 1), "MMMM", {
    locale: cultureLocale,
  });
  const nextMonthLabel = format(addMonths(currentDate, 1), "MMMM", {
    locale: cultureLocale,
  });
  const thisMonthLabel = format(currentDate, monthFormat, {
    locale: cultureLocale,
  });

  return (
    <>
      {allotmentSettings[0] && (
        <div className="calendarHeader row">
          <div className="column">
            {!isBefore(startOfMonth(currentDate), new Date(firstDate?.time)) ? (
              <button
                className="calendarIcon icon removeBorderBg"
                onClick={prevMonth}
                aria-label={`${language.ShowLastMonth} ${lastMonthLabel}`}
              >
                <IoIosArrowDropleftCircle />
              </button>
            ) : (
              <div className="calendarIcon icon">
                <IoIosArrowDropleftCircle
                  style={{
                    opacity: "0",
                    cursor: "default",
                  }}
                />
              </div>
            )}
          </div>

          <div className="calendarColumn column col-center">
            <span className="monthtext" key={`month-${currentDate.getTime()}`}>
              {thisMonthLabel}&nbsp;
            </span>
            <span className="yeartext" key={`year-${currentDate.getTime()}`}>
              {format(currentDate, yearFormat)}
            </span>
          </div>

          <div className="column col-end">
            {!isSameMonth(new Date(currentDate), new Date(latestDate?.time)) ? (
              <button
                className="calendarIcon icon removeBorderBg"
                onClick={nextMonth}
                aria-label={`${language.ShowNextMonth} ${nextMonthLabel}`}
              >
                <IoIosArrowDroprightCircle />
              </button>
            ) : (
              <div className="calendarIcon icon">
                <IoIosArrowDropleftCircle
                  style={{
                    opacity: "0",
                    cursor: "default",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
