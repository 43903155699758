import { Button, Container } from "@mantine/core";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../../common/state";
import Extension from "./Extension";
import GroupProduct from "./GroupProduct";
import PersonalTicket from "./PersonalTicket";
import SingleProduct from "./SingleProduct";
import { useNavigate } from "react-router";
import MultiTicket from "./MultiTicket/";
import classes from "./ProductV2.module.css";

const ProductV2 = ({ product }) => {
  const [description, descriptionSet] = useState(null);
  const { config, language, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const navigate = useNavigate();

  useEffect(() => {
    if (product.description !== undefined) {
      axios
        .get(`${config.baseUrl}/${product.description}.${culture}.md`)
        .then((res) => descriptionSet(res.data))
        .catch((err) => console.log(err));
    }
    return () => descriptionSet(null);
  }, [product]);

  const isPersonalGroupedTickets = useMemo(() => {
    if (
      product?.articles &&
      product.articles.length > 0 &&
      product.articles.some((article) => article.isPersonalTicket)
    ) {
      return true;
    } else {
      return false;
    }
  }, [product]);

  return (
    <div className={classes.container}>
      <Container className={classes.buttonWrapper}>
        <Button
          className={`${classes.button} backButton`}
          variant="default"
          mb={2}
          onClick={() => navigate(-1)}
        >
          {language.Back}
        </Button>
      </Container>
      {isPersonalGroupedTickets ? (
        <PersonalTicket product={product} />
      ) : product?.articles || product?.openPriceValues?.length > 0 ? (
        <GroupProduct product={product} description={description} />
      ) : product.isMultiTicket ? (
        <MultiTicket product={product} description={description} />
      ) : !product.isPersonalTicket && !product.isModification ? (
        <SingleProduct product={product} description={description} />
      ) : product.isModification ? (
        <Extension product={product} />
      ) : (
        <PersonalTicket product={product} />
      )}
    </div>
  );
};

export default ProductV2;
