export const getAllowedCurrencies = (
  avaliableCurrencies,
  allowedCurrencies
) => {
  let matchedCurrencies = [];
  let filteredCurrencies = [];

  // return available currencies if no allowed currencies
  if (!allowedCurrencies || allowedCurrencies.length < 1)
    return avaliableCurrencies;

  // match allowed currencies to available currencies
  avaliableCurrencies.forEach((c) => {
    if (allowedCurrencies.includes(c.name)) {
      matchedCurrencies = [...matchedCurrencies, c.name];
    }
  });

  // filter out allowed and matched currencies
  matchedCurrencies.forEach((curr) => {
    filteredCurrencies = [
      ...filteredCurrencies,
      avaliableCurrencies.find((c) => c.name === curr),
    ];
  });

  // return filtered currencies
  return filteredCurrencies;
};
