import { Button, SimpleGrid, Text } from "@mantine/core";

const NewOccasionPicker = ({
  el,
  index,
  language,
  chosenOldOccasion,
  chosenNewOccasion,
  setChosenNewOccasion,
  step,
}) => {
  const bookable =
    el.remaining > 0 &&
    el.time !== chosenOldOccasion.occasion &&
    chosenOldOccasion.numberOfTickets <= el.remaining;

  if (!bookable) {
    const statusText =
      el.time === chosenOldOccasion.occasion
        ? language.AlreadyBooked
        : el.remaining === 0
        ? language.SoldOut
        : chosenOldOccasion.numberOfTickets > el.remaining
        ? `${language.Only} ${el.remaining} ${
            el.remaining === 1 ? language.Spot : language.Spots
          } ${language.Left}`
        : "";

    return (
      <Button disabled size="lg" key={el + index}>
        <SimpleGrid cols={1} style={{ gap: 0, textAlign: "center" }}>
          <Text>{el.time.split("T")[1].slice(0, -3)}</Text>
          <Text size="xs">{statusText}</Text>
        </SimpleGrid>
      </Button>
    );
  }

  return (
    <Button
      variant="outline"
      className={`${step !== 3 ? "oldOccasion" : "newOccasionTime"} ${
        chosenNewOccasion === el ? "primaryButton" : "primaryButtonOutline"
      }`}
      size="lg"
      onClick={() => setChosenNewOccasion(el)}
      key={el + index}
    >
      <span>{el.time.split("T")[1].slice(0, -3)}</span>
    </Button>
  );
};

export default NewOccasionPicker;
