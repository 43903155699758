import { ActionIcon, Group, NumberInput } from "@mantine/core";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCartTracking,
  decreaseQuantity,
  increaseQuantity,
  removeFromCartTracking,
} from "../../common/sale";
import { HiMinusSm, HiPlusSm } from "react-icons/hi";
import classes from "./QuantityInput.module.css";
import { STATE_CURRENCY } from "../../common/state";

const QuantityInput = ({
  inStock,
  item,
  id,
  quantity,
  name,
  addArticle,
  isDisabled,
}) => {
  const { language, currency } = useSelector((state) => ({
    language: state.language,
    currency: state.cookies[STATE_CURRENCY],
  }));
  const rdxDispatch = useDispatch();
  const handlers = useRef();
  const quantityChangeRef = useRef();

  const trackItem = [
    {
      id: item?.plu ? item?.plu : item?.article?.plu,
      name: item?.name ? item?.name : item?.article?.name,
      price: item?.price?.amountInclVat
        ? item?.price?.amountInclVat
        : item?.pricePerPiece?.amountInclVat,
      quantity: 1,
    },
  ];

  const increase = () => {
    if (quantity > 0) {
      handlers.current.increment();
      announceQuantityChange(quantity + 1);
      rdxDispatch(increaseQuantity(id));
      addToCartTracking(currency, trackItem);
    } else if (addArticle) {
      addArticle();
    }
  };

  const decrease = () => {
    rdxDispatch(decreaseQuantity(id));
    handlers.current.decrement();
    announceQuantityChange(quantity - 1);

    if (quantity - 1 === 0) {
      removeFromCartTracking(
        currency,
        trackItem,
        item.totalPrice.amountInclVat
      );
    }
  };

  const announceQuantityChange = (newQuantity) => {
    quantityChangeRef.current.innerText = `${language.Quantity} ${newQuantity}`;
  };

  return (
    <div className={classes.quantityWrapper}>
      <div
        className={classes.hideLabel}
        role="region"
        aria-live="assertive"
        ref={quantityChangeRef}
      />
      <Group
        gap={5}
        className={classes.actionWrapper}
        role="region"
        aria-live="polite"
      >
        <ActionIcon
          className={`${classes.actionButton} addToCart`}
          size={30}
          color="gray"
          radius={50}
          variant="filled"
          disabled={
            (item?.__typename === "saleItem" && !item?.canChangeQuantity) ||
            item?.extraInformation?.length > 0 ||
            quantity === 0 ||
            item.isGiftCertificate ||
            item.article?.isMultiTicket
          }
          onClick={() => decrease()}
          aria-label={`${name} ${language.DecreaseQuantity}`}
        >
          <div style={{ fontSize: "1.5rem", height: "24px" }}>
            <HiMinusSm />
          </div>
        </ActionIcon>
        <NumberInput
          aria-label={`${language.Quantity} ${quantity}`}
          hideControls
          value={quantity}
          max={0}
          min={1}
          step={1}
          tabIndex={-1}
          styles={{
            input: {
              backgroundColor: "transparent",
              border: "none",
              width: 30,
              textAlign: "center",
              height: 30,
              fontWeight: "600",
              fontSize: "1.25rem",
              padding: 0,
              pointerEvents: "none",
            },
          }}
          handlersRef={handlers}
        />
        <ActionIcon
          className={`${classes.actionButton} addToCart`}
          aria-label={`${name} ${language.AddQuantity}`}
          size={30}
          color="gray"
          radius={50}
          variant="filled"
          disabled={
            item?.extraInformation?.length > 0 ||
            isDisabled ||
            inStock === quantity ||
            (item?.__typename === "saleItem" && !item?.canChangeQuantity) ||
            item.isGiftCertificate ||
            item.article?.isMultiTicket
          }
          onClick={() => increase()}
        >
          <div style={{ fontSize: "1.5rem", height: "24px" }}>
            <HiPlusSm />
          </div>
        </ActionIcon>
      </Group>
    </div>
  );
};

export default QuantityInput;
