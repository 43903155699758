import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mantine/core";
import Flag from "./flag";
import { setCulture, STATE_CULTURE } from "../../common/state";
import { DataContext } from "../common/dataProvider";
import classes from "./cultures.module.css";

const Cultures = () => {
  const { data, refetch } = useContext(DataContext);
  const dispatch = useDispatch();
  const handleSetCulture = (culture, config) => {
    dispatch(setCulture(culture, config));
    refetch();
  };
  const { language, config, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const cultures = data?.cultures;
  const active = culture;

  if (!cultures) return null;
  return (
    <div className={classes.container}>
      <h6 style={{ fontSize: "1rem" }}>{language.Culture}</h6>
      {cultures.map((c, i) => {
        const isActive = c.name === active;
        return (
          <Button
            key={i}
            variant="subtle"
            onClick={() => handleSetCulture(c.name, config)}
            className={classes.button}
            classes={{ disabled: classes.active }}
            disabled={isActive}
            data-testid={`button_${c.name}`}
          >
            <Flag culture={c.name} />
            <span className={classes.buttonText}>
              {c.description.toUpperCase()}
            </span>
          </Button>
        );
      })}
    </div>
  );
};

export default Cultures;
