import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import classes from "./Button.module.css";

const Button = (props) => {
  const [activeBtn, setActiveBtn] = useState(false);

  const location = useLocation();
  const click = (data) => {
    setActiveBtn(data);
    props.setIsVisible(false);
    sessionStorage.setItem("articleBookingBtn", data);
    setTimeout(() => {
      props.setSection(data);
    }, 50);
    setTimeout(() => {
      props.scrollIntoView("IntroductionSection");
    }, 70);
  };

  useEffect(() => {
    props.setSection("");
  }, [location.pathname]);

  useEffect(() => {
    props.setIsVisible(true);
    if (props.section.length === 0) {
      setActiveBtn(false);
    }
    if (props.text === props.section.name) {
      setActiveBtn(true);
    } else {
      setActiveBtn(false);
    }
  }, [props.section]);

  useEffect(() => {
    setActiveBtn("");
    sessionStorage.setItem("articleBookingBtn", "notactive");
  }, [location.pathname]);

  return (
    <>
      <button
        className={`${classes.occasionBtn}  ${
          activeBtn ? "activeArticleButton" : "articleBookingButton"
        }`}
        onClick={() => click(props.data)}
        // disabled={props.disabled}
        style={{ pointerEvents: activeBtn ? "none" : "" }}
      >
        {props.text}
      </button>
    </>
  );
};

export default Button;
