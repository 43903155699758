import React, { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { DataContext } from "../../components/common/dataProvider";
import PageLayout from "../../components/Styles/PageLayout";
import { getEvent } from "./util";
import slugify from "../../components/common/slugify";
import PageContent from "../../components/Styles/PageContent";
import EventV2 from "../../components/Event/EventV2";
import { Button, Container, Text, Title } from "@mantine/core";
import { useNavigate, useParams } from "react-router";

const EventPage = () => {
  const { data, eventFilter } = useContext(DataContext);
  const { language } = useSelector((state) => ({
    language: state.language,
  }));
  const navigate = useNavigate();
  const id = useParams().id;
  const allotment = data?.allotments.find(
    (allotment) => slugify(allotment.description) === id
  );
  const event = useMemo(() => getEvent(data, id), [data, id]);

  if (!allotment)
    return (
      <PageLayout>
        <PageContent>
          <Container
            style={{ textAlign: "center", margin: "3rem 0", maxWidth: "100%" }}
          >
            <Title mb="lg" order={1}>
              {language.CouldNotFindEventHeader}
            </Title>
            <Text component="p" mb="lg">
              {language.CouldNotFindEventText}
            </Text>
            <Button
              component="a"
              onClick={() => navigate("/")}
              className="mantine-Primary-btn primaryButton"
              variant="filled"
              size="md"
              radius="sm"
              color="indigo"
            >
              {language.BackToHomepage}
            </Button>
          </Container>
        </PageContent>
      </PageLayout>
    );

  return (
    <PageLayout>
      <PageContent>
        <div>
          <EventV2 filter={eventFilter} event={event} />
        </div>
      </PageContent>
    </PageLayout>
  );
};

export default EventPage;
