import { Accordion, Drawer, SimpleGrid } from "@mantine/core";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import slugify from "../../common/slugify";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { motion } from "framer-motion";
import Login from "../login";
import { STATE_CULTURE } from "../../../common/state";
import { useMediaQuery } from "@mantine/hooks";
import classes from "./Menu.module.css";
import { allLinks } from "./Links";

const MenuDrawer = ({ opened, setOpened }) => {
  const { config, culture, language } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
  }));
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const getLinks = useMemo(() => {
    return config?.pages?.filter((el) => !el.disabled && !el.useInDropDown);
  }, [config]);

  const dropDownLinks = useMemo(() => {
    return config?.newHeaderDropDown?.filter((el) => !el.disabled);
  }, [config]);

  const linkHref = (item) => {
    const slug = slugify(item.name);
    if (item?.internalLink) {
      return `${item.internalLink}`;
    }
    if (item?.externalLink) {
      return `${item.externalLink}`;
    }
    if (item?.eventCalendar) {
      return `/tickets/${slug}`;
    }
    if (item?.articleBooking) {
      return `/booking/${slug}`;
    }
    if (item?.publicBooking) {
      return `/publicbooking/${slug}`;
    }
    return `/${slug}`;
  };

  useEffect(() => {
    if (opened) setOpened(false);
  }, [location.pathname]);

  const isDropDownLinkActive = useCallback(
    (link) => {
      // link is an object, dropDownLinks.subLink
      const splitUrl = location.pathname.split("/").filter(Boolean).pop();

      if (
        link.internalLink &&
        link.internalLink.replaceAll("/", "") === splitUrl
      ) {
        return true;
      }

      if (!link.internalLink && slugify(link.name) === splitUrl) {
        return true;
      }
      return false;
    },
    [location]
  );

  const isLinkActive = useCallback(
    (link) => {
      // link is a string
      const splitUrl = location.pathname.split("/").filter(Boolean).pop();

      if (location.pathname === "/" && link === "hem") {
        return true;
      } else if (slugify(link) === splitUrl) return true;
      else return false;
    },
    [location]
  );

  const fixInternalLink = (internalLink) =>
    internalLink.split("/").filter(Boolean).pop();

  const nameTranslation = (link) => {
    const getTranslation =
      link?.translations &&
      link.translations.find((translation) => translation.culture === culture);
    if (getTranslation) {
      return getTranslation?.name;
    } else return link.name;
  };

  const getTranslatedName = useCallback(
    (name) => {
      if (language && language?.LandingTexts && name) {
        const getTranslation = language?.LandingTexts[name];
        return getTranslation?.primary;
      }
    },
    [language]
  );

  return (
    <Drawer
      opened={opened}
      onClose={() => setOpened(false)}
      title={language.Menu}
      padding="xl"
      size={isMobile ? "sm" : "xl"}
      className={classes.drawer}
      classNames={{
        content: classes.content,
        header: classes.content,
        title: classes.content,
        close: classes.content,
      }}
      closeButtonProps={{
        "aria-label": language.Close,
      }}
    >
      <div className={classes.menuWrapper}>
        <SimpleGrid cols={1} className={`${classes.mobileLinks} headerLinks`}>
          <Link className={classes.linkRelative} to={"/"}>
            {language.Home}
            <motion.div animate>
              {isLinkActive("hem") ? (
                <motion.div
                  className={classes.underlineMobile}
                  layoutId="underline"
                />
              ) : null}
            </motion.div>
          </Link>
          {allLinks(getLinks, dropDownLinks).map((link, i) => {
            if (link?.isDropDown) {
              return (
                <Accordion
                  classNames={{
                    panel: classes.accordionPanel,
                    item: classes.accordionItem,
                    control: classes.accordionControl,
                    content: classes.accordionContent,
                    label: classes.accordionLabel,
                  }}
                  key={nameTranslation(link)}
                  defaultValue={nameTranslation(link)}
                >
                  <Accordion.Item value={nameTranslation(link)}>
                    <Accordion.Control>
                      {nameTranslation(link)}
                    </Accordion.Control>
                    <Accordion.Panel>
                      {link.subLinks.map((subLink, i) => {
                        if (!subLink.disabled)
                          return (
                            <Link
                              className={`${classes.mobileLinks} v3-dropdown-links`}
                              key={`${subLink.name}${i}`}
                              to={linkHref(subLink)}
                            >
                              {nameTranslation(subLink)}
                              <motion.div animate>
                                {isDropDownLinkActive(subLink) ? (
                                  <motion.div
                                    className={classes.underlineMobile}
                                    layoutId="underline"
                                  />
                                ) : null}
                              </motion.div>
                            </Link>
                          );
                      })}
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              );
            } else {
              if (link.externalLink) {
                return (
                  <a
                    key={link.name}
                    href={linkHref(link)}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.linkRelative}
                  >
                    {getTranslatedName(link.name)}
                  </a>
                );
              } else {
                return (
                  <Link
                    className={classes.linkRelative}
                    key={i}
                    to={linkHref(link)}
                  >
                    {getTranslatedName(link.name)}
                    <motion.div animate>
                      {isLinkActive(
                        link.internalLink
                          ? fixInternalLink(link.internalLink)
                          : link.name
                      ) ? (
                        <motion.div
                          className={classes.underlineMobile}
                          layoutId="underline"
                        />
                      ) : null}
                    </motion.div>
                  </Link>
                );
              }
            }
          })}
          {config?.showAllProducts && (
            <Link className={classes.linkRelative} to={"/all-products"}>
              {language.All_Products}
              <motion.div animate>
                {isLinkActive("all-products") ? (
                  <motion.div
                    className={classes.underlineMobile}
                    layoutId="underline"
                  />
                ) : null}
              </motion.div>
            </Link>
          )}
          {config?.useAllEvents && (
            <Link className={classes.linkRelative} to={"/all-events"}>
              {language.All_Events}
              <motion.div animate>
                {isLinkActive("all-events") ? (
                  <motion.div
                    className={classes.underlineMobile}
                    layoutId="underline"
                  />
                ) : null}
              </motion.div>
            </Link>
          )}
        </SimpleGrid>
        {config?.showLogin && <Login />}
      </div>
    </Drawer>
  );
};

export default MenuDrawer;
