import React, { useContext, useEffect } from "react";
import { Context, CLOSE_CART } from "../../context";
import { SaleContext } from "../common/saleProvider";
import { useSelector } from "react-redux";
import DrawerCart from "../Drawer";

const Cart = () => {
  const {
    state: { cartOpen },
    dispatch,
  } = useContext(Context);
  const { config } = useSelector((state) => ({
    config: state.config,
  }));
  const saleContext = useContext(SaleContext);

  const { sale } = saleContext.state;
  useEffect(() => {
    if (cartOpen && config?.timeoutDrawer) {
      window.setTimeout(() => {
        dispatch({ type: CLOSE_CART });
      }, 5000);
    }
  }, [cartOpen, dispatch, config]);

  if (!sale) return <div />;
  return <DrawerCart cartOpen={cartOpen} sale={sale} />;
};

export default Cart;
