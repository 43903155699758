import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import axios from "axios";
import _ from "lodash";
import slugify from "../common/slugify";

const IntroductionSection = ({
  config,
  language,
  culture,
  section,
  selfUrl,
  settingsData,
  bookingData,
}) => {
  const [content, setContent] = useState();
  const [showLongText, setShowLongText] = useState(false);
  const [text, setText] = useState();
  const [header, setHeader] = useState("");

  useEffect(() => {
    const filter = bookingData.filter((el) => el.name === section.name);

    if (filter.length > 0 && settingsData) {
      axios.all([
        axios
          .get(
            `${config.baseUrl}/ee-static/shop/${
              config.shopName
            }//Texts/article-booking-${slugify(settingsData?.name)}-${
              filter[0].slug
            }.${culture}.md`
          )
          .then((data) => setText(data.data))
          .catch((err) => {
            console.log(err);
          }),
      ]);
      setContent(filter);
    }
  }, [bookingData, settingsData, section]);

  return (
    <div
      className="introductionWrapper"
      key="introduction"
      style={{
        marginTop: bookingData.length === 1 ? "-2rem" : "",
        transition: bookingData.length === 1 ? "none" : "",
      }}
    >
      {sessionStorage.getItem("articleBookingBtn") !== "notactive" ? (
        <>
          <div className="introductionHeader">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{header}</ReactMarkdown>
          </div>
          {content && content[0] !== undefined ? (
            <div
              className="introductionTextWrapper"
              key="articleBookingIntroductionWrapper"
            >
              <img
                src={`${config.baseUrl}${config.imageFolderUrl}${content[0].introductionImage}`}
                key="articleBookingImg"
                className="introductionImage"
                alt="bild på evenemanget"
              />
              <div className="introductionText">
                {text &&
                window.screen.availWidth <= 768 &&
                text.length > 300 ? (
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {showLongText
                      ? text
                      : _.truncate(text, { length: 300 }) + "... "}
                  </ReactMarkdown>
                ) : (
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {text}
                  </ReactMarkdown>
                )}
              </div>
              {text && text?.length > 300 ? (
                <button
                  className="readMoreButton"
                  onClick={() => setShowLongText(!showLongText)}
                  aria-label={
                    !showLongText
                      ? language.ArticleBooking.ReadMoreButton
                      : language.ArticleBooking.HideText
                  }
                >
                  {!showLongText
                    ? language.ArticleBooking.ReadMoreButton
                    : language.ArticleBooking.HideText}
                </button>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default IntroductionSection;
