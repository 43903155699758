import React, { useEffect, useMemo, useState } from "react";
import { Button, Text, Title } from "@mantine/core";
import { useSelector } from "react-redux";
import "../styles.css";
import { format, isSameDay } from "date-fns";
import classes from "./TimeChooser.module.css";

const TimeChooser = ({ form, occasions }) => {
  const [activeTime, activeTimeSet] = useState(null);
  const { language } = useSelector((state) => ({
    language: state.language,
  }));

  useEffect(() => {
    if (activeTime) {
      const date = activeTime;
      form.setFieldValue("occasion", date);
    }
  }, [activeTime]);

  const getOccasions = useMemo(() => {
    const filter =
      occasions &&
      occasions.filter((el) =>
        isSameDay(new Date(el), new Date(form.values.occasion))
      );
    if (filter) return filter.sort((a, b) => new Date(a) - new Date(b));
  }, [form, occasions]);

  return (
    <div
      className={classes.container}
      style={{ opacity: form.values.occasion === "" && 0.25 }}
    >
      <div className={classes.titleWrapper}>
        <Title order={3} style={{ fontSize: "1rem", fontWeight: 500 }}>
          {language.Occasion_Time_Label}
        </Title>
        <Text>{language.ChooseTimeToContinue}</Text>
      </div>
      <div className={classes.wrapper}>
        {getOccasions &&
          getOccasions.map((el) => (
            <Button
              key={el}
              className={
                activeTime === el ? "primaryButton" : "primaryButtonOutline"
              }
              variant={activeTime !== el && "outline"}
              onClick={() => activeTimeSet(el)}
            >
              {format(new Date(el), "HH:mm")}
            </Button>
          ))}
      </div>
    </div>
  );
};

export default TimeChooser;
