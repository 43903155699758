import React, { useEffect, useState } from "react";
import DateChooser from "./DateChooser";
import TimeChooser from "./TimeChooser";
import { useMediaQuery } from "@mantine/hooks";
import { useLazyQuery } from "@apollo/client";
import { GET_PUBLIC_BOOKING_OCCASIONS } from "../publicBookingQuery";
import { addYears } from "date-fns";
import classes from "./DateTimePicker.module.css";

const DateTimePicker = ({ form, bookingApiClient }) => {
  const [calendarDates, calendarDatesSet] = useState({ from: null, to: null });

  const [lazyOccasionsQuery, { data: initialData, loading: initialLoading }] =
    useLazyQuery(GET_PUBLIC_BOOKING_OCCASIONS, {
      client: bookingApiClient,
    });

  useEffect(() => {
    lazyOccasionsQuery({
      variables: {
        activityId: form.values.activityId,
        occasionsFrom: new Date(),
        occasionsTo: addYears(new Date(), 1),
      },
    });
  }, [form.values.activityId]);

  const matches = useMediaQuery("(max-width: 768px)");
  return (
    <div
      className={classes.container}
      style={{ flexDirection: matches ? "column" : "row" }}
    >
      <DateChooser
        initialData={initialData}
        occasions={initialData?.publicBookingOccasions}
        form={form}
        calendarDates={calendarDates}
        calendarDatesSet={calendarDatesSet}
        initialLoading={initialLoading}
      />
      <TimeChooser
        form={form}
        occasions={initialData?.publicBookingOccasions}
      />
    </div>
  );
};

export default DateTimePicker;
