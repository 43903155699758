import { Badge, Group, Paper, Text, ThemeIcon, Title } from "@mantine/core";
import Ticket from "./icons/Ticket";
import React from "react";
import { differenceInDays } from "date-fns";
import { useSelector } from "react-redux";
import classes from "./ExtensionCard.module.css";

const ExtensionCard = ({ card }) => {
  const { language } = useSelector((state) => ({
    language: state.language,
  }));
  const ICON_SIZE = 40;

  const badgeText = () => {
    const difference = differenceInDays(new Date(card.validUntil), new Date());
    if (difference > 0) {
      return `${differenceInDays(new Date(card.validUntil), new Date())} ${
        language.DaysLeft
      }`;
    } else {
      return language.ExtensionTicketExpired;
    }
  };

  return (
    <>
      <Title order={5}>{language.YourTicket}</Title>
      <Paper
        radius="md"
        withBorder
        className={`${classes.card}`}
        mt={ICON_SIZE / 3}
        p={0}
      >
        <div className={classes.wrapper}>
          <ThemeIcon
            className={`${classes.icon} regularButton`}
            size={ICON_SIZE}
            radius={ICON_SIZE}
          >
            <Ticket size={25} stroke={1.5} />
          </ThemeIcon>

          <Text align="center" fw={700} className={classes.title}>
            {card.owner?.firstname} {card.owner?.lastname}
          </Text>
          <Text c="black" align="center" size="sm" mt="xs">
            {card?.validFrom?.split("T")[0]} - {card?.validUntil?.split("T")[0]}
          </Text>
          <Group justify="center" mt="md">
            <Text size="sm">{card.article.name}</Text>
            <Badge size="sm">{badgeText()}</Badge>
          </Group>
        </div>
      </Paper>
    </>
  );
};

export default ExtensionCard;
