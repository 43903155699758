import React, { useContext, useEffect, useState } from "react";
import {
  addArticleBatch,
  addArticleGroupTicket,
  addToCartTracking,
} from "../../common/sale";
import { useDispatch, useSelector } from "react-redux";
import { CART_ARTICLE_ADD, Context, SUSPEND_OPEN_CART } from "../../context";
import { getCartArticle } from "../cart/util";
import { useNavigate } from "react-router";
import { STATE_CURRENCY } from "../../common/state";
import { notification } from "../common/util";
import { Button } from "@mantine/core";

const AddToCart = ({
  cart,
  setCart,
  allotmentId,
  clicked,
  setClicked,
  language,
  groupTicketCart,
  setGroupTicketCart,
  allotments,
}) => {
  const rdxDispatch = useDispatch();
  const { dispatch } = useContext(Context);
  const { config, currency } = useSelector((state) => ({
    config: state.config,
    currency: state.cookies[STATE_CURRENCY],
  }));
  const [product, setProduct] = useState([]);
  const [text, setText] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setProduct(groupByTimes(cart));
  }, [cart]);

  const reduceCart = Object.values(
    cart.reduce((acc, curr, i) => {
      if (!acc[curr.plu]) {
        acc[curr.plu] = {
          plu: curr.plu,
          quantity: 1,
          allotmentId: curr.allotmentId,
          allotmentOccasion: curr.timeOccasions,
          extraInformation: curr?.extraInformation,
        };
      } else if (acc[curr.plu] && curr?.extraInformation) {
        acc[i] = {
          plu: curr.plu,
          quantity: 1,
          allotmentId: curr.allotmentId,
          allotmentOccasion: curr.timeOccasions,
          extraInformation: curr?.extraInformation,
        };
      } else {
        acc[curr.plu].quantity += 1;
      }
      return acc;
    }, {})
  );

  const chosenAllotment =
    cart &&
    allotmentId &&
    cart.filter((el) => {
      return allotmentId.articles.some((ele) => {
        return el.id === ele.id;
      });
    });

  const groupByTimes = (data) =>
    Object.values(
      data.reduce(
        (data, { id, plu }) =>
          Object.assign({}, data, {
            [id]: data[id]
              ? { id, plu: [...data[id].plu, plu] }
              : { id, plu: [plu] },
          }),
        {}
      )
    );

  const cartArticle = product && getCartArticle(product, config);

  const productPrice = (item) => {
    if (item && item.length > 0) {
      const regex = /[$€£kr]/g;
      let newPrice = item.replace(regex, "");
      if (newPrice.trim().split(".")[1] === "00") {
        return newPrice.split(".")[0];
      } else {
        return newPrice;
      }
    }
  };

  const trackReduceCart = Object.values(
    cart.reduce((acc, curr) => {
      if (!acc[curr.plu]) {
        acc[curr.plu] = {
          id: curr.plu,
          quantity: 1,
          price: parseFloat(productPrice(curr.priceInclVat).replace(",", ".")),
          name: curr.name,
        };
      } else {
        acc[curr.plu].quantity += 1;
      }
      return acc;
    }, {})
  );

  const trackGroupTicket = () => {
    const ticket = allotments.articles.filter((article) =>
      groupTicketCart.map((item) => item.plu).includes(article.plu)
    );
    const trackingGroupTicket = ticket.reduce((acc, curr) => {
      const existingItem = acc.find((item) => item.id === curr.plu);
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        acc.push({
          id: curr.plu,
          quantity: 1,
          price: curr.price.amountInclVat,
          name: curr.name,
        });
      }
      return acc;
    }, []);
    return trackingGroupTicket;
  };

  const cb = (status, msg) => {
    if (status >= 400) {
      notification("error", language.ErrorMsgCouldNotAddToCart);
      setClicked(false);
      setCart([]);
      setGroupTicketCart([]);
    }
  };

  const cbFinally = () => {
    dispatch({
      type: CART_ARTICLE_ADD,
      payload: cartArticle,
    });
    dispatch({
      type: SUSPEND_OPEN_CART,
      payload: false,
    });
    setCart([]);
    setGroupTicketCart([]);
    setClicked(false);
    setText(language.Added_To_Cart);
    setTimeout(() => {
      setText(null);
    }, 3000);
    config?.redirectEventCalendar && navigate("/ticketshop/cart");
  };

  const add = async () => {
    setClicked(true);
    dispatch({
      type: SUSPEND_OPEN_CART,
      payload: true,
    });
    try {
      if (groupTicketCart?.length > 0) {
        await new Promise((resolve) => {
          rdxDispatch(
            addArticleGroupTicket(groupTicketCart, (status, msg) => {
              resolve();
              cb(status, msg);
              addToCartTracking(currency, trackGroupTicket());
            })
          );
        });
      }

      if (reduceCart?.length > 0) {
        await new Promise((resolve) => {
          rdxDispatch(
            addArticleBatch(reduceCart, (status, msg) => {
              cb(status, msg);
              resolve();
              addToCartTracking(currency, trackReduceCart);
            })
          );
        });
      }
    } catch (error) {
      console.error("Error during add operation:", error);
    } finally {
      cbFinally();
    }
  };

  return (
    <>
      <Button
        onClick={() => add()}
        disabled={
          (chosenAllotment?.length === 0 && groupTicketCart.length === 0) ||
          clicked
        }
        aria-live="assertive"
        className="primaryButton"
        size="md"
        color="var(--button-primary-bg-color)"
        loading={clicked}
      >
        {text ? text : language.AddToCartBtn}
      </Button>
    </>
  );
};

export default AddToCart;
