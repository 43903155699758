import React from "react";
import AlertIcon from "../Drawer/AlertIcon";
import "./styles.css";

const Legend = ({ language }) => {
  return (
    <div className="legend">
      <div className="legend-iconwrapper">
        <div className="legend-chosen" />
        <p className="legend-text">{language.Chosen}</p>
      </div>

      <div className="legend-iconwrapper">
        <div className="legend-available" />
        <p className="legend-text">{language.Available}</p>
      </div>

      <div className="legend-iconwrapper">
        <div className="legend-low" />
        <div
          style={{
            position: "absolute",
            width: "1.1rem",
            height: "1.1rem",
            pointerEvents: "none",
          }}
        >
          <AlertIcon />
        </div>
        <p className="legend-text">{language.FewLeft}</p>
      </div>

      <div className="legend-iconwrapper">
        <div className="legend-full" />
        <p className="legend-text">{language.FullAllotment}</p>
      </div>
    </div>
  );
};

export default Legend;
