import React, { useState, useEffect } from "react";
import "./calendarStyles.css";
import Header from "./Header";
import Week from "./Week";
import WeekDay from "./WeekDay";
import { Loader } from "@mantine/core";
import { sv, de, da, enGB, enUS } from "date-fns/locale";
import { addMonths, isAfter, isSameMonth } from "date-fns";

const MoveOccasaionCalendar = ({
  currentDate,
  setCurrentDate,
  selectedDate,
  setSelectedDate,
  occasion,
  chosenOldOccasion,
  culture,
  language,
}) => {
  const [lastOccasion, setLastOccasion] = useState();
  const [firstOccasion, setFirstOccasion] = useState();
  const [cultureLocale, setCultureLocale] = useState();

  useEffect(() => {
    if (occasion !== undefined && occasion.length > 0) {
      setFirstOccasion(occasion[0].occasions[0]);
      setLastOccasion(occasion[0].occasions.slice(-1)[0]);
    }
  }, [occasion]);

  useEffect(() => {
    if (isAfter(new Date(firstOccasion?.time), new Date(currentDate))) {
      setCurrentDate(new Date(firstOccasion?.time));
    }
  }, [firstOccasion]);

  const locales = [
    { culture: "sv-SE", data: sv },
    { culture: "en-US", data: enGB },
    { culture: "en-GB", data: enGB },
    { culture: "de-DE", data: de },
    { culture: "da-DK", data: da },
  ];

  useEffect(() => {
    const getLocale = locales.filter((el) => el.culture === culture)[0].data;
    setCultureLocale(getLocale);
  }, []);

  return (
    <div className={`calendar`}>
      {occasion === undefined ? (
        <div className="spinnerWrapper">
          {" "}
          <Loader
            className="loadingspinner"
            style={{ color: "rgb(53, 53, 53)" }}
          />
          <p style={{ fontWeight: 400 }}>{language.CalendarLoading}</p>
        </div>
      ) : (
        ""
      )}
      <div className={`${occasion === undefined ? "bgBlur" : ""}`}>
        <Header
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          selectedDate={selectedDate}
          firstOccasion={firstOccasion}
          lastOccasion={lastOccasion}
          culture={culture}
          cultureLocale={cultureLocale}
        />

        <Week
          currentDate={currentDate}
          cultureLocale={cultureLocale}
          firstOccasion={firstOccasion}
        />
        <WeekDay
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          occasion={occasion}
          chosenOldOccasion={chosenOldOccasion}
          cultureLocale={cultureLocale}
        />
      </div>
    </div>
  );
};

export default MoveOccasaionCalendar;
