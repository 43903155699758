import React from "react";
import clsx from "clsx";
import { Loader, Modal } from "@mantine/core";
import classes from "./busy.module.css";

const Busy = (props) => {
  const { busy, busyText } = props;

  if (busy) {
    return (
      <Modal
        opened={busy}
        fullScreen={true}
        classNames={{ modal: classes.backdrop }}
        withCloseButton={false}
      >
        <div className={clsx(classes.container, "busy__container")}>
          <Loader color="var(--theme-primary-color)" size={256} />
          <h1 className={classes.text} aria-label={busyText}>
            {busyText}
          </h1>
        </div>
      </Modal>
    );
  }
  return <div />;
};

export default Busy;
