import { Group, Burger } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useRef, useState } from "react";
import classes from "./Menu.module.css";
import { useSelector } from "react-redux";
import SearchV2 from "../../common/Search/SearchV2";
import CartV2 from "../CartV2";
import Settings from "../../Settings";
import CountdownContainer from "../CountdownContainer";
import Login from "../login";
import Links from "./Links";
import MenuDrawer from "./MenuDrawer";
import { motion } from "framer-motion";
import { useLocation } from "react-router";

export function Menu() {
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  const [opened, setOpened] = useState(false);
  const headerSettingsRef = useRef(null);
  const logoLinkRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const location = useLocation();
  const isHome = location.pathname === "/";

  const logo = `${config.baseUrl}/ee-static/shop/${config.shopName}/Images/${
    config.logoImg ? config.logoImg : "logo.svg"
  }`;

  const headerHeight = () => {
    if (isMobile) {
      return "var(--header-height-xs)";
    } else {
      return "var(--header-height)";
    }
  };

  const alignment = () => {
    if (config?.headerAlignment === "start") return "start";
    if (config?.headerAlignment === "center") return "center";
    if (config?.headerAlignment === "end") return "end";
    return "center";
  };

  return (
    <header
      className={`${classes.header} ${
        isHome ? "nav-header-home" : "nav-header"
      }`}
      style={{ height: headerHeight() }}
    >
      <div className={`${classes.inner} header__inner`}>
        <Group className={classes.logoWrapper}>
          <Burger
            // className={classes.burger}
            classNames={{ burger: classes.burger }}
            opened={opened}
            onClick={setOpened}
            size="sm"
            aria-label={language.OpenMenu}
          />
          <Settings />
        </Group>
        <div
          className="header__offset-width"
          style={{
            minWidth: !isMobile && headerSettingsRef?.current?.offsetWidth,
          }}
          ref={logoLinkRef}
        >
          <a className={classes.logoLink} href={`${config?.logoLink}`}>
            <img src={logo} className={classes.logo} alt="logo" />
          </a>
        </div>
        <motion.div
          className={classes.linksWrapper}
          style={{
            justifyContent: alignment(),
          }}
        >
          <Links />
        </motion.div>
        <Group
          className={classes.iconWrapper}
          gap={"16px"}
          wrap="nowrap"
          ml={"1rem"}
          ref={headerSettingsRef}
        >
          {!config?.hideSearch && <SearchV2 />}
          {!isMobile && <Settings />}
          <CartV2 />
          {!isMobile && <CountdownContainer parent={"Header"} />}
          {config?.showLogin && !isMobile && <Login />}
        </Group>
      </div>
      <MenuDrawer opened={opened} setOpened={setOpened} />
    </header>
  );
}

export default Menu;
