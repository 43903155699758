import { useQuery } from "@apollo/client";
import {
  Button,
  Container,
  SimpleGrid,
  Skeleton,
  Text,
  Title,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { format, isSameDay } from "date-fns";
import gql from "graphql-tag";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { STATE_CULTURE, STATE_CURRENCY } from "../../../common/state";
import ArticlePicker from "./ArticlePicker";
import { getInitialFromUntil } from "../../../common/util";

const { from, until } = getInitialFromUntil();

const query = gql`
  query detailQuery(
    $currency: String
    $culture: String
    $posId: Int
    $from: DateTime
    $until: DateTime
  ) {
    allotments(
      currency: $currency
      culture: $culture
      posId: $posId
      from: $from
      until: $until
    ) {
      description
      occasions {
        time
        remaining
        allotment {
          id
          maxPerSale
        }
      }
    }
  }
`;

const TimePicker = ({ day, allotment, cultureLocale }) => {
  const [availableOccasions, setAvailableOccasions] = useState();
  const [chosenOccasion, setChosenOccasion] = useState();
  const { config, currency, culture, language } = useSelector((state) => ({
    config: state.config,
    currency: state.cookies[STATE_CURRENCY],
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
  }));

  const isMobile = useMediaQuery("(max-width: 768px)");
  const { posId } = config;

  const { data, loading } = useQuery(query, {
    variables: { posId, currency, culture, from, until },
  });

  let timeFormat = "HH:mm";

  useEffect(() => {
    setChosenOccasion("");
  }, [day]);

  useEffect(() => {
    if (!loading && data && allotment && day) {
      const filterAllotments = data?.allotments?.filter(
        (el) => allotment.name === el.description
      );

      const filterOccasions = filterAllotments?.[0]?.occasions.filter((el) =>
        isSameDay(new Date(el.time), new Date(day))
      );
      setAvailableOccasions(filterOccasions);
    }
  }, [day, data, loading, allotment]);

  useEffect(() => {
    const timePicker = document.getElementById("event-time-picker");
    if (timePicker) {
      timePicker.focus();
    }
  }, [day]);

  useEffect(() => {
    if (
      day &&
      config?.hideEventTimeChooserIfSingleOccasion &&
      availableOccasions &&
      availableOccasions.length === 1 &&
      availableOccasions?.[0]?.remaining > 0
    ) {
      setChosenOccasion(availableOccasions?.[0]);
    } else {
      setChosenOccasion("");
    }
  }, [day, data, config, availableOccasions]);

  const showTimeChooser = useMemo(() => {
    if (
      day &&
      config?.hideEventTimeChooserIfSingleOccasion &&
      availableOccasions &&
      availableOccasions.length === 1 &&
      availableOccasions?.[0]?.remaining > 0
    ) {
      return false;
    } else {
      return true;
    }
  }, [day, data, config, availableOccasions]);

  return (
    <Container
      px={0}
      mx={0}
      className="event-time-article-container"
      style={{
        marginTop: isMobile && "1rem",
        marginBottom: isMobile && "2rem",
      }}
    >
      {showTimeChooser && (
        <>
          <SimpleGrid className="event-time-wrapper" cols={1} spacing="xs">
            <Title
              order={2}
              style={{ fontSize: "1.25rem" }}
              id="event-time-title"
              tabIndex={day && 0}
            >
              {language.ChooseTimeHeader}
            </Title>
            <Text
              size="s"
              component="small"
              c="gray.7"
              fw={400}
              style={{ marginTop: "-0.5rem", marginBottom: "1rem" }}
            >
              {language.ChooseTimeDescription}
            </Text>
          </SimpleGrid>

          <SimpleGrid
            className="event-time-occasions"
            cols={isMobile ? 2 : 4}
            breakpoints={[
              {
                maxWidth: 768,
                cols: 3,
                spacing: "sm",
              },
            ]}
          >
            {day &&
              !availableOccasions &&
              [...Array(12)].map((_, i) => (
                <Skeleton key={i} height={48} width={103} />
              ))}
            {cultureLocale &&
              day &&
              availableOccasions &&
              availableOccasions.map((el, i) =>
                el.remaining > 0 ? (
                  <Button
                    className={
                      chosenOccasion === el ? "timeOccasionSelected" : ""
                    }
                    aria-label={`${format(new Date(el?.time), "HH:mm", {
                      locale: cultureLocale,
                    })} ${chosenOccasion === el ? language.Chosen : ""}`}
                    size="lg"
                    variant={chosenOccasion === el ? "filled" : "default"}
                    color="indigo"
                    style={{ padding: "0", border: "1px solid #8A8C93" }}
                    onClick={() => setChosenOccasion(el)}
                    key={el + i}
                  >
                    <SimpleGrid cols={1} style={{ gap: 0 }}>
                      <Text
                        style={{
                          textAlign: "center",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        {format(new Date(el.time), timeFormat)}
                      </Text>
                      {!config?.hideRemainingTickets && (
                        <Text
                          className="event-remainingTickets"
                          size="xs"
                          c={chosenOccasion === el ? "white" : "dimmed"}
                          style={{ fontSize: isMobile && 10 }}
                        >
                          {language.ApproximatelyShort} {el.remaining}{" "}
                          {language.Tickets.toLowerCase()}
                        </Text>
                      )}
                    </SimpleGrid>
                  </Button>
                ) : (
                  <Button color="indigo" size="lg" disabled key={el + i}>
                    <SimpleGrid
                      cols={1}
                      style={{ gap: 0, textAlign: "center" }}
                    >
                      <Text>{format(new Date(el.time), timeFormat)}</Text>

                      <Text size="xs" style={{ fontSize: isMobile && 10 }}>
                        {language.SoldOut}
                      </Text>
                    </SimpleGrid>
                  </Button>
                )
              )}
          </SimpleGrid>
        </>
      )}
      <ArticlePicker
        chosenOccasion={chosenOccasion}
        availableOccasions={availableOccasions}
        articles={allotment.articles}
      />
    </Container>
  );
};

export default TimePicker;
