import React, { useEffect, useMemo, useState } from "react";
import EventFilter from "./EventFilter";
import _ from "lodash";
import EventWrapper from "./EventWrapper";
import { addYears, isAfter, isBefore, isSameDay } from "date-fns";
import { useSessionStorage } from "@mantine/hooks";

const AllEventsV2 = ({ events }) => {
  const getPriceRange = events.reduce((acc, curr) => {
    acc.push(curr.articles);
    return acc.flat();
  }, []);

  const maxDate = events.reduce((acc, curr) => {
    acc.push(curr.occasions);
    return acc.flat().sort((a, b) => new Date(a.time) - new Date(b.time));
  }, []);

  const calculateValue = (value) => {
    const regex = /[$€£kr,]/g;
    let newPrice = value && value.replace(regex, "");
    return newPrice;
  };

  const articles = getPriceRange.sort(
    (a, b) => a.price.amountInclVat - b.price.amountInclVat
  );

  const [items, setItems] = useState([]);
  const [isSorting, setIsSorting] = useState(false);
  const [priceBeforeChange, setPriceBeforeChange] = useState([
    0,
    articles?.[articles.length - 1]
      ? parseFloat(
          calculateValue(articles?.[articles.length - 1]?.priceInclVat) + 100
        )
      : 0,
  ]);
  const [priceVal, setPriceVal] = useSessionStorage({
    key: "priceVal",
    defaultValue: [
      parseFloat(calculateValue(articles?.[0]?.priceInclVat)),
      parseFloat(calculateValue(articles?.[articles.length - 1]?.priceInclVat)),
    ],
  });
  const [text, setText] = useSessionStorage({
    key: "text",
    defaultValue: "",
  });
  const [date, setDate] = useSessionStorage({
    key: "date",
    defaultValue: [
      maxDate?.[0]?.time ? new Date(maxDate?.[0]?.time) : new Date(),
      maxDate?.[maxDate.length - 1]?.time
        ? new Date(maxDate?.[maxDate.length - 1]?.time)
        : addYears(new Date(), 1),
    ],
    serialize: (value) => {
      return [...value];
    },
    deserialize: (value) => {
      if (value !== ",") {
        let split = value.split(",");
        let newArr = [];
        newArr.push(new Date(split[0]));
        newArr.push(new Date(split[1]));
        return newArr;
      } else {
        return [
          new Date(maxDate?.[0]?.time),
          new Date(maxDate?.[maxDate.length - 1]?.time),
        ];
      }
    },
  });

  const checkPrice = (item) => {
    if (
      parseFloat(calculateValue(item.priceInclVat)) >= priceVal?.[0] &&
      parseFloat(calculateValue(item.priceInclVat)) <= priceVal?.[1]
    ) {
      return true;
    }
  };

  const checkName = (item) => {
    if (item.name.toLowerCase().includes(text.toLowerCase())) {
      return true;
    }
  };

  const checkDateRange = (item) => {
    let occasion = item?.occasions;
    const filter = occasion.some((el) => {
      if (
        (isSameDay(new Date(el.time), date[0]) ||
          isAfter(new Date(el.time), date[0])) &&
        (isSameDay(new Date(el.time), date[1]) ||
          isBefore(new Date(el.time), date[1]))
      ) {
        return true;
      }
    });

    if (filter) {
      return true;
    }
  };

  const filterByPrice = useMemo(() => {
    return (
      events &&
      events.reduce((acc, curr) => {
        const external = () => {
          if (curr?.isExternal) {
            return true;
          }
        };

        const amount = () => {
          for (let price of curr.articles) {
            if (checkPrice(price)) {
              return true;
            }
          }
        };

        const translation = () => {
          for (let translation of curr.translation) {
            if (checkName(translation)) {
              return true;
            }
          }
        };

        const dateRange = () => {
          if (checkDateRange(curr)) {
            return true;
          }
        };
        if (
          (external() && translation() && dateRange()) ||
          (amount() && translation() && dateRange())
        ) {
          acc.push(curr);
        }
        return _.uniq(acc);
      }, [])
    );
  }, [events, priceVal, text, date]);

  useEffect(() => {
    if (filterByPrice && filterByPrice.length !== items.length) {
      setItems(filterByPrice);
    }
  }, [priceVal, text, date]);

  useEffect(() => {
    if (date === "") {
      setItems(events);
    }
  }, []);

  if (!text && !priceVal && !date && !events) return null;

  return (
    <>
      {items && (
        <>
          <EventFilter
            priceVal={priceVal}
            setPriceVal={setPriceVal}
            priceBeforeChange={priceBeforeChange}
            setPriceBeforeChange={setPriceBeforeChange}
            events={events}
            text={text}
            setText={setText}
            date={date}
            setDate={setDate}
            setIsSorting={setIsSorting}
          />
          <EventWrapper
            date={date}
            items={items}
            events={events}
            isSorting={isSorting}
            setIsSorting={setIsSorting}
          />
        </>
      )}
    </>
  );
};

export default AllEventsV2;
