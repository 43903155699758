import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Card,
  Image,
  SimpleGrid,
  Skeleton,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getSaleData } from "../Product/util";
import { addArticle, addToCartTracking } from "../../common/sale";
import { DataContext } from "../common/dataProvider";
import { SaleContext } from "../common/saleProvider";
import { STATE_CULTURE, STATE_CURRENCY } from "../../common/state";
import { getProduct } from "../../Pages/ProductPage/util";
import classes from "./CardItemV2.module.css";
import placeholder from "../../images/placeholder.jpg";
import { getImage, getName } from "./util";
import { Link } from "react-router-dom";
import { translateProductGroup } from "../../common/util";

const CardItemV2 = ({ product }) => {
  const { config, language, culture, currency } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
    currency: state.cookies[STATE_CURRENCY],
  }));
  const [addedToCart, addedToCartSet] = useState(null);
  const [clicked, clickedSet] = useState(false);
  const [price, priceSet] = useState(null);
  const navigate = useNavigate();
  const { data } = useContext(DataContext);
  const saleContext = useContext(SaleContext);
  const { sale } = saleContext.state;
  const uri = config?.apiUriOverride?.startsWith("http")
    ? config.apiUriOverride
    : config.apiUri;
  const rdxDispatch = useDispatch();
  const regex = /\D/g;

  const reduce =
    product.products &&
    product.products.reduce((acc, curr) => {
      if (curr.priceInclVat) {
        acc.push(curr.priceInclVat);
      }
      return acc;
    }, []);
  const reduceChooseValue =
    product.openPriceValues &&
    product.openPriceValues.reduce((acc, curr) => {
      acc.push(curr);
      return acc;
    }, []);

  const sortPrice = (prices) => {
    if (prices && typeof prices?.[0] === typeof 0) {
      const sortOpenPrice = prices.sort((a, b) => a - b);
      return sortOpenPrice;
    } else if (prices && typeof prices?.[0] === typeof "") {
      const sortStringValue = prices.sort(
        (a, b) => a.replace(regex, "") - b.replace(regex, "")
      );
      return sortStringValue;
    }
  };

  const links = (isLink) => {
    const url = `/${product.slug}`;
    return isLink ? `${config?.self}${url}` : navigate(url);
  };

  useEffect(() => {
    const giftCard = config?.productConfig?.filter((el) =>
      el.description?.includes("presentkort")
    );
    const filterGiftCard = giftCard?.filter((el) => el.plu === product.plu);

    if (product?.displayPrice && !reduce && !reduceChooseValue) {
      const price = product.displayPrice.replace(" - ", "–");
      priceSet(price);
    }

    if (product?.priceInclVat && !reduce && !reduceChooseValue) {
      priceSet(product.priceInclVat);
    }
    if (
      filterGiftCard.length > 0 &&
      filterGiftCard[0].openPriceValues.length > 0
    ) {
      priceSet(
        `${language.FromCost} ${filterGiftCard[0].openPriceValues[0]} ${language.CurrencyAbbreviation}`
      );
    }
    if (
      filterGiftCard.length > 0 &&
      filterGiftCard[0].openPriceValues.length === 0
    ) {
      priceSet(language.OpenPrice);
    }
    if (
      filterGiftCard.length > 0 &&
      filterGiftCard[0].openPriceValues.length === 1
    ) {
      priceSet(
        `${filterGiftCard[0].openPriceValues[0]} ${language.CurrencyAbbreviation}`
      );
    }
    if (reduce) {
      priceSet(
        `${sortPrice(reduce)?.[0]}–${
          sortPrice(reduce)?.[sortPrice(reduce).length - 1]
        }`
      );
    }

    if (reduceChooseValue) {
      priceSet(
        `${sortPrice(reduceChooseValue)?.[0]} kr–${
          sortPrice(reduceChooseValue)?.[
            sortPrice(reduceChooseValue).length - 1
          ]
        } kr`
      );
    }
  }, [config, product]);

  const trackProductData = [
    {
      id: product.plu,
      name: product.name,
      price: product?.price?.amountInclVat,
      quantity: 1,
    },
  ];

  const handleAdd = () => {
    addToCartTracking(currency, trackProductData);
    clickedSet(true);
    const callback = (status, msg) => {
      if (status === 200) {
        clickedSet(false);
        addedToCartSet(true);
        setTimeout(() => {
          addedToCartSet(null);
        }, 5000);
      }
      if (status !== 200) {
        clickedSet(false);
        addedToCartSet(false);
        setTimeout(() => {
          addedToCartSet(null);
        }, 5000);
      }
    };
    let article;
    let values;
    let photo;
    let overrideDate;
    let quantity = 1;
    const saleData = getSaleData(
      product,
      article,
      values,
      photo,
      quantity,
      overrideDate
    );
    rdxDispatch(addArticle(saleData, callback));
  };

  const name = translateProductGroup(product.name, language, "ProductGroups");

  const image = useMemo(() => {
    return getImage(product, uri, config);
  }, [product.images]);

  const productData = useMemo(() => {
    if (sale) {
      return getProduct(data, config, product.plu, culture);
    }
  }, [data, product, config, culture, sale]);

  const disableAdd = useCallback(
    (productItem) => {
      if (sale && sale?.items?.length > 0) {
        const getItem = sale?.items.filter(
          (item) => item?.article?.plu === productItem?.plu
        )?.[0];
        if (getItem?.quantity === productItem?.inStock) return true;
      }

      if (productItem?.inStock <= 0) return true;
      return false;
    },
    [sale?.items, productData]
  );

  if (!product || !productData)
    return (
      <div className={classes.skeleton}>
        <Skeleton height={245} width="100%" radius="md" />
        <Skeleton height={25} mt={12} width="75%" radius="sm" />
        <Skeleton height={20} mt={5} mb={16} width="25%" radius="sm" />
        <Skeleton height={36} width="100%" radius="sm" />
      </div>
    );
  return (
    <Card radius="sm" className={classes.card}>
      <UnstyledButton
        component={Link}
        className={`${classes.imageButton} cardItemButton`}
        to={links(true)}
        style={{ width: "100%" }}
      >
        <Card.Section mx="0">
          <Image
            src={image}
            h={"244"}
            fit="cover"
            fallbackSrc={placeholder}
            radius="md"
            alt={`${language.ProductAltLabel} ${name}`}
          />
        </Card.Section>

        <Text className={`${classes.title} itemTitle`} fw={700} component="p">
          {`${name.slice(0, 1).toUpperCase()}${name.slice(1)}`}
        </Text>
      </UnstyledButton>
      <SimpleGrid className={classes.footer} mt={0} pb={"0.1rem"}>
        <Text
          className={`${classes.price} product-price-list`}
          component={"p"}
          c={"gray.7"}
          fw={"500"}
          lineClamp={4}
          style={{ textAlign: "left" }}
          m={0}
          mb={"0.15rem"}
        >
          {price}
        </Text>
        {productData?.isProduct ? (
          <div
            role="region"
            aria-live="assertive"
            className="productActionButton"
          >
            <Button
              className={`add_to_cart_product ${classes.addToCart} ${
                !addedToCart && "regularButton"
              }`}
              classNames={{
                label: "add_to_cart_product",
                inner: "add_to_cart_product",
              }}
              size="sm"
              onClick={() => handleAdd()}
              color={
                addedToCart
                  ? "var(--button-add-to-cart-success-bg-color)"
                  : "var(--button-primary-bg-color)"
              }
              disabled={disableAdd(productData)}
              loading={clicked}
            >
              {!addedToCart && (!product.inStock || product.inStock >= 1)
                ? language.AddToCartBtn
                : !addedToCart && product.inStock <= 0
                ? language.SoldOut
                : addedToCart && language.Added_To_Cart}
            </Button>
          </div>
        ) : (
          <Button
            className="productChooseBtn primaryButtonOutline"
            size="sm"
            onClick={() => links(false)}
            variant="outline"
            aria-label={`${language.Choose} ${product.name}`}
          >
            {language.Choose}
          </Button>
        )}
      </SimpleGrid>
    </Card>
  );
};

export default CardItemV2;
