import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import axios from "axios";
import { useDisclosure } from "@mantine/hooks";
import { Button, SimpleGrid } from "@mantine/core";
import ConsentContent from "../CookieBanner/ConsentContent";
import { useNavigate } from "react-router";

let source;

const Security = () => {
  const [text, setText] = useState("");
  const { klarnaTerms, shopName, baseUrl, useTagmanager } = useSelector(
    (state) => state.config
  );
  const language = useSelector((state) => state.language);
  const [opened, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();

  useEffect(() => {
    let CancelToken = axios.CancelToken;
    source = CancelToken.source();

    axios
      .get(`${baseUrl}/ee-static/shop/${shopName}/Texts/cookies.md`)
      .then((res) => setText(res.data));

    return () => source.cancel("Component Is Unmounting");
  }, [shopName]);

  return (
    <div className="cookies__container">
      <div className="cookies__header">
        <Button
          variant="outline"
          className="primaryButtonOutline"
          onClick={() => navigate(-1)}
        >
          {language.Back}
        </Button>
        {useTagmanager && (
          <>
            <Button className="openCookieSettings primaryButton" onClick={open}>
              {language.HandleCookies}
            </Button>
            <ConsentContent opened={opened} close={close} />
          </>
        )}
      </div>
      <ReactMarkdown className="cookies-text" rehypePlugins={[rehypeRaw]}>
        {text}
      </ReactMarkdown>
      {klarnaTerms && (
        <span>
          <h3>Klarna</h3>
          <p>
            Our checkout solution is provided by Klarna (Klarna AB, Sveavägen
            46, 111 34 Stockholm, Sweden) and uses cookies to offer you the best
            possible, tailored, online experience when you use Klarna's
            checkout. The exact cookies and purposes for which they are used can
            be found here:
            <br />
            <a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/sv_se/cookie_purchase">
              Klarnas användande av spårningsteknologier i webbsidans kassa
            </a>{" "}
            <br />
            <a href="https://cdn.klarna.com/1.0/shared/content/legal/terms/0/sv_se/privacy">
              Klarnas dataskyddsinformation
            </a>
          </p>
        </span>
      )}
    </div>
  );
};

export default Security;
