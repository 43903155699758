import React, { useEffect } from "react";

const useClickDetector = (ref, toggleSearch, setToggleSearch, setSearchValue) => {
  useEffect(() => {
    const handleClick = (event) => {
      if (ref?.current && !ref?.current.contains(event.target)) {
        if (toggleSearch) {
          setToggleSearch(false);
          setSearchValue("")
        }
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [ref, toggleSearch]);
};

export default useClickDetector;
