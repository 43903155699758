import { useDisclosure } from "@mantine/hooks";
import { motion } from "framer-motion";
import { useState } from "react";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import { useSelector } from "react-redux";
import OptionsModal from "../Options/OptionsModal";

const spring = {
  type: "spring",
  stiffness: 500,
  damping: 30,
};

const ArticleOption = ({
  item,
  cart,
  setCart,
  translation,
  calculateQuantity,
  removeItem,
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [extraInformation, setExtraInformation] = useState(null);
  const { language } = useSelector((state) => ({
    language: state.language,
  }));

  const addItem = (product) => {
    let article = product;
    if (article?.additionalFields && extraInformation) {
      article = { ...product, extraInformation };
    }

    if (calculateQuantity(article) + 1 <= article.inStock) {
      setCart([...cart, article]);
    }
  };
  return (
    <div
      className={`calendar-articleWrapper ${
        item.inStock === 0 ? "calendar-hideScreen" : ""
      }`}
      key={item.name + item.id}
    >
      <div className="calendar-articleLeftColumn">
        <span className="calendar-articleTitle">{translation(item)}</span>
        <span className="calendar-articlePrice">
          {item.inStock === 0 ? language?.SoldOut : item.priceInclVat}
        </span>
      </div>
      {item.inStock === 0 ? (
        ""
      ) : (
        <div className="calendar-articleRightColumnToggle">
          <motion.div
            className="calendar-switch"
            data-ison={calculateQuantity(item) > 0 ? true : ""}
            transition={spring}
            layout
          />
          <motion.div
            className="calendar-handle"
            data-ison={calculateQuantity(item) > 0 ? true : ""}
            whileTap={{ scale: 1.2 }}
            layout
            transition={spring}
            style={{
              pointerEvents:
                calculateQuantity(item) === item.inStock ? "none" : "",
            }}
          >
            <button
              className={`calendar-articleButton ${
                calculateQuantity(item) === item.inStock
                  ? "calendar-hideScreen"
                  : ""
              }`}
              onClick={() => {
                if (item.additionalFields.length > 0) {
                  open();
                } else {
                  addItem(item);
                }
              }}
              aria-label={`${language?.AddProduct} ${translation(item)}`}
            >
              <MdAddCircle className="buttonIcons" />
            </button>
          </motion.div>
          <motion.div
            className="calendar-count calendar-articleQty"
            data-ison={calculateQuantity(item) > 0 ? true : ""}
            layout
            transition={spring}
          >
            {calculateQuantity(item)}
          </motion.div>
          <motion.div
            data-ison={calculateQuantity(item) > 0 ? true : ""}
            className="calendar-handle2"
            layout
            whileTap={{ scale: 1.2 }}
            transition={spring}
          >
            <button
              className={`calendar-articleButtonRemove ${
                calculateQuantity(item) === 0 ? "calendar-hideScreen" : ""
              }`}
              onClick={() => removeItem(item)}
              aria-label={`${language?.RemoveProduct} ${calculateQuantity(
                item
              )} ${item.name}`}
              disabled={calculateQuantity(item) === 0 ? true : false}
            >
              <MdRemoveCircle className="buttonIcons" />
            </button>
          </motion.div>
          {item?.additionalFields?.length > 0 && (
            <OptionsModal
              title={translation(item)}
              opened={opened}
              close={close}
              additionalFields={item.additionalFields}
              handleAdd={() => addItem(item)}
              extraInformation={extraInformation}
              setExtraInformation={setExtraInformation}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default ArticleOption;
