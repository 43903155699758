import { z } from "zod";

export const getZodSchema = (checkIfRequired, language) => {
  const noValidationNeeded = (field, value) => {
    return !checkIfRequired(field) && value === "";
  };

  return z.object({
    firstName: z
      .string()
      .max(100, { message: language.Form_Error_InvalidLength })
      .refine(
        (value) => noValidationNeeded("firstName", value) || value.length > 1,
        {
          message: language.Form_Error_FirstName,
        }
      ),
    lastName: z
      .string()
      .max(100, { message: language.Form_Error_InvalidLength })
      .refine(
        (value) => noValidationNeeded("lastName", value) || value.length > 1,
        {
          message: language.Form_Error_LastName,
        }
      ),
    civicRegistrationNumber: z
      .string()
      .trim()
      .refine(
        (value) =>
          noValidationNeeded("civicRegistrationNumber", value) ||
          /^([1-2][0|9]){0,1}[0-9]{2}[0-1][0-9][0-3][0-9][-]{0,1}[0-9]{4}$/.test(
            value
          ),
        {
          message: language.Form_Error_CivicRegistrationNumber_Invalid,
        }
      )
      .refine(
        (value) =>
          noValidationNeeded("civicRegistrationNumber", value) ||
          (value.replace("-", "").length >= 10 &&
            value.replace("-", "").length <= 12),
        {
          message: language.Form_Error_CivicRegistrationNumber_Length,
        }
      ),
    email: z
      .string()
      .max(100, { message: language.Form_Error_InvalidLength })
      .refine(
        (value) =>
          noValidationNeeded("email", value) ||
          z.string().email().safeParse(value).success,
        { message: language.Form_Error_Email }
      ),
    cellPhone: z
      .string()
      .max(15, { message: language.Form_Error_InvalidLength })
      .refine(
        (value) =>
          noValidationNeeded("cellPhone", value) ||
          /^(\+(\d{1,3}\s*)|)\d{1,4}(-|\/|\s|)(\d\s*){5,10}$/.test(value),
        {
          message: language.Form_Error_CellPhone,
        }
      )
      .refine(
        (value) => noValidationNeeded("cellPhone", value) || value.length > 9,
        {
          message: language.Form_Error_CellPhone,
        }
      ),
    street: z
      .string()
      .max(100, { message: language.Form_Error_InvalidLength })
      .refine(
        (value) => noValidationNeeded("street", value) || value.length > 1,
        {
          message: language.Form_Error_Street,
        }
      ),
    streetNumber: z
      .string()
      .max(100, { message: language.Form_Error_InvalidLength })
      .refine(
        (value) =>
          noValidationNeeded("streetNumber", value) || value.length > 0,
        {
          message: language.Form_Error_StreetNumber,
        }
      ),
    zipCode: z
      .string()
      .max(20, { message: language.Form_Error_InvalidLength })
      .refine(
        (value) =>
          noValidationNeeded("zipCode", value) ||
          /^[0-9]{3}(\s?){0,1}[0-9]{2,}$/.test(value),

        {
          message: language.Form_Error_CityCode,
        }
      )
      .transform(Number),
    city: z
      .string()
      .max(100, { message: language.Form_Error_InvalidLength })
      .refine(
        (value) => noValidationNeeded("city", value) || value.length > 1,
        {
          message: language.Form_Error_City,
        }
      ),
    countryCode: z
      .string()
      .max(100, { message: language.Form_Error_InvalidLength })
      .refine(
        (value) => noValidationNeeded("countryCode", value) || value.length > 1,
        {
          message: language.Form_Error_CountryCode,
        }
      ),
  });
};
