import React from "react";
import { Checkbox, Group } from "@mantine/core";
import classes from "./Field.module.css";

const CheckboxGroupField = ({
  form,
  label,
  id,
  description,
  values,
  required,
}) => {
  const matchingFormIndex = form.values.formFields.findIndex(
    (field) => field.id === id
  );
  return (
    <div className={"pbCheckbox"}>
      <Checkbox.Group
        classNames={{
          label: classes.label,
          description: classes.description,
        }}
        label={label}
        description={description}
        withAsterisk={required}
        {...form.getInputProps(`formFields.${matchingFormIndex}.multiVal`)}
      >
        <Group mt="xs">
          {values.map((el) => (
            <Checkbox key={el} label={el} value={el} color="indigo" />
          ))}
        </Group>
      </Checkbox.Group>
    </div>
  );
};

export default CheckboxGroupField;
