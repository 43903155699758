import React from "react";
import { Title } from "@mantine/core";
import classes from "./BookingSection.module.css";

const BookingSection = ({ label, subLabel, children }) => {
  return (
    <div className={classes.container}>
      <div>
        <Title order={2} style={{ fontSize: "1.15rem" }}>
          {label} <span style={{ fontWeight: 400 }}>{subLabel}</span>
        </Title>
      </div>
      <section className={classes.section}>{children}</section>
    </div>
  );
};

export default BookingSection;
