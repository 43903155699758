import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { STATE_CULTURE, STATE_CURRENCY } from "../../common/state";
import { DataContext } from "../common/dataProvider";
import Cart from "./Cart";
import Header from "./Header";
import IntroductionSection from "./IntroductionSection";
import { useLocation, useNavigate } from "react-router";
import smoothscroll from "smoothscroll-polyfill";
import { extendPage } from "../../Pages/AllProductsPage/util";
import PageLayout from "../Styles/PageLayout";
import PageContent from "../Styles/PageContent";
import { Button, Container, Text, Title } from "@mantine/core";
import classes from "./ArticleBooking.module.css";

const ArticleBooking = () => {
  const [section, setSection] = useState("");
  const [bookingData, setBookingData] = useState([]);
  const [settingsData, setSettingsData] = useState([]);
  const [articlesData, setArticlesData] = useState([]);
  const [optionsData, setOptionsData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const { config, language, culture, currency } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
    currency: state.cookies[STATE_CURRENCY],
  }));
  const pageRefs = useRef();
  const { data } = useContext(DataContext);
  const location = useLocation();
  const urlPathName = location.pathname.split("/").slice(-1)[0];
  const navigate = useNavigate();
  smoothscroll.polyfill();

  useEffect(() => {
    if (config?.articleBookingConfigurator) {
      const filter = config?.articleBookingConfigurator.filter(
        (el) => el.slug === urlPathName && !el?.disabled
      );
      if (filter[0]) {
        setBookingData(filter[0].tickets);
        setSettingsData(filter[0]);
      }
    }
  }, [config?.articleBookingConfigurator, location.pathname]);

  useEffect(() => {
    const articles = data.articles;
    const articleIds = section.articleId;
    const optionIds = section.optionId;

    if (
      articles &&
      section.articleId?.[0] !== undefined &&
      section.articleId?.length > 0
    ) {
      const filterArticles = articles.filter((el) => {
        return articleIds.some((item) => {
          return item === el.id;
        });
      });

      const removeArticlesDuplicates = new Set(filterArticles);
      const newArticlesData = [...removeArticlesDuplicates];

      // Get options
      const options = articles.reduce((acc, curr) => {
        if (optionIds.includes(curr.id)) {
          acc.push(curr);
        }
        return acc;
      }, []);

      const sortArticles = newArticlesData.sort(
        (a, b) => articleIds.indexOf(a.id) - articleIds.indexOf(b.id)
      );

      const sortOptions = options.sort(
        (a, b) => optionIds.indexOf(a.id) - optionIds.indexOf(b.id)
      );
      setArticlesData(sortArticles);
      setOptionsData(sortOptions);
    }
  }, [data, section]);

  const selfUrl = config.self.split("/").slice(-1);

  const scrollIntoView = (type) => {
    if (isVisible === true) {
      pageRefs.current[type].scrollIntoView({
        block: "nearest",
        inline: "center",
        behavior: "smooth",
        alignToTop: false,
      });
    }
  };

  useEffect(() => {
    setIsVisible(false);
  }, [location.pathname]);

  if (bookingData.length === 0) {
    return (
      <PageLayout>
        <PageContent>
          <Container
            style={{
              textAlign: "center",
              margin: "3rem 0",
              maxWidth: "100%",
              padding: 0,
            }}
          >
            <Title mb="lg" order={1}>
              {language.CouldNotFindEventHeader}
            </Title>
            <Text component="p" mb="lg">
              {language.CouldNotFindEventText}
            </Text>
            <Button
              component="a"
              onClick={() => navigate("/")}
              className="mantine-Primary-btn backButton"
              variant="filled"
              size="md"
              radius="sm"
              color="indigo"
            >
              {language.BackToHomepage}
            </Button>
          </Container>
        </PageContent>
      </PageLayout>
    );
  }
  return (
    <Container style={{ padding: 0 }}>
      <Container className={classes.buttonWrapper}>
        <Button
          className={`${classes.button} backButton`}
          variant="default"
          mb={2}
          onClick={() => navigate(-1)}
        >
          {language.Back}
        </Button>
      </Container>
      <Header
        section={section}
        setSection={setSection}
        bookingData={bookingData}
        scrollIntoView={scrollIntoView}
        language={language}
        setIsVisible={setIsVisible}
        settingsData={settingsData}
        config={config}
        culture={culture}
      />

      <div
        className="articleBookingWrapper"
        style={{ height: isVisible === false ? "5rem" : "auto" }}
        key="articleBookingWrapper"
        ref={(el) =>
          (pageRefs.current = {
            ...pageRefs.current,
            IntroductionSection: el,
          })
        }
      >
        <div className={`scroller`}>
          {!section.name || section.name === undefined ? (
            <h2 className="articleHeader continueHeader">
              {language?.ArticleBooking?.ChooseTicketToContinue}
            </h2>
          ) : (
            ""
          )}
          <IntroductionSection
            config={config}
            language={language}
            culture={culture}
            section={section}
            selfUrl={selfUrl}
            bookingData={bookingData}
            settingsData={settingsData}
          />
        </div>
        {section && section.length !== 0 ? (
          <Cart
            section={section}
            articlesData={articlesData}
            optionsData={optionsData}
            language={language}
            isVisible={isVisible}
            culture={culture}
            currency={currency}
          />
        ) : (
          ""
        )}
      </div>
    </Container>
  );
};

export default ArticleBooking;
