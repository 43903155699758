import React, { useMemo } from "react";
import { Grid, Stack, Text } from "@mantine/core";
import GroupArticleButtons from "./GroupArticleButtons";
import { useSelector } from "react-redux";

const GroupArticle = ({
  allTicketsData,
  ticketData,
  groupTicketCart,
  setGroupTicketCart,
  item,
}) => {
  const { config } = useSelector((state) => ({
    config: state.config,
  }));

  const getMaxQuantity = useMemo(() => {
    const maxQuantity = Math.max(
      ...allTicketsData.map((ticket) => ticket.maximumNumber)
    );
    return maxQuantity;
  }, [allTicketsData]);

  const max = useMemo(() => {
    if (config?.useMultiTicketQuantityOverride && groupTicketCart) {
      const reduce = groupTicketCart.map((ticket) =>
        ticket.ticketOwners.reduce((acc, curr) => {
          acc += curr.quantity;
          return acc;
        }, 0)
      );
      return getMaxQuantity - reduce.pop();
    } else {
      return ticketData.maximumNumber;
    }
  }, [config, groupTicketCart, ticketData, item]);

  return (
    <Grid justify="space-between" align="center">
      <Grid.Col span={6}>
        <Stack>
          <Text fw={500}>{ticketData.categoryName}</Text>
        </Stack>
      </Grid.Col>
      <Grid.Col span={6} style={{ textAlign: "right", flexBasis: "0%" }}>
        <GroupArticleButtons
          max={max}
          ticketData={ticketData}
          groupTicketCart={groupTicketCart}
          setGroupTicketCart={setGroupTicketCart}
          item={item}
        />
      </Grid.Col>
    </Grid>
  );
};

export default GroupArticle;
