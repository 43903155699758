import React, { useMemo, useState } from "react";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { Text } from "@mantine/core";
import OptionsModal from "../../Options/OptionsModal";
import { useDisclosure } from "@mantine/hooks";

const ArticleButtons = ({
  cart,
  cartSet,
  ticketData,
  chosenOccasion,
  disabledBtn,
  availableOccasions,
  name,
  openModal,
  groupTicketCart,
  setGroupTicketCart,
  removeGroupTicket,
}) => {
  const [opened, { open: optionsOpen, close: optionsClose }] = useDisclosure();
  const [extraInformation, setExtraInformation] = useState(null);
  const { language } = useSelector((state) => ({
    language: state.language,
  }));

  const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30,
  };

  const optionsItem = useMemo(() => {
    return {
      id: ticketData.id,
      plu: ticketData.plu,
      quantity: 1,
      allotmentId: chosenOccasion.allotment.id,
      timeOccasions: chosenOccasion.time,
      extraInformation: extraInformation,
      priceInclVat: ticketData.priceInclVat,
      price: {
        amountInclVat: ticketData?.price?.amountInclVat,
      },
    };
  }, [chosenOccasion, extraInformation]);

  const addAllotment = (product) => {
    if (product?.additionalFields.length > 0) {
      cartSet([...cart, optionsItem]);
    } else if (product?.isMultiTicket) {
      openModal(ticketData);
      setGroupTicketCart([
        ...groupTicketCart,
        {
          plu: product.plu,
          quantity: 1,
          allotmentId: chosenOccasion.allotment.id,
          allotmentOccasion: chosenOccasion.time,
          ticketOwners: [],
        },
      ]);
    } else {
      let newProduct = { ...product };
      newProduct.allotmentId = chosenOccasion.allotment.id;
      newProduct.timeOccasions = chosenOccasion.time;
      cartSet([...cart, newProduct]);
    }
  };

  const removeAllotment = (ticketData) => {
    if (ticketData?.isMultiTicket) return removeGroupTicket(ticketData);
    let newCart = [...cart];
    let findAllotment = cart.findIndex((i) => i.id === ticketData.id);
    newCart.splice(findAllotment, 1);
    cartSet(newCart);
  };

  const checkIfAvailable = () => {
    let timeData = availableOccasions.filter(
      (el) => chosenOccasion.time === el.time
    );
    if (
      timeData?.[0]?.remaining <= cart.length ||
      timeData?.[0]?.allotment.maxPerSale === cart.length
    ) {
      return true;
    } else {
      return false;
    }
  };

  const calculateQuantity = (el) => {
    const isGroupItem = groupTicketCart.some((item) => item.plu === el.plu);
    if (isGroupItem) {
      const findGroupItem = groupTicketCart.filter(
        (item) => item.plu === el.plu
      );
      return findGroupItem.length;
    }

    const findItems = cart.filter((item) => item.id === el.id);
    return findItems.length;
  };

  const handleAdd = () => {
    if (ticketData.additionalFields.length > 0) {
      optionsOpen();
    } else {
      addAllotment(ticketData);
    }
  };

  return (
    <div className="articleRightColumnToggle" style={{ margin: 0 }}>
      <motion.div
        className="switch"
        data-ison={calculateQuantity(ticketData) > 0 ? true : ""}
        transition={spring}
        layout
      />
      <motion.div
        className={`handle ${checkIfAvailable() ? "" : ""}`}
        tabIndex="-1"
        data-ison={calculateQuantity(ticketData) > 0 ? true : ""}
        whileTap={{ scale: 1.2 }}
        layout
        transition={spring}
        style={{
          pointerEvents: disabledBtn ? "none" : "",
        }}
      >
        <button
          className={`articleButton`}
          onClick={handleAdd}
          aria-label={`${language?.AddQuantity} ${name}`}
          disabled={disabledBtn}
          style={{
            border: "none",
            background: "none",
            color: disabledBtn ? "rgb(180, 180, 180)" : "",
            pointerEvents: disabledBtn ? "none" : "",
            opacity: disabledBtn ? "0.5" : 1,
          }}
        >
          <MdAddCircle className="buttonIcons" />
        </button>
      </motion.div>
      <motion.div
        className="event-count articleQty"
        data-ison={calculateQuantity(ticketData) > 0 ? true : false}
        layout
        transition={spring}
        aria-live="assertive"
      >
        <Text
          size="xl"
          fw={"bold"}
          component="span"
          aria-label={`${name} ${language.Quantity} ${calculateQuantity(
            ticketData
          )}`}
        >
          {calculateQuantity(ticketData)}
        </Text>
      </motion.div>
      <motion.div
        data-ison={calculateQuantity(ticketData) > 0 ? true : ""}
        className="handle2"
        tabIndex="-1"
        whileTap={{ scale: 1.2 }}
        layout
        transition={spring}
      >
        <button
          className={`articleButtonRemove`}
          onClick={() => removeAllotment(ticketData)}
          aria-label={`${language?.DecreaseQuantity} ${name}`}
          disabled={calculateQuantity(ticketData) === 0 ? true : false}
          style={{ border: "none", background: "none" }}
        >
          <MdRemoveCircle className="buttonIcons" />
        </button>
      </motion.div>
      <OptionsModal
        title={name}
        opened={opened}
        close={optionsClose}
        additionalFields={ticketData?.additionalFields}
        handleAdd={() => addAllotment(ticketData)}
        extraInformation={extraInformation}
        setExtraInformation={setExtraInformation}
      />
    </div>
  );
};

export default ArticleButtons;
