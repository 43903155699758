import React, { useEffect, useMemo, useState } from "react";
import Header from "./components/Header";
import Cart from "./components/cart";
import SaleProvider from "./components/common/saleProvider";
import DataProvider from "./components/common/dataProvider";
import AppLayout from "./components/Styles/AppLayout";
import AppContent from "./components/Styles/AppContent";
import OidcClientInitializer from "./common/oidcClientInitializer";
import { useSelector } from "react-redux";
import { STATE_CONSENT } from "./common/state";
import axios from "axios";
import CookieBanner from "./components/CookieBanner";
import Cookies from "universal-cookie";
import { removeTrackingCookies } from "./components/CookieBanner/util";
import { CountdownProvider } from "./components/common/countdownProvider";

const App = () => {
  const consent = useSelector((state) => state.cookies[STATE_CONSENT]);
  const config = useSelector((state) => state.config);
  const [newConsent, setNewConsent] = useState(false);
  const cookies = useMemo(() => new Cookies(), []);

  const loadAnalyticsScript = (config) => {
    const isProduction =
      config?.environment !== "Development" && config?.environment !== "Test";

    if (config && config.shopName && isProduction) {
      const track = document.createElement("script");
      track.type = "text/javascript";
      track.src =
        "https://analytics.entryevent.se/js/script.tagged-events.exclusions.pageview-props.js";
      track.defer = true;
      track.setAttribute("data-domain", "ticketshop");
      track.setAttribute("event-tenant", `${config.shopName}`);
      track.setAttribute("data-exclude", "/ticketshop/callback");
      document.head.appendChild(track);
      const custom = document.createElement("script");
      custom.innerHTML =
        "window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }";
      document.head.appendChild(custom);
    }
  };

  useEffect(() => {
    if (config) {
      loadAnalyticsScript(config);
    }
  }, [config]);

  useEffect(() => {
    let data = [];
    data = consent;
    setNewConsent(data);
  }, [cookies, consent]);

  useEffect(() => {
    if (newConsent && config?.useTagmanager) {
      let tgm = window.document.createElement("script");
      tgm.setAttribute("type", "text/javascript");
      tgm.setAttribute("id", "tgm");
      tgm.setAttribute(
        "src",
        `${config.baseUrl}/ee-static/shop/${config.shopName}/Scripts/tgm.js`
      );
      window.document.getElementsByTagName("head")[0].appendChild(tgm);
    } else if (newConsent === false) {
      removeTrackingCookies(cookies, config?.baseUrl);
    }
  }, [cookies, newConsent]);

  // Add Favicon
  useMemo(() => {
    let icon = [
      `${config.baseUrl}/ee-static/shop/${config.shopName}/favicon.ico`,
      `${process.env.PUBLIC_URL}/favicon.ico`,
    ];

    const getIcon = (arr, index) => {
      return axios.get(arr[index]).catch((err) => {
        if (index < arr.length - 1) {
          return getIcon(arr, index + 1);
        }
        throw err;
      });
    };

    getIcon(icon, 0)
      .then((response) => {
        if (response.status === 200) {
          let link = window.document.createElement("link");
          link.href = response.request.responseURL;
          link.rel = "shortcut icon";
          window.document.getElementsByTagName("head")[0].appendChild(link);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <OidcClientInitializer>
      <DataProvider>
        <SaleProvider>
          <CountdownProvider>
            <AppLayout>
              <Header />
              <Cart />
              <AppContent />
              <CookieBanner />
            </AppLayout>
          </CountdownProvider>
        </SaleProvider>
      </DataProvider>
    </OidcClientInitializer>
  );
};

export default App;
