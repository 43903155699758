import React from "react";
import { Button, Loader } from "@mantine/core";

const SubmitButton = (props) => {
  return (
    <div>
      <Button
        type="submit"
        className={`primaryButton ${props.disabled ? "disabled" : ""}`}
        aria-label={props.language?.SubmitBtn}
        size="md"
        leftSection={
          props.loading === true ? (
            <Loader
              className="loadingspinner"
              style={{ color: "rgb(255, 255, 255)", transform: "scale(0.8)" }}
            />
          ) : null
        }
      >
        {props.primaryText}
      </Button>
    </div>
  );
};

export default SubmitButton;
