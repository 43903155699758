import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import PageLayout from "../../components/Styles/PageLayout";
import PageContent from "../../components/Styles/PageContent";
import Description from "../../components/common/Description";
import { STATE_CULTURE } from "../../common/state";
import { DataContext } from "../../components/common/dataProvider";
import AllEventsV2 from "../../components/Events/AllEventsV2/index";

let source;

const AllEventsPagePage = (props) => {
  const [text, setText] = useState("");
  const { config, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const { data } = useContext(DataContext);
  useEffect(() => {
    source = axios.CancelToken.source();
    axios
      .get(
        `${config.baseUrl}/${config.descriptionFolderUrl}/all-events.${culture}.md`,
        {
          cancelToken: source.token,
        }
      )
      .then((res) => setText(res.data));
    return () => source.cancel("All Events Is Unmounting");
  }, [culture, config.descriptionFolderUrl]);

  const filteredEvents = useMemo(() => {
    const allEventsExternal = config?.allEventsExternal?.filter(
      (event) => !event?.disabled
    );

    const events = data?.allotments.reduce((acc, curr) => {
      if (config?.hideEventsFromAllEvents?.includes(curr.id)) {
        return acc;
      } else {
        acc.push(curr);
        return acc;
      }
    }, []);
    if (allEventsExternal && events) {
      return [...events, ...allEventsExternal];
    }
    return events;
  }, [data.allotments, config]);

  if (!data) {
    return (
      <PageLayout>
        <Description text={text} />
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <PageContent>
        <Description text={text} />
        <AllEventsV2 events={filteredEvents} />
      </PageContent>
    </PageLayout>
  );
};

export default AllEventsPagePage;
